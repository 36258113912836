export default {
    // URLS APPLI
    BASE_API_URL:                           process.env.VUE_APP_BASE_API_URL,
    API_URL:                                process.env.VUE_APP_BASE_API_URL + "/api/",
    REGISTER_URL:                           process.env.VUE_APP_BASE_API_URL + "/register/",
    RESET_PWD_URL:                          process.env.VUE_APP_BASE_API_URL + "/password/reset/",

    SYNCHRO_URL:                            process.env.VUE_APP_BASE_API_URL + "/api/synchro_files/",
    HAS_RIGHT_URL:                          process.env.VUE_APP_BASE_API_URL + "/api/has_right/",
    SEARCH_SIRE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/search_sire/", // params: sire, licencekey, pattern /search_sire/?sire=x&licencekey=x
    SEARCH_UELN_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/search_ueln/", // params: ueln, licencekey, pattern /search_ueln/?ueln=x&licencekey=x
    SEARCH_WEATHERBYS_NAME_URL:             process.env.VUE_APP_BASE_API_URL + "/api/search_weatherbys_name/",
    ADD_WEATHERBYS_ID_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/add_weatherbys/:id",
    SEARCH_NAME_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/liste_nom/",  // params: name, licencekey, pattern /liste_nom/name?licencekey=x
    SIRE_DECODE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/add_sire_encode/", // params: encode_sire, licencekey, pattern /liste_nom?encode_sire=x?licencekey=x
    VALIDE_HORSE_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/valide_horse_web/", // params : extern_horse_id, licence_key
    DELETE_HORSE_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id",
    DB_TABLE_VERSION_URL:                   process.env.VUE_APP_BASE_API_URL + "/table/check.json",
    DB_ORDER_TABLE_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/get_table_order/",
    DB_TABLE_STRUCT_URL:                    process.env.VUE_APP_BASE_API_URL + "/table/structure/",
    DB_USER_GET_DATA:                       process.env.VUE_APP_BASE_API_URL + "/api/get_data/",
    DB_USER_SAVE_DATA:                      process.env.VUE_APP_BASE_API_URL + "/api/save_data/",
    DB_USER_ACCUSE_RECEP:                   process.env.VUE_APP_BASE_API_URL + "/api/accuse_reception/",
	DB_DEL_SYNCHRO_HISTO:                   process.env.VUE_APP_BASE_API_URL + "/api/delete_synchro_data/",
	CHECK_DOMAIN_AUTHENTICITY:				process.env.VUE_APP_BASE_API_URL + "/api/check_domain_auth/",
    ALL_LICENCES_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/user/licences",
    SEARCH_HORSE_AND_PARENTS_URL:           process.env.VUE_APP_BASE_API_URL + "/api/search_horse_parents/",
    SEND_INVITATION_CA:                     process.env.VUE_APP_BASE_API_URL + "/api/invitation_ca",

    // USER
    USER_LOGIN:                             process.env.VUE_APP_BASE_API_URL + "/api/login/",
    USER_LOGIN_DEBUG:                       process.env.VUE_APP_BASE_API_URL + "/api/login_debug/",
    USER_AUTOLOGIN:                         process.env.VUE_APP_BASE_API_URL + "/api/login/autolog",
    USER_LOGIN_ADMIN:                       process.env.VUE_APP_BASE_API_URL + "/api/autolog_admin/",
    USER_LOGOUT:                            process.env.VUE_APP_BASE_API_URL + "/api/logout/",
    USER_CREATE:                            process.env.VUE_APP_BASE_API_URL + "/api/register",
    RESET_PWD_URL_API:                      process.env.VUE_APP_BASE_API_URL + "/api/password/reset/",
    UPDATE_PWD_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/password/update",
    USER_VALIDATION_CODE:                   process.env.VUE_APP_BASE_API_URL + "/api/validation_code",
    USER_VALIDATION_PHONE:                  process.env.VUE_APP_BASE_API_URL + "/api/validation_phone",
    USER_VALIDATION_EMAIL:                  process.env.VUE_APP_BASE_API_URL + "/api/email/verify/:user_id/:token?:signature",
    USER_VALIDATION_ASK:                    process.env.VUE_APP_BASE_API_URL + "/api/send_email_verif",
	USER_GET_ID:                            process.env.VUE_APP_BASE_API_URL + "/api/user_get_id/:id",
	USER_GET_LICENCE_MODULES_CODE:			process.env.VUE_APP_BASE_API_URL + "/api/licence_modules_code",
    USER_GET_USER_TO_MANAGE:                process.env.VUE_APP_BASE_API_URL + "/api/users_to_manage/",
	USER_GET_USER_TO_MANAGE_LICENCE:		process.env.VUE_APP_BASE_API_URL + "/api/users_to_manage/:licence_id",
    USER_GET_GROUP_TO_MANAGE:				process.env.VUE_APP_BASE_API_URL + "/api/groups_to_manage",
	USER_ENTITY_GET_STALLION_ID:			process.env.VUE_APP_BASE_API_URL + "/api/user_entities/:email",
	LICENCE_ENTITY_GET_STALLION_ID:			process.env.VUE_APP_BASE_API_URL + "/api/licence_entities/:licence_key",
	LICENCE_ENTITY_SET_STALLION_ID:			process.env.VUE_APP_BASE_API_URL + "/api/licence_entities/:licence_key",
	USER_AUTOLOG_TIER_CA_URL:				process.env.VUE_APP_BASE_API_URL + "/api/login/autolog_tier",
	USER_AUTOCOMPLETE_TIER_REGISTER_CA_URL:	process.env.VUE_APP_BASE_API_URL + "/api/client_area/register/autocomplete",
	USER_GET_ALL:							process.env.VUE_APP_BASE_API_URL + "/api/get_all_users/",
	USER_GET_BY_TIER_MAIL:					process.env.VUE_APP_BASE_API_URL + "/api/get_users_by_tier_mail/",
    USER_GET_GROUPS_NAMES:                  process.env.VUE_APP_BASE_API_URL + "/api/get_all_groups/names",
    USER_GOCARDLESS_RAPPROCHEMENT:          process.env.VUE_APP_BASE_API_URL + "/api/gocardless_rapprochement",
    LICENCE_FREE_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/licence/:licence_id/free",
    LICENCE_VALIDITY_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/licence/:licence_id/validity",
    LICENCE_NAME_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/licence/:licence_id/name",
    USERS_LICENCE_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/licence/users",
    LICENCE_GENERATE_TOKEN:                 process.env.VUE_APP_BASE_API_URL + "/api/licence/generate_token/:type",
    LICENCE_TOKEN_API:                      process.env.VUE_APP_BASE_API_URL + "/api/licence/api_token",


    // CONFIG
    USER_TABLE_CONFIG:                      process.env.VUE_APP_BASE_API_URL + "/api/private/tables/config/",
    USER_ACTION_BUTTON:                     process.env.VUE_APP_BASE_API_URL + "/api/private/tables/button/",
    USER_ACTION_FILTER:                     process.env.VUE_APP_BASE_API_URL + "/api/private/tables/filter/",
    CONFIG_HORSE:                           process.env.VUE_APP_BASE_API_URL + "/api/config/horses/",
    CONFIG_LIEUX:                           process.env.VUE_APP_BASE_API_URL + "/api/config/lieux",
    CONFIG_VAT:                             process.env.VUE_APP_BASE_API_URL + "/api/config/vat",
    GENERAL_CONFIG:                         process.env.VUE_APP_BASE_API_URL + "/api/private/config/general",
    USER_SET_LANG:                          process.env.VUE_APP_BASE_API_URL + "/api/private/config/lang",
    TABS_CONFIG_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/private/config/tabs/:type",
    TABLE_SHARE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/private/tables/config/:config_id/share",

    // HORSES
    HORSE_LIST_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/private/horses/",
    HORSE_URL:                              process.env.VUE_APP_BASE_API_URL + "/api/horses/",
    HORSE_CRITERE_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/horses/critere",
    HORSE_REFRESH_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/sire",
    HORSE_ACTES_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/actes",
    HORSE_WITHOUT_ACTS:                     process.env.VUE_APP_BASE_API_URL + "/api/horses/without_acts",
    HORSE_WITHOUT_PENSIONS:                 process.env.VUE_APP_BASE_API_URL + "/api/horses/without_pensions",
    HORSES_IMPORT:                          process.env.VUE_APP_BASE_API_URL + "/api/horses/import",
    HORSES_IMPORT_WEATHERBY:                process.env.VUE_APP_BASE_API_URL + "/api/horses/import_weatherby",

    // HORSE_ACTUAL_PLACE:                     process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/actual_residence",
    HORSE_ACTUAL_PLACE_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/actual_place",
    HORSE_ACTUAL_RESIDENCE_URL:             process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/actual_residence",
    HORSE_LAST_RESIDENCE_URL:               process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/last_residence",
    HORSE_RESIDENCE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/main_residence",
    HORSE_MOUVEMENTS_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/horses/:id/mouvements",
    HORSE_DERNIER_MV_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/horses/:id/dernier_mouvements",
    HORSE_PROVENANCE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/horses/:id/initial_provenance",
    HORSE_PEDIGREE_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/horses/:id/pedigree",
    HORSE_ALL_CATEGORIES:                   process.env.VUE_APP_BASE_API_URL + "/api/horses/categories",
    HORSE_UPDATE_CATEGORIE:                 process.env.VUE_APP_BASE_API_URL + "/api/horses/categories/:categorie_id",
    HORSE_CATEGORIE:                        process.env.VUE_APP_BASE_API_URL + "/api/horses/:id/categories",
    HORSE_DOCUMENTS_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/documents",
    HORSE_TRANSPONDEUR_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/transpondeur",
    HORSE_TAILLE_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/taille",
    HORSE_TIERS_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/tiers",
    HORSE_TIERS_DEL_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/horses/tiers",
    HORSE_TIERS_PART_DEL_URL:               process.env.VUE_APP_BASE_API_URL + "/api/horses/tiers_part",
    HORSE_TIERS_CURRENT_PART_URL:           process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/tiers_current_part",
    HORSE_TIERS_PART_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/tiers_part",
    HORSE_MEDIAS_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/medias",
    HORSE_PENSION_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/pensions",
    HORSE_CURRENT_PENSION_URL:              process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/current_pensions",
    HORSE_BY_USER:                          process.env.VUE_APP_BASE_API_URL + "/api/horses/:user_licence_key/:user_licence_id/get_by_user",
    HORSE_INVOICES_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/invoices",
    HORSE_SYNDIC_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/syndic",
    HORSE_ACTUAL_SYNDIC_URL:                process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/syndic",
    HORSE_SEASON_SYNDIC_URL:                process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/syndic/:season_id",
    HORSE_SYNDIC_DETAILS_URL:               process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/syndic/:syndic_id/:season_id/details",
    HORSE_CARRIERS_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/syndic/:syndic_id/carriers",
    HORSE_SYNDIC_HISTORY_URL:               process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/syndic/history",
    HORSE_SYNDIC_PREVIOUS_URL:              process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/syndic/previous",
    HORSE_SYNDIC_CLONE_URL:	                process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/syndic/clone",
    HORSE_SEASON_SYNDIC_DETAILS_URL:        process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/syndic/season/:season_id",
	HORSE_PEDIGREE_CROISEMENT_URL:			process.env.VUE_APP_BASE_API_URL + "/api/horses/generatepedigreepdf",
    HORSE_ENVOIE_COURRIER_URL:              process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/send/:model_id",
	HORSE_FICHE_DEPART_URL:					process.env.VUE_APP_BASE_API_URL + "/api/horses/generatepdffichedepart",
	HORSE_CLEAN_PDF_PEDIGREE_URL:			process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/clean_linked_pedigree_pdf",
	HORSE_SUIVI_GYNECO_URL:					process.env.VUE_APP_BASE_API_URL + "/api/horses/generatepdfsuivigyneco",
	HORSE_INFOS_URL:						process.env.VUE_APP_BASE_API_URL + "/api/horses/generatepdf_horse_infos",
	HORSE_CA_UNACKNOWLEDGED_URL:			process.env.VUE_APP_BASE_API_URL + "/api/horses/client_area/unacknowledged",
	HORSE_CA_ACKNOWLEDGE_URL:				process.env.VUE_APP_BASE_API_URL + "/api/horses/client_area/ack",
    HORSE_IMPORT_DESCENDANTS_URL:           process.env.VUE_APP_BASE_API_URL + "/api/horses/:sire/:year/import_descendants/",
    HORSE_SWITCH_FORMATS_POULAIN:           process.env.VUE_APP_BASE_API_URL + "/api/horses/poulain/switch_format",
    HORSE_MERGE:                            process.env.VUE_APP_BASE_API_URL + "/api/horses/merge/:horse_to_merge_id/:horse_id",
    HORSE_ADD_BY_TRANSPONDEUR_URL:          process.env.VUE_APP_BASE_API_URL + "/api/horses/transpondeur",
    HORSE_WEATHERBY_LISTE_HORSE_URL:        process.env.VUE_APP_BASE_API_URL + "/api/horses/weatherby/:horse_id",
    HORSE_DPS_BY_CHILDREN:                  process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/dps_by_children",
    GET_SIRE_BY_MICROSHIP_URL:              process.env.VUE_APP_BASE_API_URL + "/api/horses/sire_by_microship",
    HORSE_CHARGES_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/charges/",
    HORSE_CHARGES_PENSION_URL:              process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/charges_pension/",
    LETROT_RESULTS_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/horses/letrot_results",
    LETROT_ADD_HORSES_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/horses/letrot_horses",
    HORSE_VIDEO_CONCOURS:                   process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/video_concours",
    HORSE_SEND_SIGNATURE_URL:               process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/signature",

    // LIEUX
    LIEUX_TYPES_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/config/lieux/",
    LIEUX_URL:                              process.env.VUE_APP_BASE_API_URL + "/api/lieux",
    LIEUX_BY_ID_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/lieux/:lieu_id",
    LIEUX_DOCUMENT_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/lieux/:lieu_id/document",
    LIEUX_IMAGE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/lieux/:lieu_id/image",
    LIEUX_LOCALISATION_ACTE_URL:            process.env.VUE_APP_BASE_API_URL + "/api/lieux/:lieu_id/actes",
    RESIDENCES_LIST_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/residences/",
    MOUVEMENTS_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/mouvements",
    MOUVEMENTS_GET_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/mouvements/:id",
    MOUVEMENTS_LABEL_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/mouvements/labels",
    MOUVEMENTS_ANNEE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/mouvements/annees",
    MOUVEMENTS_PREVIOUS:                    process.env.VUE_APP_BASE_API_URL + "/api/mouvements/previous/:mouvement_id/:horse_id",
    MOUVEMENTS_HORSE_DATE_URL:              process.env.VUE_APP_BASE_API_URL + "/api/mouvements/horse_date/",
    RESIDENCE_SET_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/residences/",
	RESIDENCE_LAST_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/residences/last",
    INTRALOCATION_HORSE_DATE_URL:           process.env.VUE_APP_BASE_API_URL + "/api/intra_location/horse_date/",
	INTRALOCATION_IMAGE_URL:			    process.env.VUE_APP_BASE_API_URL + "/api/intra_location/:intralocation_id/image",
    INTRALOCATION_LOCALISATION_ACTE_URL:    process.env.VUE_APP_BASE_API_URL + "/api/intra_location/:intralocation_id/actes",
    MOUVEMENT_PRESENCE_HORSE_URL:           process.env.VUE_APP_BASE_API_URL + "/api/mouvements/presence/:horse_id",
    MOUVEMENT_PRESENCE_HORSE_BY_TIER_URL:   process.env.VUE_APP_BASE_API_URL + "/api/mouvements/presence_by_tier/:tier_id/:horses_id",
    MOUVEMENT_SUGGESTION_SAILLIE:           process.env.VUE_APP_BASE_API_URL + "/api/mouvements/suggestion_saillie/:type",

    // DOCUMENTS
    DOCUMENTS_LIST_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/documents/",
    DOCUMENT_FILE_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/storage/documents/file/:document_id",
    DOCUMENT_BASE_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/storage/documents/base64/:document_id",
    DOCUMENT_GET_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/documents/:document_id",
    DOCUMENT_UPDATE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/documents/:document_id",
    DOCUMENT_SET_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/documents",
    DOCUMENTS_DEL_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/documents/",
    REGISTRY_LIST_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/registry_history/",
    REGISTRY_DOWNLOAD_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/registry_history/download/:reg_ids",
    REGISTRY_GENERATE_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/registry_history/generate/",

    // ACTES
    ACTES_URL:                              process.env.VUE_APP_BASE_API_URL + "/api/actes",
    ACTES_HIST_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/actes/history?start=:start&end=:end&actes_types=:actes_types",
    ACTES_HIST_CA_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/actes/history/ca?start=:start&end=:end",
    ACTES_PLAN_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/actes/planning",
    ACTES_REPLAN_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/actes/replan/:acte_ids",
    ACTES_SET_VALIDE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/actes/valide/:acte_ids",
    ACTES_GROUPES_URL:                      process.env.VUE_APP_BASE_API_URL + "/acte-type/Webservice/Groupes",
    ACTES_TYPES_URL:                        process.env.VUE_APP_BASE_API_URL + "/acte-type/Webservice/Types/",
    ACTES_TYPES_USER_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/acte_type/",
    ACTES_TYPES_USER_DET_URL:               process.env.VUE_APP_BASE_API_URL + "/api/acte_type_details",
    ACTES_TYPES_DISABLE_URL:                process.env.VUE_APP_BASE_API_URL + "/api/acte_type/disable/",
    ACTES_TYPESREF_URL:                     process.env.VUE_APP_BASE_API_URL + "/acte-type/Webservice/TypesRef/",
    ACTES_SPECREF_URL:                      process.env.VUE_APP_BASE_API_URL + "/acte-type/Webservice/",
    ACTES_GET_QUESTIONS_URL:                process.env.VUE_APP_BASE_API_URL + "/api/acte_type/:acte_type_id/questions",
    ACTE_GET_TYPE_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/acte_type/:acte_type_id/",
    ACTE_UPDATE_TYPE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/acte_type/:acte_type_id/",
    ACTES_TYPE_ARTICLES_URL:                process.env.VUE_APP_BASE_API_URL + "/api/acte_type/:acte_type_id/articles",
    ACTES_SAVE_ANSWERS_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/actes/:acte_id/answers/",
	ACTES_GET_ANSWERS_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/actes/:acte_id/answers/",
	ACTES_TYPES_BLACKLISTED_URL:			process.env.VUE_APP_BASE_API_URL + "/api/clientareaatblacklist",
	ADD_ACTES_TYPES_BLACKLISTED_URL:		process.env.VUE_APP_BASE_API_URL + "/api/clientareaatblacklist/add",
	DELETE_ACTES_TYPES_BLACKLISTED_URL:		process.env.VUE_APP_BASE_API_URL + "/api/clientareaatblacklist/delete",
	ACTES_NOTIFY_CLIENT:					process.env.VUE_APP_BASE_API_URL + "/api/actes/notify",
    ACTES_ANALYSE_LABO_URL:                 process.env.VUE_APP_BASE_API_URL + "/acte-type/Webservice/Laboratoire/:acte_type/:date",
    ACTES_ANALYSE_MATRICE_URL:              process.env.VUE_APP_BASE_API_URL + "/acte-type/Webservice/Matrice/:acte_type/",
    RESULTAT_ANALYSE_URL:                   process.env.VUE_APP_BASE_API_URL + "/acte-type/Webservice/Resultat/:result_type",
    INTERPRETATION_ANALYSE_URL:             process.env.VUE_APP_BASE_API_URL + "/acte-type/Webservice/Interpretation/",
    SAVE_ANALYSE_RESULT_URL:                process.env.VUE_APP_BASE_API_URL + "/api/actes/analyse_result",
    UPDATE_ANALYSE_RESULT_URL:              process.env.VUE_APP_BASE_API_URL + "/api/actes/analyse_result/edit",
    GENERATE_ANALYSE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/actes/:actes_ids/analyse/:tiers1_id/:tiers2_id",
    RESULTAT_SANITAIRE_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/resultat_sanitaire/:actetype_id/:start/:end",
    RESULTAT_SANITAIRE_HORSE_URL:           process.env.VUE_APP_BASE_API_URL + "/api/resultat_sanitaire/horse/:horse_id",
    EDIT_ANALYSE_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/analyses/:analyse_id/",
    LIST_DEMANDE_ANALYSE:                   process.env.VUE_APP_BASE_API_URL + "/api/analyses/:start_date/:end_date",
    EMBRYONS_COLLECTE_GET_URL:				process.env.VUE_APP_BASE_API_URL + "/api/actes/:actes_id/embryons/collecte",
    FREE_EMBRYON_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/actes/:acte_id/embryons/free",
    DISABLE_ACT_TYPE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/acte_type/disable_act_type",
    PRINT_ACTS:                             process.env.VUE_APP_BASE_API_URL + "/api/actes/:model_id/print_acts",

    //LICENCES
    LICENCES_GAMMES_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/gammes_list",
    LICENCES_OPTIONS_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/options_list",
    LICENCES_SUPPLEMENTS_URL:               process.env.VUE_APP_BASE_API_URL + "/api/supplements_list",
    LICENCES_SET_GAMME_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/set_licence_gamme",
    LICENCES_SET_OPTIONS_URL:               process.env.VUE_APP_BASE_API_URL + "/api/set_licence_options",
    LICENCES_SET_SUPPLEMENTS_URL:           process.env.VUE_APP_BASE_API_URL + "/api/set_licence_supplements",
    LICENCES_CA_CONFIG_URL:  	 	        process.env.VUE_APP_BASE_API_URL + "/api/licence_ca_config/:target_licence",
    USERS_GET_GAMME_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/get_user_gamme",
    USERS_GET_CURRENT_GAMME_URL:            process.env.VUE_APP_BASE_API_URL + "/api/current_gamme",
    ALL_APPLICATIONS_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/applications/all",
    LICENCE_APPLICATIONS_URL:               process.env.VUE_APP_BASE_API_URL + "/api/applications",
    ALL_CONFIG_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/config/all",
    LICENCE_CONFIG_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/config",

    // QUESTIONS
    QUESTION_TYPES_INPUT_URL:               process.env.VUE_APP_BASE_API_URL + "/api/question/types/",
    QUESTION_FROM_FK:                       process.env.VUE_APP_BASE_API_URL + "/api/question/from_fk/:id",

    // STATISTIQUES
    STATISTIQUE_LAST_SYNCHRO:               process.env.VUE_APP_BASE_API_URL + "/stat/lastSynchro",
    STATISTIQUE_NB_LAST_SYNCHRO:            process.env.VUE_APP_BASE_API_URL + "/stat/lastSynchroNb",
    STATISTIQUE_DEVICE:                     process.env.VUE_APP_BASE_API_URL + "/stat/device",
    STATISTIQUE_NB_USER:                    process.env.VUE_APP_BASE_API_URL + "/stat/nbUser",
    STATISTIQUE_NEW_USER:                   process.env.VUE_APP_BASE_API_URL + "/stat/newUser",
    STATISTIQUE_NB_LICENCE:                 process.env.VUE_APP_BASE_API_URL + "/stat/nbLicence",
    STATISTIQUE_NB_HORSE:                   process.env.VUE_APP_BASE_API_URL + "/stat/nbHorse",
    STATISTIQUE_LICENCE_NB_HORSE_LIMIT:     process.env.VUE_APP_BASE_API_URL + "/stat/licenceNbHorses",
    STATISTIQUE_USER_LAST_SYNCHRO:          process.env.VUE_APP_BASE_API_URL + "/stat/lastSynchro/:licence_key",
    STATISTIQUE_USER_DEVICE:                process.env.VUE_APP_BASE_API_URL + "/stat/device/:licence_key",
    STATISTIQUE_LICENCE_NB_HORSE:           process.env.VUE_APP_BASE_API_URL + "/stat/nbHorse/:licence_key",
    STATISTIQUE_NEW_ACCOUNT:                process.env.VUE_APP_BASE_API_URL + "/stat/newAccount/",
    STATISTIQUES_PARRAINAGE:                process.env.VUE_APP_BASE_API_URL + "/stat/parrainage/",

    // COMPTABILITE
    ACCOUNTING_PLAN_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/accounting",
    ACCOUNTING_PLAN_URL_BY_USER:            process.env.VUE_APP_BASE_API_URL + "/api/accounting/:user_licence_key/:user_licence_id/accounting_by_user",
    ARCHIVED_ACCOUNTING_PLAN_URL:           process.env.VUE_APP_BASE_API_URL + "/api/archived_accounting",
    ACCOUNTING_PLAN_CLIENTACCOUNT_GENERATE: process.env.VUE_APP_BASE_API_URL + "/api/accounting/client_account_regenerate/",
    ACCOUNTING_PLAN_WITH_ARTICLES_URL:      process.env.VUE_APP_BASE_API_URL + "/api/accounting/articles",
    ACCOUNTING_PLAN_WITH_PENSION_TYPE_URL:  process.env.VUE_APP_BASE_API_URL + "/api/accounting/pension_type",
    ACCOUNTING_PLAN_ACTES_TYPE_ARTICLES_URL:process.env.VUE_APP_BASE_API_URL + "/api/accounting/acte_type/:actestype_id/articles",
    ACCOUNTING_PLAN_GET_URL:                process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id",
    ACCOUNTING_PLAN_DUPLICATE_URL:          process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/duplicate/",
    ACCOUNTING_ARTICLES_URL:                process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/articles",
    ACCOUNTING_ALL_ARTICLES_URL:            process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/articles/all",
    ACCOUNTING_PENSION_TYPE_URL:            process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/pension_type",
    ACCOUNTING_ACCOUNT_ACCOUNTING_URL:      process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/accounting_account",
    ACCOUNTING_PAYMENT_ACCOUNT_ACCOUNTING_URL: process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/accounting_payment_account",
    ACCOUNTING_ACTES_TYPE_ARTICLES_URL:     process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/acte_type/:actestype_id/articles",
    ACCOUNTING_REMISE_CHEQUE_CONFIG_URL:    process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/check_deposit_configuration",
	ACCOUNTING_ALL_REMISE_CHEQUE_CONFIG_URL: process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/all_check_deposit_configuration",
    ACCOUNTING_REMISE_CHEQUE_CONFIG_DELETE_URL:    process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/check_deposit_configuration/:checkdepositconfiguration_id",
    ACCOUNTING_CODE_ANA_URL:                process.env.VUE_APP_BASE_API_URL + "/api/accounting/from_tiers/:accountingplan_tiers/code_analytics",
    ACCOUNTING_PLAN_SUPPLIER_URL:           process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/supplier",
    ARCHIVE_ACCOUNTING_PLAN_URL:            process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accounting_plan_ids/archive",
    UNARCHIVE_ACCOUNTING_PLAN_URL:          process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accounting_plan_ids/unarchive",
    GET_TRAINER_CODE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/trainer_code",
    SAVE_TRAINER_CODE_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/trainer_code",
    GET_TRAINER_JOCKEY_ARTICLE_URL:         process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/jockey_trainer_articles",
    SAVE_TRAINER_JOCKEY_ARTICLE_URL:        process.env.VUE_APP_BASE_API_URL + "/api/accounting/:accountingplan_id/jockey_trainer_articles",
    SYNC_PERFOMANCES:                       process.env.VUE_APP_BASE_API_URL + "/api/accounting/sync_performances",
    ALL_REMISE_CHEQUE_CONFIG_URL:           process.env.VUE_APP_BASE_API_URL + "/api/accounting/all_check_deposit_configuration_without_accounting/",
    ACCOUNTING_PLAN_MODEL_URL:              process.env.VUE_APP_BASE_API_URL + "/api/accounting/model/:model_type",
    ACCOUNTING_PLAN_MODEL_PRINT_URL:        process.env.VUE_APP_BASE_API_URL + "/api/accounting/model/:model_id/print",

    // PENSION
    PENSION_URL:                            process.env.VUE_APP_BASE_API_URL + "/api/pension",
    PENSION_GET_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/pension/:horsepension_id",
    PENSION_HORSE_DATE_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/pension/:horse_id/date",
    PENSION_HORSE_CLOSE_URL:                process.env.VUE_APP_BASE_API_URL + "/api/pension/:horse_id/cloture",
    PENSION_STATE_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/pension/:pension_id/state",
    PENSION_LISTE_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/pension/horsepension/list",
    PENSION_PRINT_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/pension/horsepension/print",

    // PENSION TYPE
    PENSION_TYPE_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/pension_type",
    PENSION_TYPE_GET_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/pension_type/:pension_id",

    // ARTICLES
    ARTICLES_URL:                           process.env.VUE_APP_BASE_API_URL + "/api/articles",
	ARTICLES_GET_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/articles/:articles_id",
	ARTICLES_GET_DEBOURS_AVF:				process.env.VUE_APP_BASE_API_URL + "/api/articles/suppliers/:invoicesupplier_id/:accountingplan_id",
    ARTICLES_VAT_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/articles/vat",
    ARTICLES_ACCOUNTING_URL:                process.env.VUE_APP_BASE_API_URL + "/api/articles/accounting",
    ARTICLES_IMPORT:                        process.env.VUE_APP_BASE_API_URL + "/api/articles/import/",

    // COMPTE COMPTABLE
    ACCOUNT_ACCOUNTING_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/accounting_account",
    ACCOUNT_ACCOUNTING_GET_URL:             process.env.VUE_APP_BASE_API_URL + "/api/accounting_account/:accountingaccount_id",

    //COMPTE COMPTABLE REGLEMENT
    PAYMENT_ACCOUNT_ACCOUNTING_URL:         process.env.VUE_APP_BASE_API_URL + "/api/accounting_payment_account",
    PAYMENT_ACCOUNT_ACCOUNTING_GET_URL:     process.env.VUE_APP_BASE_API_URL + "/api/accounting_payment_account/:accounting_payment_account_id",

    // LOGICIELS COMPTABLES
    ACCOUNTING_SOFTWARE_URL:                process.env.VUE_APP_BASE_API_URL + "/api/accounting_software",

    // GYNECOLOGIE
    SEASONS_GET_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/seasons",
    SEASON_HORSE_GET_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/seasons/:season_id/horse/:horse_id",
    SEASON_MARE_GET_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/season_mare/",
    SEASON_MARE_HORSE_GET_URL:              process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/season_mare/horse/:horse_id",
    SEASON_MARE_COMMENTARY_GET_URL:         process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/season_mare/:seasonmare_id/commentary",
    SEASON_MARE_STALLION_URL:               process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/season_mare_stallion",
    SEASON_MARE_STALLION_GET_URL:           process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/season_mare_stallion/:seasonmarestallion_id",
	SEASON_EMBRYONS_URL:					process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/seasons/:season_id/embryons",
    EMBRYONS_UPSERT_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/embryons",
    EMBRYONS_UPSERT_COUPLE_URL:             process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/embryons/couple",
    EMBRYONS_ID_URL:	       				process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/embryons/:embryon_id",
	EMBRYONS_AVAILABLE_URL:					process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/embryons/available",
	EMBRYONS_EDIT_URL:						process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/embryons/:embryon_id/edit",
	SEASON_EMBRYONS_MARE_URL:				process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/seasons/mare/:season_id/embryons",
    EMBRYONS_EXPEDITION:                    process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/embryons/expedition/:tiers_id",
    HORSE_DPS_STATUS_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/horse_dps_status",

    SEASON_FERTILITE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/fertilite/:season_id",
	GYNECOLOGIE_STAT_URL:					process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/statistiques/",
    INFO_SAILLIES_WS_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/saillies/:horse_id/",
    INFO_SAILLIES_SEASON_WS_URL:            process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/saillies/:horse_id/:season_id/",

    STAT_SEASON_STALLION_URL:               process.env.VUE_APP_BASE_API_URL + "/api/monte/statistiques/:season_id/:stallion_id",
    SEND_DG_MAIL:                           process.env.VUE_APP_BASE_API_URL + "/api/gynecologie/send_mail_dg",

    // INVOICES
    INVOICES_URL:                           process.env.VUE_APP_BASE_API_URL + "/api/invoices/",
	INVOICES_SAILLIE_URL:				    process.env.VUE_APP_BASE_API_URL + "/api/invoices/saillie",
    INVOICES_DETAILS:                       process.env.VUE_APP_BASE_API_URL + "/api/invoices/:invoice_id/details",
	INVOICES_DELETE_URL:				    process.env.VUE_APP_BASE_API_URL + "/api/invoices/:invoice_ids/delete",
    INVOICES_BONS_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/invoices/details/:type/:id/",
    INVOICES_BONS_V2_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/invoices/details_v2/:type/:id/",
    INVOICES_BONS_ERREUR_URL:               process.env.VUE_APP_BASE_API_URL + "/api/invoices/bons/erreur/:l1/:l2",
    INVOICES_BONS_BY_TIERS_URL:             process.env.VUE_APP_BASE_API_URL + "/api/invoices/details/tiers/:tiers_id",
    LAST_INVOICE_BY_ENTITY_URL:             process.env.VUE_APP_BASE_API_URL + "/api/invoices/last/entity/:author_id",
    INVOICES_BILAN_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/invoices/bilan/:start_date/:end_date",
    INVOICES_FACTURATION_A_VALIDER_URL:     process.env.VUE_APP_BASE_API_URL + "/api/invoices/a_valider/:start_date/:end_date/:display_zero/:display_abandonne",
    INVOICES_ABANDON_A_VALIDER_URL:         process.env.VUE_APP_BASE_API_URL + "/api/invoices/a_valider/abandon/",
    INVOICES_ARTICLES_COURSE_URL:           process.env.VUE_APP_BASE_API_URL + "/api/invoices/articles_course/:start_date/:end_date",
    INVOICES_A_VALIDER_BY_TIER_URL:         process.env.VUE_APP_BASE_API_URL + "/api/invoices/tiers/a_valider/:tiers_id/:start_date/:end_date/:display_zero/:display_abandonne",
    INVOICES_FACTURATION_SIGN_URL:          process.env.VUE_APP_BASE_API_URL + "/api/invoices/a_valider_monte/signature/:season_id",
    INVOICES_FACTURATION_SAILLIE_URL:       process.env.VUE_APP_BASE_API_URL + "/api/invoices/a_valider_monte/saillie/:season_id",
    INVOICES_FACTURATION_OCTBR_URL:         process.env.VUE_APP_BASE_API_URL + "/api/invoices/a_valider_monte/octobre/:season_id",
    INVOICES_FACTURATION_POULAIN_URL:       process.env.VUE_APP_BASE_API_URL + "/api/invoices/a_valider_monte/poulain/:season_id",
    INVOICES_CREATE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/invoices/create/",
    INVOICES_DUPLICATE_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/invoices/:invoice_ids/duplicate/",
    INVOICES_PDF_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/invoices/:invoice_ids/pdf/",
    INVOICES_DOWNLOAD_PDF_URL:              process.env.VUE_APP_BASE_API_URL + "/api/invoices/:invoice_ids/pdf/download",
    INVOICES_UNPAID_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/invoices/unpaid/:start_date/:end_date",
    INVOICES_RELANCE_MANUELLE_URL:          process.env.VUE_APP_BASE_API_URL + "/api/invoices/send_relance_manuelle/",
    INVOICES_CALCUL_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/invoices/calcul/",
    INVOICES_VAT:                           process.env.VUE_APP_BASE_API_URL + "/api/invoices/vat/",
    INVOICES_VAT_CODE:                      process.env.VUE_APP_BASE_API_URL + "/api/invoices/tiers/vat/default_code/:author_id/:tiers_id",
    INVOICES_CREDIT:                        process.env.VUE_APP_BASE_API_URL + "/api/invoices/credit/",
    INVOICES_CREDIT_GET:                    process.env.VUE_APP_BASE_API_URL + "/api/invoices/credit/:invoice_id", //Pour récupérer les factures d'avoir en fonction d'une facture classique
    INVOICES_BALANCE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/invoices/balance/:invoice_ids",
    INVOICES_CREDIT_DATE_CHECK:             process.env.VUE_APP_BASE_API_URL + "/api/invoices/credit/date_check/:invoice_date/:author_id", //Permet de vérifier si la date que je renseigne est ok
    INVOICES_EXPORT_COMPTABLE:              process.env.VUE_APP_BASE_API_URL + "/api/invoices/export_comptable/",
    INVOICES_SEND:                          process.env.VUE_APP_BASE_API_URL + "/api/invoices/send/",
    INVOICES_ADRESSED_TOPAY:                process.env.VUE_APP_BASE_API_URL + "/api/invoices/addressed/unpaid/",
    INVOICES_ADRESSED_PAID:                 process.env.VUE_APP_BASE_API_URL + "/api/invoices/addressed/paid/",
    INVOICES_PDF_ADDRESSED:                 process.env.VUE_APP_BASE_API_URL + "/api/invoices/addressed/:invoice_id/pdf",
    TIERS_SOLDE:                            process.env.VUE_APP_BASE_API_URL + "/api/invoices/tiers/:tiers_id/",
	INVOICES_ACCOUNTED_URL:					process.env.VUE_APP_BASE_API_URL + "/api/invoices/accounted",
	INVOICES_NOT_PRINTED_URL:				process.env.VUE_APP_BASE_API_URL + "/api/invoices/not_printed",
    INVOICES_DISCOUNT_TYPE:                 process.env.VUE_APP_BASE_API_URL + "/api/invoices/discount/type",
    INVOICE_PREPARE_CALCUL_URL:             process.env.VUE_APP_BASE_API_URL + "/api/invoices/prepare/:author_id/:tiers_id",

	INVOICES_SUPPLIER_LIST:                 process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/list/:tiers_id",
    INVOICES_SUPPLIER_DETAILS_LIST:         process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/:invoice_id/details",
	INVOICES_SUPPLIER_SAVE:                 process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/:invoice_id",
	INVOICES_SUPPLIER_ADD_FILE:             process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/:invoice_id/document",
	INVOICES_SUPPLIER_GET:					process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/:invoice_id",
	INVOICES_SUPPLIER_ASSOCIATE:			process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/:invoice_id/horse",
    INVOICES_SUPPLIER_DOCUMENT:             process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/:invoice_id/document",
    INVOICES_SUPPLIER_DELETE_URL:			process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/:invoice_id/delete",
    INVOICES_SUPPLIERS_DETAILS_CREATE_URL:  process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/details/create/",
    INVOICES_SUPPLIERS_OCR_URL:             process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr",
    INVOICES_SUPPLIERS_OCR_LIST_CONTROL:    process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/control/:entity_ids",
    INVOICES_SUPPLIERS_OCR_TIERS_URL:       process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/tiers/:tiers_id",
    INVOICES_SUPPLIERS_TIERS_OCR_CONTROL:   process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/tiers/:tiers_id/control/:entity_ids",
    INVOICES_SUPPLIERS_OCR_LIST_CHECKED:    process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/checked/:entity_ids",
    INVOICES_SUPPLIERS_TIERS_OCR_CHECKED:   process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/tiers/:tiers_id/checked/:entity_ids",
    INVOICES_SUPPLIERS_OCR_ID_URL:          process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/:invoicesupplier_id",
    INVOICES_SUPPLIERS_OCR_DUPLICATE_URL:   process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/duplicate/:invoicesupplier_ids",
    INVOICES_SUPPLIERS_OCR_SOLDE_URL:       process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/:invoicesupplier_id/solde",
    INVOICES_SUPPLIERS_OCR_CHARGE_URL:      process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/:invoicesupplier_id/charge",
    INVOICES_SUPPLIERS_OCR_CHARGE_HORSE_URL:process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/horse/:horse_id/charge",
    INVOICES_SUPPLIERS_OCR_ACCOUNTED_URL:   process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/accounted/:invoicesupplier_ids",
    INC_SUPPL_CURRENT_ACCOUNT:              process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/current_account/:tiers_id",
    INC_SUPPL_CURRENT_ACCOUNT_DETAILS:      process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/current_account/:tiers_id/details",
    INC_SUPPL_EXPORT_COMPTABLE:             process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/export_comptable/",
    INC_SUPPL_ACCOUNT_MOST_USED:            process.env.VUE_APP_BASE_API_URL + "/api/invoices/supplier/ocr/most_used_account/:entity_id/:tiers_id",
    INC_PAYMENTS_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/payments_supplier",
    INC_PAYMENTS_ID_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/payments_supplier/:payment_id",
    INC_PAYMENTS_CANCEL_URL:                process.env.VUE_APP_BASE_API_URL + "/api/payments_supplier/:payment_ids/cancel",
    INC_PAYMENTS_TIERS_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/payments_supplier/tiers/:tiers_id",

    INVOICE_STAT_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/invoices/stat/",
    INVOICE_BONS_STAT_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/invoices/bons/stat",
    INVOICE_CHANGE_TIERS_URL:               process.env.VUE_APP_BASE_API_URL + "/api/invoices/tiers/change",
    
    INVOICES_CONTROL_PROPERTIES_URL:        process.env.VUE_APP_BASE_API_URL + "/api/invoices/control/:start_date/:end_date",
    INVOICES_CHECK_MONTH_URL:               process.env.VUE_APP_BASE_API_URL + "/api/invoices/check_month/",
    INVOICES_SOLDE_AVOIR_URL:               process.env.VUE_APP_BASE_API_URL + "/api/invoices/:invoice_id/solde_avoir",
    INVOICE_SOLDE_CC_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/invoices/solde_cc",
    INVOICE_GET_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/invoices/:invoice_id",

    // INVOICES DETAILS
    INVOICES_DETAILS_CREATE_URL:            process.env.VUE_APP_BASE_API_URL + "/api/invoices/details/create/",
    INVOICES_DETAILS_CREATE_MULTIPLE_URL:   process.env.VUE_APP_BASE_API_URL + "/api/invoices/multiple_details/create/",
    INVOICES_DETAILS_PREVIEW_URL:           process.env.VUE_APP_BASE_API_URL + "/api/invoices/details/preview/",
    INVOICES_DETAILS_VIERGE_TIERS_URL:      process.env.VUE_APP_BASE_API_URL + "/api/invoices/details/vierge/:tiers_ids/:contract_id",
    INVOICES_DETAILS_UPDATE_URL:            process.env.VUE_APP_BASE_API_URL + "/api/invoices/details/update/:invoicedetails_id/",
    INVOICES_DETAILS_UPDATE_ORDER_URL:      process.env.VUE_APP_BASE_API_URL + "/api/invoices/details/order/",
    INVOICES_DETAILS_DELETE_URL:            process.env.VUE_APP_BASE_API_URL + "/api/invoices/details/:invoicedetails_ids/:send_a_valider",
    INVOICES_DETAILS_DEDOUBLONNER_URL:      process.env.VUE_APP_BASE_API_URL + "/api/invoices/details/:invoicedetails_ids/dedoublonner/",
    INVOICES_DETAILS_EDIT_HT_URL:           process.env.VUE_APP_BASE_API_URL + "/api/invoices/details/:invoicedetails_ids/edit_ht_initial",
    INVOICES_DETAILS_VAT_RECHECK_URL:       process.env.VUE_APP_BASE_API_URL + "/api/invoices/recheck_vat/",
    INVOICES_DETAILS_PW_PROGRESS_URL:       process.env.VUE_APP_BASE_API_URL + "/api/invoices/details/preview/progress/:progress_id",


    // PAYMENTS
    PAYMENTS_URL:                           process.env.VUE_APP_BASE_API_URL + "/api/payments/",
    PAYMENTS_REGUL_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/payments/regul",
	PAYMENTS_ACCOUNTED_URL:					process.env.VUE_APP_BASE_API_URL + "/api/payments/accounted",
    PAYMENTS_EDIT_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/payments/:payment_id",
    PAYMENTS_CREATE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/payments/create",
    PAYMENTS_UNDEPOSIT_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/payments/get/:accountingplan_id/:payment_method",
    PAYMENTS_LINKED_CHECK_DEPOSIT_URL:      process.env.VUE_APP_BASE_API_URL + "/api/payments/has_check_deposit",
    PAYMENTS_DELETE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/payments/delete/",
    PAYMENTS_SET_UNPAID_URL:                process.env.VUE_APP_BASE_API_URL + "/api/payments/unpaid/",
    PAIEMENTS_ADRESSED:                     process.env.VUE_APP_BASE_API_URL + "/api/invoices/addressed/payment/",
    PAYMENT_EXPORT:                         process.env.VUE_APP_BASE_API_URL + "/api/payments/export_comptable/",
    HORSE_UNPAID_BY_INVOICES:               process.env.VUE_APP_BASE_API_URL + "/api/invoices/:invoices_ids/horses/unpaid/",
    PAYMENTS_INIT_FROM_BANK_URL:            process.env.VUE_APP_BASE_API_URL + "/api/payments/init_from_bank",
    PAYMENTS_CREATE_FROM_BANK_URL:          process.env.VUE_APP_BASE_API_URL + "/api/payments/create_from_bank",

    //REMISES CHEQUE
    CHECK_DEPOSIT_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/check_deposit/",
    CHECK_DEPOSIT_CREATE_URL:               process.env.VUE_APP_BASE_API_URL + "/api/check_deposit/",
    CHECK_DEPOSIT_DELETE_URL:               process.env.VUE_APP_BASE_API_URL + "/api/check_deposit/",
    CHECK_DEPOSIT_CASH_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/check_deposit/cash/",
    CHECK_DEPOSIT_PRINT_URL:                process.env.VUE_APP_BASE_API_URL + "/api/check_deposit/print/",

    // TIERS
    TIERS_FUSION_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/tiers/fusion/",
    TIERS_LISTE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/tiers/",
    TIERS_CONTRACTS_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/contracts",
    TIERS_ENTITY_LISTE_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/tiers/entity",
    TIERS_FICHE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id",
    TIERS_PHONE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/phones/",
    TIERS_CONTACT_FAVORIS_URL:              process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/contact_favoris/",
    TIERS_CONTACT_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/contact/",
    TIERS_CONTACT_EDIT_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/contact/:contact_id",
    TIERS_HORSE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/horse/",
    TIERS_HORSE_CURRENT_PART_URL:           process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/horse_current_part/",
    TIERS_HORSE_PART_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/horse_part/",
    TIERS_ARTICLES_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/articles",
    TIERS_INVOICES_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/invoices",
    TIERS_PAYMENTS_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/payments",
    TIERS_ACCOUNTING_ACCOUNT_URL:           process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/accounting_account",
    TIERS_RIB_URL:                          process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/rib",
    TIERS_CLIENT_ACCOUNT_URL:               process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/client_account",
    TIERS_JURIDIQUE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/juridique",
    TIERS_PARAMETERS_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/parameters",
    TIERS_CURRENT_ACCOUNT_URL:              process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/current_account/:keys_id",
    TIERS_CURRENT_ACCOUNT_DETAILS_URL:      process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/current_account/details/",
    TIERS_DATE_DUEDATE_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/date_duedate/:date",
    TIERS_DUEDATE_CONFIG_URL:               process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers/config_duedate/",
    DUEDATE_CONFIG_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/invoices/config_duedate/",
    TIERS_UPDATE_DUEDATE_CONFIG_URL:        process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/config_duedate/",
    TIERS_MODELS_URL:				        process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/models/",
	TIERS_INFOS_URL: 						process.env.VUE_APP_BASE_API_URL + "/api/tiers/generatepdf_tiers_infos",
	TIERS_MONTE_RESA_URL: 					process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/monte/reservations",
	CITIES_AND_CP_URL:						process.env.VUE_APP_BASE_API_URL + "/api/tiers/communes/cities_cp",
    TIERS_SYNDIC_DETAILS_URL:               process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/syndic/:season_id",
    TIERS_GROUPE_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/groupes",
    TIERS_DUPLICATE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/duplicate",
    TIERS_SAVE_GROUPE_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/tiers/:principal_tiers_id/save_groupe/:second_tiers_id",
    TIERS_GROUPE_DELETE_URL:                process.env.VUE_APP_BASE_API_URL + "/api/tiers/delete_groupe/",
    TIERS_USERSTIERS_DELETE_URL:            process.env.VUE_APP_BASE_API_URL + "/api/tiers/delete_user_tiers",
    TIERS_HUBSPOT_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/hubspot",
    TIERS_HUBSPOT_SEARCH_URL:               process.env.VUE_APP_BASE_API_URL + "/api/tiers/hubspot/search/:search_val",
    TIERS_IMPORT:                           process.env.VUE_APP_BASE_API_URL + "/api/tiers/import/",
    SEARCH_QUALIFICATION:                   process.env.VUE_APP_BASE_API_URL + "/api/qualifications/:type",
    TIERS_SWITCH_FORMATS_RS:                process.env.VUE_APP_BASE_API_URL + "/api/tiers/raison_sociale/switch_format",
    TIERS_MOST_USED_CMEP:                   process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/most_used_cmep",
    TIERS_GOCARDLES_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/gocardless",
    TIERS_PAPPERS_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/tiers/pappers/:val/:type",
    TIERS_PAPPERS_VAT_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/tiers/pappers_vat/:val",
    TIERS_PAPPERS_RNE_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/tiers/pappers_rne/:val",
    // TIERS_PAPPERS_URL:                      "https://api.pappers.fr/v2/recherche?api_token=:api_key&q=:tiers_rs&bases=entreprises",
    SEND_TIERS_MAIL:                        process.env.VUE_APP_BASE_API_URL + "/api/tiers/send_mail_tiers",
    SEND_TIERS_COMPTA_MAIL:                 process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/send_mail_tiers_compta",
    CHECK_VAT_URL:                          process.env.VUE_APP_BASE_API_URL + "/api/tiers/check_vat/:vat_number/:country",
    TIERS_IMG_SEARCH_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/tiers/img_search",
    TIERS_GET_IMG_FROM_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/tiers/img_get",
    TIERS_HAS_GROOMY:                       process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/has_groomy",

    // FONCTION
    FONCTION_URL:                           process.env.VUE_APP_BASE_API_URL + "/api/tiers/fonction/",


    // FILES
    UPLOAD_FILES_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/files/",
    UPLOAD_FILES_MEDIA_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/files/media/",
	UPLOAD_FILES_EMBEDD_URL:				process.env.VUE_APP_BASE_API_URL + "/api/files/embedded/",


    // CONTACT
    CONTACT_PHONE_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/contact/:contact_id/phones/",

    // MODEL (Facture, courriers)
    MODEL_TYPE_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/model_type/",
    MODEL_URL:                              process.env.VUE_APP_BASE_API_URL + "/api/:modelable_type/:modelable_id/model",
    MODEL_TIERS_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/:modelable_type/:modelable_id/model/tiers/:tiers_id",
    MODEL_FROM_ADDRESS_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/model/from_address",
    MODEL_DETAILS_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/:modelable_type/:modelable_id/model/:model_id",
    MODEL_TEMPLATE_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/:modelable_type/:modelable_id/model/:model_id/template",
    MODEL_TEMPLATE_UPDATE_URL:              process.env.VUE_APP_BASE_API_URL + "/api/:modelable_type/:modelable_id/model/:model_id/template/:modeltemplate_id",
    MODEL_UPDATE_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/:modelable_type/:modelable_id/model/:model_id",
    MODEL_TYPE_VARIABLES_URL:               process.env.VUE_APP_BASE_API_URL + "/api/model_type/variables/:modeltype_id",
    MODEL_WITHOUT_TYPE_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/model/:model_id",
    MODEL_CLONE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/model/:model_id/clone/:copy_id",
    MODEL_GENERATE_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/:modelable_type/:modelable_id/generate/:model_id",
    MODEL_PRINT_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/model/:model_ids/print",
    MODEL_CONTRACT_CA_DEFAULT_URL:          process.env.VUE_APP_BASE_API_URL + "/api/model/:model_id/:contractconfig_id/default_ca",
    MODEL_CONTRACT_CA_SEASON_DEFAULT_URL:	process.env.VUE_APP_BASE_API_URL + "/api/model/:model_id/:season_id/season/default_ca",
	MODEL_DELETE_URL:						process.env.VUE_APP_BASE_API_URL + "/api/model/delete",
	MODEL_ARCHIVE_URL:					    process.env.VUE_APP_BASE_API_URL + "/api/model/archive",
	MODEL_STALLION_URL:						process.env.VUE_APP_BASE_API_URL + "/api/model/:model_id/stallions",
	MODEL_DISTRIBUTE_URL:					process.env.VUE_APP_BASE_API_URL + "/api/model/:model_id/distribute",
	MODEL_DUPLICATE_URL:					process.env.VUE_APP_BASE_API_URL + "/api/model/:model_id/duplicate",
    MODEL_SEASON_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/model/:model_id/global_season",
    MODEL_ZONE_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/model_zone/:modelzone_id",

    // PARRAINAGE
    PARRAINAGE_SOLDE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/fidelite/solde",
    PARRAINAGE_FILLEUL_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/fidelite/filleul",
    PARRAINAGE_HISTORIQUE_URL:              process.env.VUE_APP_BASE_API_URL + "/api/fidelite/historique",
    PARRAINAGE_CODE_PARRIN_URL:             process.env.VUE_APP_BASE_API_URL + "/api/fidelite/code_parrain",

    // MEDIAS
    MEDIAS_FILE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/storage/get/media/:media_id",

    // CONTRACT
    CONTRACT_GET_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id",
    CONTRACT_ADD_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/contract",
    CONTRACT_ADD_FROM_HORSE_URL:            process.env.VUE_APP_BASE_API_URL + "/api/contract/horse/:horse_id",
    CONTRACT_ADD_FROM_TIERS_URL:            process.env.VUE_APP_BASE_API_URL + "/api/contract/tiers/:tiers_id",
    CONTRACT_DUPLICATE_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/duplicate",
    CONTRACT_SAVE_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id",
	CONTRACT_CONFIG_UPDATE:                 process.env.VUE_APP_BASE_API_URL + "/api/contract/prefix/:contractconfig_id/",
	CONTRACT_CONFIG_MODEL:              	process.env.VUE_APP_BASE_API_URL + "/api/contract/config/:contractconfig_id/model",
	CONTRACT_PRINT_URL:						process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/pdf",
	CONTRACT_PRINT_LOT_URL:					process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_ids/pdf_multiple",
    CONTRACT_SEND_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/contract/send",
	CONTRACT_DOCUMENT_SEND_URL:				process.env.VUE_APP_BASE_API_URL + "/api/contract/document/send",
    CONTRACT_INVOICES_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/invoices",
    CONTRACT_NUMBER:                        process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/number",
    CONTRACT_SAILLIE:                       process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/saillie",
    CONTRACT_SAILLIE_FREE:                  process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/saillie/free",
    CONTRACT_SAILLIE_RESA:                  process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/monte/reservations",
    CONTRACT_CONDITIONS_CA_INVOICE_URL:     process.env.VUE_APP_BASE_API_URL + "/api/contract/conditions/auto_invoice_ca",
    CONTRACT_CONDITIONS_SET_CA_INVOICE_URL: process.env.VUE_APP_BASE_API_URL + "/api/contract/conditions/:condition_id/auto_invoice_ca",
    CONTRACT_CA_PRIVACY_POLICY_URL:			process.env.VUE_APP_BASE_API_URL + "/api/ca_settings",
	CONTRACT_BULK_INVOICES:					process.env.VUE_APP_BASE_API_URL + "/api/contract/bulk/:contract_ids/invoices",
	CONTRACT_BULK_INVOICES_POST:			process.env.VUE_APP_BASE_API_URL + "/api/contract/bulk/invoices",
    CONTRACT_BILAN_SAISON_URL:              process.env.VUE_APP_BASE_API_URL + "/api/contract/bilan/:season_id/:start/:end",
	CONTRACT_CONFIG_SEASON_STALLION_URL:	process.env.VUE_APP_BASE_API_URL + "/api/contract_config/:season_id/stallions/:model_id",
	CONDITIONS_GLOBAL_URL:	                process.env.VUE_APP_BASE_API_URL + "/api/contract/conditions/:season_id/",
	CONTRACT_CONFIG_GLOBAL_URL:	            process.env.VUE_APP_BASE_API_URL + "/api/contract_config_global/:season_id/",
	CONTRACT_CONFIG_GLOBAL_STALLIONS_URL:	process.env.VUE_APP_BASE_API_URL + "/api/contract_config_global/:contractconfigglobal_id/stallions/",
	CONTRACT_CONFIG_GLOBAL_STALLIONS_WITH_MONTE_URL:	process.env.VUE_APP_BASE_API_URL + "/api/contract_config_global/:contractconfigglobal_id/stallions/:contractconfigglobaltypemonte_id",
	CONTRACT_CONFIG_NUMBER_TEMPLATE_URL:	process.env.VUE_APP_BASE_API_URL + "/api/contract_config/number_template",
	CONTRACT_CONFIG_GLOBAL_NOT_HORSES:	    process.env.VUE_APP_BASE_API_URL + "/api/contract_config_global/:contractconfigglobal_id/not_stallions",
	CONTRACT_CONFIG_CREATE_FROM_GLOBAL:     process.env.VUE_APP_BASE_API_URL + "/api/contract_config_global/:contractconfigglobal_id/add_child",
    CONTRACT_CONFIG_DELETE_FROM_GLOBAL:     process.env.VUE_APP_BASE_API_URL + "/api/contract_config_global/:contractconfigglobal_id/delete_child",
	CONTRACT_CONFIG_GLOBAL_STALLIONS_COND_URL:	process.env.VUE_APP_BASE_API_URL + "/api/contract_config_global/:contractconfigglobal_id/stallions_conditions",
    CONTRACT_CONFIG_GLOBAL_SWITCH_CA:           process.env.VUE_APP_BASE_API_URL + "/api/contract_config_global/switch_global_ca",
	CONTRACT_GLOBAL_STALLIONS_TYPEMONTE_URL:    process.env.VUE_APP_BASE_API_URL + "/api/contract_config_global/:contractconfigglobaltypemonte_id/stallions_typemonte",
	CONTRACT_GLOBAL_STALLIONS_DISTRIBUTE_URL:   process.env.VUE_APP_BASE_API_URL + "/api/contract_config_global/:contractconfigglobaltypemonte_id/distribute",
	CONTRACT_GLOBAL_STALLIONS_CONDITIONS_URL:	process.env.VUE_APP_BASE_API_URL + "/api/contract_config_global/:contractconfigglobaltypemonte_id/conditions",
	CONTRACT_AVENANT_ARTICLES:	                process.env.VUE_APP_BASE_API_URL + "/api/contract/avenant/:avenant_id/articles",
	CONTRACT_SAVE_AVENANT_ARTICLES:	        process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/avenant/:avenant_id/articles",
	SEASON_DUPLICATE_URL:	                process.env.VUE_APP_BASE_API_URL + "/api/season/duplicate/:season_id/",
    CONTRACT_EXPORT:                        process.env.VUE_APP_BASE_API_URL + "/api/contract/export",
    CONTRACT_AVENANT_ARTICLES_AVALIDER_GET_URL:     process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/get_avalider",
    CONTRACT_AVENANT_ADD_URL:               process.env.VUE_APP_BASE_API_URL + "/api/contract/avenant/:avenant_id/add",
    CONTRACT_FAST_SAVE_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/fast_save",
    CONTRACT_AVENANT_SIGN_URL:              process.env.VUE_APP_BASE_API_URL + "/api/contract/avenant/:avenant_id/sign",
    CONTRACT_DOCUMENT_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/document",
    CONTRACT_DOCUMENTS_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/documents",
    CONTRACT_DOCUMENT_BY_ID_URL:            process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/document/:document_id",
    CONTRACT_DOCUMENT_FILE_URL:             process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/document/:document_id/file",
    CONTRACT_SAVE_VERSION_URL:              process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/save_version",
    CONTRACT_INVOICE_DETAILS_AVENANT_ARTICLES_URL:  process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/invoice_details",
    CONTRACT_DELIER_INVOICE_DETAILS_AVENANT_ARTICLES_URL:  process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/delier/:avenantarticles_id/invoice_details/",
    CONTRACT_BLANK_PDF_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/contract_config/:contractconfig_id/:model_id/:condition_id/:lang/blank",
    CONTRACT_GET_CONTRACTS_STATUS_STALLION: process.env.VUE_APP_BASE_API_URL + "/api/contract/:stallion_id/:season_id/count_contracts_status",
    CONTRACT_CONFIG_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/contract_config/:contractconfig_id",
    CONTRACT_CREATE_LOT:                    process.env.VUE_APP_BASE_API_URL + "/api/contract/lot",
    CONTRACT_RELANCE_CENTRE:                process.env.VUE_APP_BASE_API_URL + "/api/contract/:tiers_id/:model_id/relance_centre",
    CONTRACT_COUPLE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/contract/solde_couple/:seasonmarestallion_ids",
    CONTRACT_AVENANT_ARTICLES_DELETE:       process.env.VUE_APP_BASE_API_URL + "/api/contract/avenant_articles/delete",
    CONTRACTS_STALLION_SEASON_URL:          process.env.VUE_APP_BASE_API_URL + "/api/contract_stallion/:stallion_id/:season_id",
    CONTRACT_SEND_SIGNATURE_URL:            process.env.VUE_APP_BASE_API_URL + "/api/contract/:contract_id/signature",

    // SYNDIC
    SYNDIC_URL:                             process.env.VUE_APP_BASE_API_URL + "/api/syndic/:syndic_id/",
    SYNDIC_SEASON_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/syndic/:syndic_id/:season_id",
    SYNDIC_TYPES_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/syndic/types/",
    SYNDIC_INVOICE_TYPES_URL:               process.env.VUE_APP_BASE_API_URL + "/api/syndic/invoice/types",
    SYNDIC_EDIT_PART:                       process.env.VUE_APP_BASE_API_URL + "/api/syndic/:syndic_id/part/:syndic_part_id",
    SYNDIC_EDIT_MULTI_PART:                 process.env.VUE_APP_BASE_API_URL + "/api/syndic/:syndic_id/parts/:syndic_part_ids",
    SYNDIC_ASSIGN_PART:                     process.env.VUE_APP_BASE_API_URL + "/api/syndic/:syndic_id/part/:syndic_part_id/assign_to",
	SYNDIC_PART_HISTORY_URL:				process.env.VUE_APP_BASE_API_URL + "/api/syndic/part/:syndicpart_id/history",
    SYNDIC_EDIT_DATE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/syndic/part/:syndicpart_id/cession",

	// MONTE
    CONTRACT_SAILLIE_RESA_DETAILS:          process.env.VUE_APP_BASE_API_URL + "/api/monte/reservation/:reservation_id",
    MONTE_RESA_LIST:						process.env.VUE_APP_BASE_API_URL + "/api/monte/reservations",
    MONTE_RESA_ORDER:						process.env.VUE_APP_BASE_API_URL + "/api/monte/reservation/:reservation_id/order",
    MONTE_RESA_ORDER_NOTIFY:				process.env.VUE_APP_BASE_API_URL + "/api/monte/reservation/notify_expediteur",
    MONTE_RESA_ORDER_EXPEDITEUR:            process.env.VUE_APP_BASE_API_URL + "/api/monte/reservation/:reservation_ids/expediteur",

    // EXPORT PERSO
    EXPORT_PERSO_UPDATE:                    process.env.VUE_APP_BASE_API_URL + "/api/export/:export_id/",
    EXPORT_PERSO_CREATE:                    process.env.VUE_APP_BASE_API_URL + "/api/export/",
    EXPORT_PERSO_GET:                       process.env.VUE_APP_BASE_API_URL + "/api/export/:licence_key/",
    EXPORT_PERSO_GET_ACCOUNTING:            process.env.VUE_APP_BASE_API_URL + "/api/export/accountingplan/:accountingplan_id/",
    EXPORT_PERSO_DEFAULT:                   process.env.VUE_APP_BASE_API_URL + "/api/export/default/",

    // TRIGGER
    TRIGGER_ENTITY_GET:                     process.env.VUE_APP_BASE_API_URL + "/api/trigger_entity/:triggerentity_id",
    TRIGGER_ENTITY:                         process.env.VUE_APP_BASE_API_URL + "/api/trigger_entity/",
    TRIGGER_ENTITY_TYPE:                    process.env.VUE_APP_BASE_API_URL + "/api/trigger_entity_type/",
    TRIGGERED_ENTITY_TYPE:                  process.env.VUE_APP_BASE_API_URL + "/api/triggered_entity_type/",
    TRIGGER_URL:                            process.env.VUE_APP_BASE_API_URL + "/api/triggers/:triggerentity_id",
    TRIGGER_BY_ACTE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/trigger_by_acte_type/:acte_type",
    TRIGGER_LINK_ACTE:                      process.env.VUE_APP_BASE_API_URL + "/api/link_acte/",
    TRIGGERED_BY_TRIGGER:                   process.env.VUE_APP_BASE_API_URL + "/api/triggered_by_trigger/:trigger_entity",
    TRIGGERED_PERIODS:                      process.env.VUE_APP_BASE_API_URL + "/api/triggered_period/:triggered",
    TRIGGER_ACTIVE:                         process.env.VUE_APP_BASE_API_URL + "/api/trigger_active/:triggerentity_id",

    // NOTIFICATIONS
    NOTIFICATIONS_SUBSCRIBE_API:            process.env.VUE_APP_BASE_API_URL + "/api/notifications/subscribe",
    NOTIFICATIONS_UNSUBSCRIBE_API:          process.env.VUE_APP_BASE_API_URL + "/api/notifications/unsubscribe",

	// NOTES
	NOTES_URL:								process.env.VUE_APP_BASE_API_URL + "/api/notes",
	NOTES_DETAILS_URL:						process.env.VUE_APP_BASE_API_URL + "/api/notes/:note_id",
	NOTES_FILES_URL:						process.env.VUE_APP_BASE_API_URL + "/api/notes/:note_id/files",
	NOTES_SET_URL:         					process.env.VUE_APP_BASE_API_URL + "/api/notes/note",
	NOTES_HORSE_GET_URL:					process.env.VUE_APP_BASE_API_URL + "/api/horses/:horse_id/notes",
    NOTES_TIERS_GET_URL:					process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/notes",
	NOTES_NOTIFY_URL:						process.env.VUE_APP_BASE_API_URL + "/api/notes/ca_notify",
    NOTES_SEND:                             process.env.VUE_APP_BASE_API_URL + "/api/notes/send",

    // STOCK SEMENCE
    STOCK_SEMENCE_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/monte/stock",
    STOCK_SEMENCE_DETAILS_URL:              process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/:stock_id",
    STOCK_SEMENCE_DETAILS_COMMENTAIRE_URL:  process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/:stock_id/commentaire",
    STOCK_DISTANT_SEMENCE_URL:              process.env.VUE_APP_BASE_API_URL + "/api/monte/stock_distant/",
    STOCK_LOCAL_SEMENCE_URL:                process.env.VUE_APP_BASE_API_URL + "/api/monte/stock_local/",
    STOCK_LOCAL_SEMENCE_EXPORT_URL:         process.env.VUE_APP_BASE_API_URL + "/api/monte/stock_local/export/",
    STOCK_SEMENCE_TIERS_URL:                process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/stock",
    STOCK_SEMENCE_TIERS_HORSE_URL:          process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/stock/:horse_id",
    STOCK_EXTERNE_SEMENCE_TIERS_URL:        process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/stock/externe",
    STOCK_SEMENCE_HORSE_URL:                process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/stock",
    STOCK_LOCAL_HORSE_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/stock_local",
    STOCK_DISTANT_HORSE_URL:                process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/stock_distant",
    STOCK_SEMENCE_USE_URL:                  process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/use",
    STOCK_SEMENCE_USE_CANCEL_URL:           process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/use/cancel",
    STOCK_SEMENCE_RETOUR_URL:               process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/retour",
    STOCK_LOCAL_FLUX_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/flux/:begin/:end",
    STOCK_USED_ACTE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/use/:acte_id",
    STOCK_USED_ACTE_CANCEL_URL:             process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/use/:acte_id/cancel",
    STOCK_SEMENCE_SORTIE_URL:               process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/sortie",
    STOCK_BY_LOCALISATION:                  process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/localisation",
    DESTROY_STOCK:                          process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/destroy",
    PRINT_FLUX_PAILLETTES:                  process.env.VUE_APP_BASE_API_URL + "/api/monte/stock/print",

    // SYNCHRONISATION
    TABLE_CHECK_API:                        process.env.VUE_APP_BASE_API_URL + "/table/check_version/40000/web",
    TABLE_STRUCTURES_API:					process.env.VUE_APP_BASE_API_URL + "/table/structure/relationships/web",
    SYNC_DATA_API:							process.env.VUE_APP_BASE_API_URL + "/app/sync/40000/:type/web",
    SYNC_FILE_API:		           		    process.env.VUE_APP_BASE_API_URL + "/app/sync/40000/media/web",
    SYNC_ACCUSE_API:		           		process.env.VUE_APP_BASE_API_URL + "/app/accuse_reception/40000",
    SYNC_ACK:                               process.env.VUE_APP_BASE_API_URL + "/api/ack_",
    SYNC_SEND_EXPORT:						process.env.VUE_APP_BASE_API_URL + "/app/export_indexed_db",
    SYNC_MIGRATION:		           		    process.env.VUE_APP_BASE_API_URL + "/app/backup_db",

	//Indexation via API
	INDEX_DATA_API:							process.env.VUE_APP_BASE_API_URL + "/app/index_cache_data",


    // DPS
    DPS_CARNET_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/dps/:tiers_id/:season_id",
    DPS_IMPORT_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/dps/:tiers_id/:season_id/import/",
    DPS_PREPARE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/dps/:dps_id/prepare/:stallion_mare_ids",
    DPS_SAILLIE_INFO_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/dps/:season_id/saillie/:dps_id",
    DPS_ENVOIE_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/dps/:dps_id/envoie/",
    ACTE_SANITAIRE_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/sanitaire/:tiers_id/:season_id",
    ACTE_SANITAIRE_JUMENT_URL:              process.env.VUE_APP_BASE_API_URL + "/api/sanitaire/:tiers_id/:season_id/:seasonmare_id",
    ACTE_SANITAIRE_REST_URL:                process.env.VUE_APP_BASE_API_URL + "/api/sanitaire/:tiers_id/:season_id/:sms_id/reset/:maladie",
    CONTRACT_IMPORT_DN_AS_URL:              process.env.VUE_APP_BASE_API_URL + "/api/contract/:season_id/:stallion_num/import_dn_as",

    // PLANNING MONTE
    PLANNING_MONTE_BY_SEASON_AND_LIEU:      process.env.VUE_APP_BASE_API_URL + "/api/planning_monte/:type/:lieu_id/:season_id/:date",
    PLANNING_MONTE_BY_MARE:                 process.env.VUE_APP_BASE_API_URL + "/api/planning_monte/mare/:mare_id",
    PLANNING_MONTE_PRINT:                   process.env.VUE_APP_BASE_API_URL + "/api/planning_monte/:type/:lieu_id/:season_id/:date_start/:date_end/print",
    PLANNING_MONTE_PRINT_DAY:               process.env.VUE_APP_BASE_API_URL + "/api/planning_monte/:type/:lieu_id/:season_id/:date/print/",
    PLANNING_MONTE_ADD_SCHEDULE:            process.env.VUE_APP_BASE_API_URL + "/api/planning_monte/:type/",
    PLANNING_MONTE_EDIT_SCHEDULE:           process.env.VUE_APP_BASE_API_URL + "/api/planning_monte/:type/:schedulehorse_id",
    PLANNING_MONTE_COMMENTAIRE:             process.env.VUE_APP_BASE_API_URL + "/api/planning_monte/commentaire",
    PLANNING_MONTE_PRINT_FEUILLE:           process.env.VUE_APP_BASE_API_URL + "/api/planning_monte/:type/:lieu_id/:season_id/:date/feuille_saillie/print",
    PLANNING_SCHEDULE_STALLION_DATE:        process.env.VUE_APP_BASE_API_URL + "/api/planning_monte/schedule_taken/:stallion_id/:date",

    // CROISSANCE
    HORSE_CROISSANCE:                       process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/croissance",
    HORSE_CROISSANCE_BY_ID:                 process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/croissance/:croissance_id",
    HORSE_CROISSANCE_COMPARE:               process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/croissance/compare/:weeks",

    // TEMPERATURE
    HORSE_TEMPERATURE:                      process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/temperature",
    HORSE_TEMPERATURE_BY_ID:                process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/temperature/:temperature_id",
    HORSE_TEMPERATURE_ON_DURATION:          process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/temperature/:start/:end",

    // DEVIS
    DEVIS_TIERS_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/quotation",
    DEVIS_BASE_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/quotation/",
    DEVIS_BY_ID_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id",
    DEVIS_DETAILS_BY_QUOTATION_URL:         process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id/details",
    DEVIS_SHARE_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id/share",
    DEVIS_DETAILS_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/quotation/details",
    DEVIS_DETAILS_BY_ID_URL:                process.env.VUE_APP_BASE_API_URL + "/api/quotation/details/:details_id",
    DEVIS_DETAILS_ORDER_URL:                process.env.VUE_APP_BASE_API_URL + "/api/quotation/details/order",
    DEVIS_PDF_URL:                          process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id/pdf",
    DEVIS_CONVERT_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id/convert",
    DEVIS_SEND_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id/send/",
    DEVIS_DUPLICATE_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id/duplicate/:tiers_id",
    DEVIS_DOCUMENT_URL:                     process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id/document",
    DEVIS_DOCUMENT_BY_ID_URL:               process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id/document/:document_id",
    DEVIS_DOCUMENT_FILE_URL:                process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id/document/:document_id/file",
    DEVIS_SEND_SIGNATURE_URL:               process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id/signature",
    DEVIS_SEND_DOCUMENT_URL:                process.env.VUE_APP_BASE_API_URL + "/api/quotation/:quotation_id/send/document",

    EXPORT_DES_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/invoices/export_des/:month/:year/:entity_ids",
    EXPORT_DEB_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/invoices/export_deb/:month/:year/:entity_ids",
    GENERATE_EXPORT_DES_URL:                process.env.VUE_APP_BASE_API_URL + "/api/invoices/generate_export_des/:month/:year/:entity_ids",
    GENERATE_EXPORT_DEB_URL:                process.env.VUE_APP_BASE_API_URL + "/api/invoices/generate_export_deb/:month/:year/:entity_ids",

    //PAX (GFE)
    PAX_CRITERES:                           process.env.VUE_APP_BASE_API_URL + "/api/pax/criteres",
    PAX_CRITERES_BY_ID:                     process.env.VUE_APP_BASE_API_URL + "/api/pax/criteres/:criteres_id",
    PAX_CARACTERISATION_HORSE:              process.env.VUE_APP_BASE_API_URL + "/api/pax/horse/:horse_id/caracterisation",
    PAX_PAYS_DISPO_HORSE:                   process.env.VUE_APP_BASE_API_URL + "/api/pax/horse/:horse_id/pays",
    
    // RECHERCHE AVANCEE
    GET_MATCHED_RESULTS:                    process.env.VUE_APP_BASE_API_URL + "/api/search",
    GET_ADVANCED_SEARCH_HISTORY:            process.env.VUE_APP_BASE_API_URL + "/api/search_history",

    /* SHORTCUTS */
    GET_SHORTCUTS:                          process.env.VUE_APP_BASE_API_URL + "/api/shortcuts",
    SAVE_SHORTCUTS:                         process.env.VUE_APP_BASE_API_URL + "/api/shortcuts/save",


    // CARDS ONBOARDING
    GET_CARDS_URL:                          process.env.VUE_APP_BASE_API_URL + "/api/cards",
    SAVE_CARDS_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/cards/:cards_id",
    CHECK_CARDS_URL:                        process.env.VUE_APP_BASE_API_URL + "/api/cards/check",

    // DEPLACEMENT
    VEHICULE_BASE:                          process.env.VUE_APP_BASE_API_URL + "/api/vehicules",
    VEHICULE_BY_ID:                         process.env.VUE_APP_BASE_API_URL + "/api/vehicules/:vehicule_id",
    VEHICULE_ARCHIVE:                       process.env.VUE_APP_BASE_API_URL + "/api/vehicules/:vehicule_id/archive",
    DEPLACEMENT_BASE:                       process.env.VUE_APP_BASE_API_URL + "/api/deplacements",
    DEPLACEMENT_BY_ID:                      process.env.VUE_APP_BASE_API_URL + "/api/deplacements/:deplacement_id",
    DEPLACEMENT_PDF_BY_ID:                  process.env.VUE_APP_BASE_API_URL + "/api/deplacements/:deplacement_id/pdf",
    DEPLACEMENT_SUGGESTION:                 process.env.VUE_APP_BASE_API_URL + "/api/deplacements/suggestion/:date",

    // TRADUCTION
    ALL_TRANSLATIONS:                       process.env.VUE_APP_BASE_API_URL + "/api/translation",
    TRANSLATIONS_BY_CODE:                   process.env.VUE_APP_BASE_API_URL + "/api/translation/:code",

    WEB_CONTENT_TYPE:                       process.env.VUE_APP_BASE_API_URL + "/api/web_content_type",
    WEB_CONTENT_MODEL:                      process.env.VUE_APP_BASE_API_URL + "/api/web_content/:model_type/:model_id",

    // IMPORTDATA
    IMPORT_OHARAS_API:                       process.env.VUE_APP_BASE_API_URL + "/api/import/oharas",

    // INFOS USER
    USER_NAME: "",
    USER_LICENCE_KEY: "",
    USER_LANG_CODE: "",
    USER_ALREADY_LOGGED: false,
    USER_MENU_OPEN: false,
	USER_ACCESS_RIGHTS: '',
	USER_REGISTER_IAP_TRANSACTION:			process.env.VUE_APP_BASE_API_URL + "/api/transaction/register",

    // ADMIN
	RAPPRO_CONTRAT_FACTURE_ERREUR_URL:     process.env.VUE_APP_BASE_API_URL + "/api/rapprochement_contrat_facture_erreur",
	RAPPRO_CONTRAT_SANS_FACTURE_URL:       process.env.VUE_APP_BASE_API_URL + "/api/rapprochement_contrat_sans_facture",
	RAPPRO_FACTURE_SANS_CONTRAT_URL:       process.env.VUE_APP_BASE_API_URL + "/api/rapprochement_facture_sans_contrat",

    //MARKETPLACE
    MARKETPLACE_GET_TYPE_NOTAREA:           process.env.VUE_APP_BASE_API_URL + "/api/marketplace/type",

    //TRACKING EMAIL 
    EMAILS_TRACKING_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/email/list",
    EMAILS_TRACKING_ARCHIVE_URL:            process.env.VUE_APP_BASE_API_URL + "/api/email/:trackings_ids/archive",
    EMAILS_TRACKING_BY_ID_URL:              process.env.VUE_APP_BASE_API_URL + "/api/email/:type/:id",

    TASK_PLANNING_URL:                      process.env.VUE_APP_BASE_API_URL + "/api/task_planning",
    TASK_PLANNING_BY_ID_URL:                process.env.VUE_APP_BASE_API_URL + "/api/task_planning/:taskplanning_id",
    TASK_PLANNING_ARCHIVE_BY_ID_URL:        process.env.VUE_APP_BASE_API_URL + "/api/task_planning/:taskplanning_id/archive",
    TASK_URL:                               process.env.VUE_APP_BASE_API_URL + "/api/task",
    TASK_BY_ID_URL:                         process.env.VUE_APP_BASE_API_URL + "/api/task/:task_id",

    BRIDGE_URL:                             process.env.VUE_APP_BASE_API_URL + "/api/bridge",
    BRIDGE_ITEMS_URL:                       process.env.VUE_APP_BASE_API_URL + "/api/bridge/items",
    BRIDGE_ACCOUNTS_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/bridge/accounts",
    BRIDGE_ACCOUNTS_ACC_PLAN_URL:           process.env.VUE_APP_BASE_API_URL + "/api/bridge/accounts/accounting_plan",
    BRIDGE_ACCOUNTS_UPDATE_URL:             process.env.VUE_APP_BASE_API_URL + "/api/bridge/accounts/update",
    BRIDGE_ITEMS_ID_URL:                    process.env.VUE_APP_BASE_API_URL + "/api/bridge/items/:item_id",
    BRIDGE_ITEMS_REFRESH_URL:               process.env.VUE_APP_BASE_API_URL + "/api/bridge/items/:item_id/refresh",
    BRIDGE_TRANSACTIONS_URL:                process.env.VUE_APP_BASE_API_URL + "/api/bridge/transactions",
    BRIDGE_TRANSACTION_ID_SUGGESTION_URL:   process.env.VUE_APP_BASE_API_URL + "/api/bridge/transactions/:transaction_id/suggest",
    BRIDGE_TRANSACTION_ID_ASSIGNATION_URL:  process.env.VUE_APP_BASE_API_URL + "/api/bridge/transactions/:transaction_id/assign",
    BRIDGE_TRANSACTION_ID_SEARCH_URL:       process.env.VUE_APP_BASE_API_URL + "/api/bridge/transactions/:transaction_id/search",
    BRIDGE_TRANSACTION_SUGGESTION_URL:      process.env.VUE_APP_BASE_API_URL + "/api/bridge/transactions/suggest",

    CONGELATION_NOT_INVENTORIED_URL:        process.env.VUE_APP_BASE_API_URL + "/api/congelation/not_inventoried",
    CONGELATION_NOT_INVENTORIED_ID_URL:     process.env.VUE_APP_BASE_API_URL + "/api/congelation/not_inventoried/:lots_ids",
    CONGELATION_STOCK_LOTS_URL:             process.env.VUE_APP_BASE_API_URL + "/api/congelation/stock_lots",
    CONGELATION_PRESTOCKAGE_LOTS_URL:       process.env.VUE_APP_BASE_API_URL + "/api/congelation/prestockage_lots",
    CONGELATION_DEPLACEMENT_LOTS_URL:       process.env.VUE_APP_BASE_API_URL + "/api/congelation/deplacement_lots",
    CONGELATION_LOT_CONTROL_URL:            process.env.VUE_APP_BASE_API_URL + "/api/congelation/lots/:lot_id/controls",
    CONGELATION_CONTROL_SEMENCE_ID_URL:     process.env.VUE_APP_BASE_API_URL + "/api/congelation/controls/:control_id",
    CONGELATION_PROTOCOLES_URL:             process.env.VUE_APP_BASE_API_URL + "/api/protocoles",
    CONGELATION_PROTOCOLES_ORDER_URL:       process.env.VUE_APP_BASE_API_URL + "/api/protocoles/order",
    CONGELATION_PROTOCOLES_ID_URL:          process.env.VUE_APP_BASE_API_URL + "/api/protocoles/:protocole_id",
    CONGELATION_HORSE_CAMPAGNE_URL:         process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/campagnes",
    CONGELATION_HORSE_CAMPAGNE_ID_URL:      process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/campagnes/:campagne_id",
    CONGELATION_HORSE_CAMPAGNE_CLOSE_URL:   process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/campagnes/:campagne_id/close",
    CONGELATION_HORSE_CAMPAGNE_PRINT_URL:   process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/campagnes/:campagne_id/print",
    CONGELATION_LOT_ID_URL:                 process.env.VUE_APP_BASE_API_URL + "/api/lots/:lot_id",
    CONGELATION_LOT_LOCALISATIONS_URL:      process.env.VUE_APP_BASE_API_URL + "/api/lots/:lot_id/localisations",
    CONGELATION_LOT_EMPLACEMENTS_URL:       process.env.VUE_APP_BASE_API_URL + "/api/lots/:lot_id/emplacements",
    CONGELATION_LOT_DESTROY_URL:            process.env.VUE_APP_BASE_API_URL + "/api/lots/:lots_ids/destroy",
    CONGELATION_LOT_ASSIGN_URL:             process.env.VUE_APP_BASE_API_URL + "/api/lots/:lots_ids/assign",
    CONGELATION_HORSE_STOCKS_URL:           process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/stocks",
    CONGELATION_HORSE_STOCKS_TIERS_URL:     process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/stocks/tiers",
    CONGELATION_HORSE_STOCKS_NORMES_URL:    process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/stocks/normes",
    CONGELATION_EMPLACEMENT_STOCK_URL:      process.env.VUE_APP_BASE_API_URL + "/api/emplacements/:emplacement_id/inventaire",
    CONGELATION_EMPLACEMENT_DESTROY_URL:    process.env.VUE_APP_BASE_API_URL + "/api/emplacements/:emplacement_ids/destroy",
    CONGELATION_EMPLACEMENT_ASSIGN_URL:     process.env.VUE_APP_BASE_API_URL + "/api/emplacements/:emplacement_ids/assign",
    CONGELATION_EMPLACEMENT_FLUX_URL:       process.env.VUE_APP_BASE_API_URL + "/api/emplacements/:emplacement_id/flux",
    CONGELATION_EMPLACEMENT_SPLIT_URL:      process.env.VUE_APP_BASE_API_URL + "/api/emplacements/:emplacement_id/split",
    CONGELATION_HORSE_PLAN_CUVE_URL:        process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/plan_cuve",
    CONGELATION_HORSE_PLAN_CUVE_DETAIL_URL: process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/plan_cuve/:localisation_id",
    CONGELATION_HORSE_LOTS:                 process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/lots",
    CONGELATION_EXPEDITIONS_URL:            process.env.VUE_APP_BASE_API_URL + "/api/expeditions",
    CONGELATION_EXPEDITIONS_ID_URL:         process.env.VUE_APP_BASE_API_URL + "/api/expeditions/:expedition_id",
    CONGELATION_EXPEDITION_ARTICLES_URL:    process.env.VUE_APP_BASE_API_URL + "/api/expeditions/:expedition_id/articles",
    CONGELATION_EXPEDITIONS_ACTION_URL:     process.env.VUE_APP_BASE_API_URL + "/api/expeditions/:expeditions_ids/:action",
    CONGELATION_STALLION_PROPRIO_URL:       process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/lots/proprietaires",
    CONGELATION_LOTS_AVAILABLE_URL:         process.env.VUE_APP_BASE_API_URL + "/api/expeditions/lots_available",
    CONGELATION_EXPEDITIONS_REINTEGR_URL:   process.env.VUE_APP_BASE_API_URL + "/api/expeditions/reintegration/:localisation_id",
    CONGELATION_EXP_DOSE_REINTEGR_URL:      process.env.VUE_APP_BASE_API_URL + "/api/expeditions/:expeditions_ids/reintegration_dose",
    CONGELATION_EXPEDITIONS_DND_ENLEV_URL:  process.env.VUE_APP_BASE_API_URL + "/api/expeditions/demande_enlevement/:expeditions_ids",
    CONGELATION_EXPEDITIONS_PRINT_URL:      process.env.VUE_APP_BASE_API_URL + "/api/expeditions/:expeditions_ids/print/:type",
    CONGELATION_FLOORS_IDS_URL:             process.env.VUE_APP_BASE_API_URL + "/api/emplacements/floors/:floors_ids",
    CONGELATION_STAT_PRODUCTION_URL:        process.env.VUE_APP_BASE_API_URL + "/api/congelation/statistiques",
    CONGELATION_STAT_PRODUCTION_LOT_URL:    process.env.VUE_APP_BASE_API_URL + "/api/congelation/statistiques_lot",
    CONGELATION_TIERS_STOCKS_URL:           process.env.VUE_APP_BASE_API_URL + "/api/tiers/:tiers_id/stocks",
    CONGELATION_HORSE_CAMPAGNE_LOTS_URL:    process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/campagnes/lots",
    CONGELATION_CHECK_CAMPAGNE_URL:         process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/campagnes/check",
    CONGELATION_CUVE_SHIPPED_URL:           process.env.VUE_APP_BASE_API_URL + "/api/cuves/shipped",
    CONGELATION_CUVE_RETOUR_URL:            process.env.VUE_APP_BASE_API_URL + "/api/cuves/:cuves_ids/retour",
    CONGELATION_CUVE_RETOUR_PRINT_URL:      process.env.VUE_APP_BASE_API_URL + "/api/cuves/:cuve_id/retour/send",
    CONGELATION_STALLION_NOTE_URL:          process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/semence_note",
    CONGELATION_STALLION_NOTE_ID_URL:       process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/semence_note/:note_id",
    CONGELATION_SORTIE_STOCK_PRINT_URL:     process.env.VUE_APP_BASE_API_URL + "/api/expeditions/sortie_stocks_print",
    CONGELATION_STALLION_DOSE_URL:          process.env.VUE_APP_BASE_API_URL + "/api/horse/:horse_id/doses",
    CONGELATION_DOSE_URL:                   process.env.VUE_APP_BASE_API_URL + "/api/congelation/doses",

    IFCE_DEPOT:                             process.env.VUE_APP_BASE_API_URL + "/api/ifce_depot",


	/* WEB SUPPORT */
	APP_BUILD_NUMBER:						'0',
	IS_IOS_RUNNING: 						process.env.VUE_APP_IOS_RUNNING == "true",
	IS_AND_RUNNING:							false
}
