import Vue from 'vue'
import Constants from 'Constants'
import RootUserMixin from 'GroomyRoot/mixins/User.js'

export default Vue.util.mergeOptions(RootUserMixin, {
    methods: {
        checkLogin: async function(email, password, salon = false) {
            this.has_multi_licences = false
            return this.$request.request_post_api("UserMixin::checkLogin", Constants.USER_LOGIN, {
                email: email,
                password: password,
                salon: salon
            }, false, { sync:false })
            .then(async response => {
                if(response.code_retour === 0) {
                    const data = response.retour

					await this.persistUserSession(data.token, data.licence_key, data.licence_username, data.user_id, data.valid_user, data.valid_mail, data.lang)
					await this.persistUserLicence(data)

                    if(data.all_licences.length > 1) {
                        await this.persistUserLicences(data.all_licences)
                        this.has_multi_licences = true
                    }
                }
            })
        },

        async loginUser(email, password, redirect='/cards', salon = false) {
            try {
                const res = await this.checkLogin(email, password, salon)
                // On regarde si le user a plusieurs licences
                if(this.has_multi_licences) {
					const query = redirect ? { redirect: redirect } : null
                    this.$router.push({ name: 'switchLicence', query: query })
                    return
                }

                // On charge la config en cache (localstorge), et on fait la redirection
                this.redirectPostLogin(redirect)
            } catch (err) {
                if(err.response.status == 401) {
                    throw new Error(this.getTrad('compte.bad_identifiants'))
                } else {
                    this.$request.parseError(err)
                }
            }
		},

		autologCaTier: async function(tier_email) {
            const url = Constants.USER_AUTOLOG_TIER_CA_URL + "?licence_key=" + Constants.USER_LICENCE_KEY

            return this.$request.request_post_api("UserMixin::autologCaTier", url, { tier_email }, false)
				.then(res => res.retour)
        },

		autocompleteCaTierRegister: async function(tier_email, signature) {
			const url = Constants.USER_AUTOCOMPLETE_TIER_REGISTER_CA_URL

            return this.$request.request_post_api("UserMixin::autocompleteCaTierRegister", url, { tier_email, signature }, false)
				.then(res => res.retour)
		},

		redirectUrlAutologCa(redirect_domain, tier_email, signature) {
			return `http://${redirect_domain}/#/?mail=${tier_email}&als=${signature}`
		},

        persistUserSession: async function(bearer, licence, licence_name, user_id, valid_user, valid_mail, lang) {
            await this.setConfig("licence_key", licence)
            await this.setConfig("licence_name", licence_name)
            await this.setConfig("token", bearer)
            await this.setConfig("user_id", user_id)
			await this.setConfig("valid_user", valid_user)
            await this.setConfig("lang", lang || 'fr')

			await this.persistUserMailValid(valid_mail)
            this.request.set_headers(bearer)
		},

		persistUserMailValid: async function(mail_valid=false) {
			await this.setConfig("valid_mail", mail_valid)
		},

		persistUserLicence: async function(data) {
			const to_store = {	
				licence_key: data.licence_key,
				licence_username: data.licence_username,
				licence_date_of_validity: data.licence_date_of_validity
			}

			await this.setConfig("main_licence", to_store)
		},

		// Les licences mises en local storage ne seront utilisées que lors du login
        persistUserLicences: async function(licences) {
            await this.setConfig("licences", licences)
        },

        persistUserLicenceKey: async function(licence_key) {
            Constants.USER_LICENCE_KEY = licence_key
            await this.setConfig("licence_key", licence_key)
        },
		
		persistUserLicenceName: async function(licence_name) {
			Constants.USER_NAME = licence_name
            await this.setConfig("licence_name", licence_name)
		},

        persistUserConfig: async function() {
            await this.loadConfig()

			await this.initUserConfig()
			return true
		},

        // Permet de delog le user, en supprimant la section "configuration.actual_user" de la conf de l'app
        delogUser: async function(keep_config=false) {
			const shouldKeepDatabase = await this.$bvModal.msgBoxConfirm(
				this.$t('toast.logout_delete_db'),
				{
					noCloseOnBackdrop: true,
					noCloseOnEsc: true,
					centered: true,
					okTitle: Vue.i18n.translate('global.oui'),
					cancelTitle: Vue.i18n.translate('global.non'),
				}
			)

            return await this.$request.request_get_api("UserMixin::delogUser", Constants.USER_LOGOUT)
                .then(async res => {
                    this.deleteConfig(keep_config, shouldKeepDatabase)
                })
                .catch(e => {
                    this.deleteConfig(keep_config, shouldKeepDatabase)
                    console.error("UserMixin::delogUser => ERROR", e)
                })
        },

        deleteConfig: async function(keep_config, shouldKeepDatabase) {
            try {
                if (shouldKeepDatabase) {
                    await this.$storage.unloadDatabase()
                }
                else {
                    await this.$storage.removeDatabase(this)
                }
            }
            catch(err) {
                console.error(err)
            }

            try {
                await this.$firebase.messaging.reset()
            }
            finally {
                if(!keep_config) {
                    await this.rmConfig()
                    this.rmConfigCache()
                    this.request.reset_header()
        
                    this.$router.user_logged = false

                    // On ne redirige que si le delog ne vient pas du switch licence
                    if(this.$route.name != "Home") {
                        this.$router.push("/")
                    }
                }
            }
        },

        changeDb: async function() {
            const shouldKeepDatabase = await this.$bvModal.msgBoxConfirm(
                this.$t('toast.logout_delete_db'),
                {
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    centered: true,
                    okTitle: Vue.i18n.translate('global.oui'),
                    cancelTitle: Vue.i18n.translate('global.non'),
                }
            )

            try {
                if (shouldKeepDatabase) {
                    await this.$storage.unloadDatabase()
                }
                else {
                    await this.$storage.removeDatabase(this)
                }
            }
            catch(err) {
                console.error(err)
            }
        },

        // Récupération des paramètres user dans local storage
        initUserConfig: async function() {
            // On récupère le paramètre de resize du menu header
			let user_params = this.getConfig('user')

            if(!user_params) {
                user_params = {}
                user_params.menu_open = true

                await this.setConfig('user', user_params)
            }
			const menu_open = user_params.menu_open
			if (menu_open) {
				this.$store.commit('nav/open')
			}
			else {
				this.$store.commit('nav/close')
			}
		},

        /* Gestion des numéros étalonniers */
        async getUserEntities(email) {
            const url = this.constructRoute(Constants.USER_ENTITY_GET_STALLION_ID, { email })
                +"?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_get_api("UserMixin::getUserEntities", url)
                .then(res => res.retour)
                .catch(e => console.error("UserMixin::getUserEntities => ERROR", e))
        },

        async getLicenceEntities(licence_key) {
            const url = this.constructRoute(Constants.LICENCE_ENTITY_GET_STALLION_ID, { licence_key })
                +"?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_get_api("UserMixin::getLicenceEntities", url)
                .then(res => res.retour)
                .catch(e => console.error("UserMixin::getLicenceEntities => ERROR", e))
        },

        async setEntityStallionId(licence_key, entities) {
            const url = this.constructRoute(Constants.LICENCE_ENTITY_GET_STALLION_ID, { licence_key })
                +"?licence_key="+Constants.USER_LICENCE_KEY

            return this.$request.request_post_api("UserMixin::setEntityStallionId", url, { entities }, false)
                .then(res => res.retour)
                .catch(e => console.error("UserMixin::setEntityStallionId => ERROR", e))
        },

        async getIFCEDepot() {
            const url = Constants.IFCE_DEPOT
                +"?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_get_api("UserMixin::getIFCEDepot", url)
                .then(res => res.retour)
                .catch(e => console.error("UserMixin::getIFCEDepot => ERROR", e))
            },

		/* Config de l'espace client de la licence donnée */
		getCaConfig: async function() {
			const url = this.constructRoute(Constants.LICENCES_CA_CONFIG_URL, {
                target_licence: ''
			})+"?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("UserMixin::getCaConfig", url)
				.then(res => res.retour)
		},

		getUserCaConfig: async function(userLicenceKey) {
			const url = this.constructRoute(Constants.LICENCES_CA_CONFIG_URL, {
                target_licence: userLicenceKey
			})+"?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("UserMixin::getUserCaConfig", url)
				.then(res => res.retour)
		},

		setUserCaConfig: async function(userLicenceKey, type, module, values) {
			const url = this.constructRoute(Constants.LICENCES_CA_CONFIG_URL, {
                target_licence: userLicenceKey
			})+"?licence_key="+Constants.USER_LICENCE_KEY

			const params = { type, module, values }
            return this.$request.request_post_api("UserMixin::setUserCaConfig", url, params, false)
		},

		getAllUsers: async function() {
			const url = Constants.USER_GET_ALL + "?licence_key=" + Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("HorseMixin::getAllUsers", url)
            .catch(error => {
                console.error("HorseMixin::getAllUsers => ERROR", error)
                return null
            })

            if(result.retour) return result.retour
            return null
		},

		getUserByTierMail: async function(mail) {
			const url = Constants.USER_GET_BY_TIER_MAIL + "?licence_key=" + Constants.USER_LICENCE_KEY + "&mail="+mail
            const result = await this.$request.request_get_api("HorseMixin::getUserByTierMail", url)
            .catch(error => {
                console.error("HorseMixin::getUserByTierMail => ERROR", error)
                return null
            })

            if(result.retour) return result.retour
            return null
		},

        // Récupérer l'id de la gamme de l'user connecté (fonction non admin)
        getCurrentUserGamme: async function() {
            const url = Constants.USERS_GET_CURRENT_GAMME_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("UserMixin::getCurrentUserGamme", url)
            .catch(error => {
                console.log("UserMixin::getCurrentUserGamme => ERROR", error)
                return null
            })
            if(result.code_retour == 0) return result.retour
            return
        },

		getRapprochementContratFactureErreur: async function(licence, annee) {
            const url = Constants.RAPPRO_CONTRAT_FACTURE_ERREUR_URL+"?licence_key="+Constants.USER_LICENCE_KEY+"&licence="+licence+"&annee="+annee
            return await this.$request.request_get_api("UserMixin::getRapprochementContratFactureErreur", url)
        },

        saveRapprochementContratFactureErreur: async function(invoice, type) {
            const url = Constants.RAPPRO_CONTRAT_FACTURE_ERREUR_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            const params = {"invoice":invoice, "type":type}
            return await this.$request.request_post_api("UserMixin::saveRapprochementContratFactureErreur", url, params, false)
        },

        getRapprochementContratSansFacture: async function(licence, annee) {
            const url = Constants.RAPPRO_CONTRAT_SANS_FACTURE_URL+"?licence_key="+Constants.USER_LICENCE_KEY+"&licence="+licence+"&annee="+annee
            return await this.$request.request_get_api("UserMixin::getRapprochementContratSansFacture", url)
        },

        saveRapprochementContratSansFacture: async function(contrat, invoice) {
            const url = Constants.RAPPRO_CONTRAT_SANS_FACTURE_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            const params = {"contrat":contrat, "invoice":invoice}
            return await this.$request.request_post_api("UserMixin::saveRapprochementContratSansFacture", url, params, false)
        },
        getRapprochementFactureSansContrat: async function(licence, annee) {
            const url = Constants.RAPPRO_FACTURE_SANS_CONTRAT_URL+"?licence_key="+Constants.USER_LICENCE_KEY+"&licence="+licence+"&annee="+annee
            return await this.$request.request_get_api("UserMixin::getRapprochementFactureSansContrat", url)
        },

        saveRapprochementFactureSansContrat: async function(invoice, contrat) {
            const url = Constants.RAPPRO_FACTURE_SANS_CONTRAT_URL+"?licence_key="+Constants.USER_LICENCE_KEY
            const params = {"invoice":invoice, "contrat":contrat}
            return await this.$request.request_post_api("UserMixin::saveRapprochementFactureSansContrat", url, params, false)
        },

        getAllLicence: async function() {
            const url = Constants.ALL_LICENCES_URL + "?licence_key="+  Constants.USER_LICENCE_KEY
            
            const result = await this.$request.request_get_api("HorseMixin::getAllLicence", url)
            .catch(error => {
                console.error("HorseMixin::getAllLicence => ERROR", error)
                return null
            })

            if(result.retour) return result.retour
            return null
        },

        async getAllGroupsNames() {
			const url = Constants.USER_GET_GROUPS_NAMES+"?licence_key="+Constants.USER_LICENCE_KEY

			let result = await this.$request.request_get_api("UserMixin::getAllGroupsNames", url)
                .catch(error => {
                    console.error("UserMixin::getAllGroupsNames => ERROR", error)
                    return null
                })

            if(result.code_retour == 0) return result.retour
            return []
        },

		getGocardlessRapprochement: async function(gocardlessrapprochement_token) {
            const url = Constants.USER_GOCARDLESS_RAPPROCHEMENT+"?gocardlessrapprochement_token="+gocardlessrapprochement_token
			const result = await this.$request.request_get_api("UserMixin::loadGocardlessRapprochement", url)
			.catch(error => {
				console.error("UserMixin::loadGocardlessRapprochement => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		},

        saveGocardlessRapprochement: async function(email, gocardlessrapprochement_token) {
            const url = Constants.USER_GOCARDLESS_RAPPROCHEMENT
            const params = {"email":email, "gocardlessrapprochement_token":gocardlessrapprochement_token}
            return await this.$request.request_post_api("UserMixin::saveGocardlessRapprochement", url, params, false, {withDetails: true})
		},

        getAllApplications: async function() {
            const url = Constants.ALL_APPLICATIONS_URL + "?licence_key="+  Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("UserMixin::getAllApplications", url)
            .catch(error => {
                console.error("UserMixin::getAllApplications => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        getLicenceApplications: async function() {
            const url = Constants.LICENCE_APPLICATIONS_URL + "?licence_key="+  Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("UserMixin::getLicenceApplications", url)
            .catch(error => {
                console.error("UserMixin::getLicenceApplications => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        saveLicenceApplication: async function(applications) {
            const url = Constants.LICENCE_APPLICATIONS_URL + "?licence_key=" + Constants.USER_LICENCE_KEY

            return this.$request.request_post_api("UserMixin::saveLicenceApplication", url, {applications}, false)
                .then(res => res.retour)
        },

        async saveLicenceFree(licence_id, free) {
            const url = this.constructRoute(Constants.LICENCE_FREE_URL, {licence_id})+"?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("UserMixin::saveLicenceFree", url, {free}, false)
        },

        async saveLicenceDateOfValidity(licence_id, date) {
            const url = this.constructRoute(Constants.LICENCE_VALIDITY_URL, {licence_id})+"?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("UserMixin::saveLicenceDateOfValidity", url, {date}, false)
        },

        async saveLicenceName(licence_id, name) {
            const url = this.constructRoute(Constants.LICENCE_NAME_URL, {licence_id})+"?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("UserMixin::saveLicenceName", url, {name}, false)
        },

        getAllConfig: async function() {
            const url = Constants.ALL_CONFIG_URL + "?licence_key="+  Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("UserMixin::getAllConfig", url)
            .catch(error => {
                console.error("UserMixin::getAllConfig => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        getLicenceConfig: async function() {
            const url = Constants.LICENCE_CONFIG_URL + "?licence_key="+  Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("UserMixin::getLicenceConfig", url)
            .catch(error => {
                console.error("UserMixin::getLicenceConfig => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        saveLicenceConfig: async function(config) {
            const url = Constants.LICENCE_CONFIG_URL + "?licence_key=" + Constants.USER_LICENCE_KEY

            return this.$request.request_post_api("UserMixin::saveLicenceConfig", url, {config}, false)
                .then(res => res.retour)
        },

         async submitAutoLog(email, token) {
            return this.$request.request_post_api("UserMixin::submitAutoLog", Constants.USER_AUTOLOGIN, {
                email: email,
                signature: token
            }, false, { sync:false })
            .then(async response => {
                if(response.code_retour === 0) {
                    const data = response.retour
                    await this.persistUserSession(data.token, data.licence_key, data.licence_username, data.user_id, data.valid_user, data.valid_mail, data.lang)
                    await this.persistUserLicence(data)
                }
            })
        },

        async generateToken(type = '') {
            const url = this.constructRoute(Constants.LICENCE_GENERATE_TOKEN, {type})+ "?licence_key="+  Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("UserMixin::generateToken", url)
            .catch(error => {
                console.error("UserMixin::generateToken => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        async getLicenceTokenAPI() {
            const url = Constants.LICENCE_TOKEN_API + "?licence_key="+  Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("UserMixin::getLicenceTokenAPI", url)
            .catch(error => {
                console.error("UserMixin::getLicenceTokenAPI => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        async saveLicenceTokenAPI(tokens) {
            const url = Constants.LICENCE_TOKEN_API + "?licence_key="+  Constants.USER_LICENCE_KEY
            return this.$request.request_post_api("UserMixin::saveLicenceTokenAPI", url, {tokens}, false)
                .then(res => res.retour)
        },
        
        async getUserLicence() {
            const url = Constants.USERS_LICENCE_URL + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_get_api("UserMixin::getUserLicence", url)
                .then(res => res.retour)
        }
    }
})
