<template>
    <div :class="classes" style="white-space:nowrap;">
        <span v-if="mail && value"> 
            <label v-if="label">{{ label }} :{{'\xa0'}}</label><a :href="'mailto:'+value">{{ value }}</a>  <a href="#"><font-awesome-icon v-if="value && value != ''" class="copy" :icon="['far', 'copy']" @click="copyValue" :id="'toolstring_' + id + 'mail'"/></a>
            <b-tooltip :id="'toolstring_' + id" v-if="popupIsToggled" placement="top" variant="primary" :show="popupIsToggled" :target="'toolstring_' + id + 'mail'">
                <a href="#" style="color:white;">{{ $t('global.copie') }}</a>
            </b-tooltip>
        </span>
        <span v-else-if="phone && value && customtable"> 
            <label v-if="label">{{ label }} :{{'\xa0'}}</label><a :href="'tel:'+value">{{ value }}</a>  <a href="#"><font-awesome-icon v-if="value && value != ''" class="copy icon_table" :icon="['far', 'copy']" @click="copyValue" :id="'toolstring_' + id + 'tel'"/></a>
            <b-tooltip :id="'toolstring_' + id" v-if="popupIsToggled" placement="top" variant="primary" :show="popupIsToggled" :target="'toolstring_' + id + 'tel'">
                <a href="#" style="color:white;">{{ $t('global.copie') }}</a>
            </b-tooltip>
        </span>
        <span v-else-if="phone && value"> 
            <label v-if="label">{{ label }} :{{'\xa0'}}</label><a :href="'tel:'+value">{{ value }}</a>  <a href="#"><font-awesome-icon v-if="value && value != ''" class="copy" :icon="['far', 'copy']" @click="copyValue" :id="'toolstring_' + id + 'tel'"/></a>
            <b-tooltip :id="'toolstring_' + id" v-if="popupIsToggled" placement="top" variant="primary" :show="popupIsToggled" :target="'toolstring_' + id + 'tel'">
                <a href="#" style="color:white;">{{ $t('global.copie') }}</a>
            </b-tooltip>
        </span>
        <span v-else-if="!boxed && value"> 
            <label v-if="label && label != ''">{{ label }} :{{'\xa0'}}</label><span>{{ value }}</span>  <a href="#"><font-awesome-icon v-if="value && value != ''" class="copy" :icon="['far', 'copy']" @click="copyValue" :id="'toolstring_' + id"/></a>
            <b-tooltip :id="'toolstring_' + id" v-if="popupIsToggled" placement="top" variant="primary" :show="popupIsToggled" :target="'toolstring_' + id">
                <a href="#" style="color:white;">{{ $t('global.copie') }}</a>
            </b-tooltip>
        </span>
        <div v-else-if="boxed" class="input-group">
            <div v-if="label && label != ''" class="input-group-prepend">
                <span class="input-group-text">{{ label }}</span>
            </div>
            <input type="text" :placeholder="placeholder" class="form-control" :readonly="readonly" @keyup="setValue()" v-model="newValue">
            <div class="input-group-append">
                <button class="btn btn-secondary" type="button" @click="copyValue"><font-awesome-icon v-if="value && value != ''" :icon="['far', 'copy']" :id="'toolstring_' + id"/></button>
                <b-tooltip v-if="popupIsToggled" placement="top" variant="primary" :show="popupIsToggled" :target="'toolstring_' + id">
                    <a href="#" style="color:white;">{{ $t('global.copie') }}</a>
                </b-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'e-copybox',
    props:{
        value: {
            type: String,
            default: ''
        },
        removespace: {
            type: Boolean,
            default: false
        },
        boxed: {
            type: Boolean,
            default: false
        },
        mail: {
            type: Boolean,
            default: false
        },
        phone: {
            type: Boolean,
            default: false
        },
        customtable: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        classes: {
            type: String,
            default: ''
        },
        readonly: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            popupIsToggled : false,
            id: null,
            newValue: null
        }
    },
    mounted () {
        this.id = this._uid
        this.newValue = this.value
    },
    methods:{
        copyValue() {
            if (this.removespace) {
                navigator.clipboard.writeText(this.value.replace(/ /g, ''))
            } else {
                navigator.clipboard.writeText(this.value)
            }
            this.togglePopup()
        },
        async togglePopup(){
            if (this.popupIsToggled == false) {
                this.popupIsToggled = true
                await new Promise(resolve => setTimeout(resolve, 1000))
                this.popupIsToggled = false
            }
        },
        setValue() {
            return this.$emit('update:value', this.newValue)
        },
    },
    watch: {
        value(val) {
                this.newValue = this.value
        },
    }
}
</script>

<style scoped>
    .copy {
        cursor: copy;
    }
    .icon_table {
        display: inline-block;
        margin-right: 15px;
    }
</style>