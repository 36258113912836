import Vue from 'vue'
import Transformer from './Transformer'
import PhoneTransformer from './PhoneTransformer'

import Common from '@/assets/js/common'
import Countries from '@/assets/lang/countries/countries'
import TiersHorseTransformer from './TiersHorseTransformer'
import DuedateConfigTransformer from './DuedateConfigTransformer'
import TiersClientAccountTransformer from './TiersClientAccountTransformer'

export class TierTransformer extends Transformer {

	table = 'tiers'
	transactionTables = ['phone']

	async stringified(tiers) {
		const stringified = await super.stringified(tiers)
		const phone = await this.fetchAdditionalColumn(tiers, 'phone')
		
		return `${stringified}|${Common.getArrayObjProperty(phone, 'phone_combine').join('|')}`
	}

	// ATTENTION Colonne indéxée : Vérifier sur groomy_api (model/Contract.php::avenant()) si update
    transformLight(tier) {
        return {
			'tiers_id': tier.tiers_id,
			'tiers_rs': tier.tiers_rs,
			'tiers_lastname': tier.tiers_lastname,
			'tiers_firstname': tier.tiers_firstname,
			'tiers_civility': tier.tiers_civility,
			'tiers_mail': tier.tiers_mail,
			'tiers_address1': tier.tiers_address1,
			'tiers_address2': tier.tiers_address2,
			'tiers_postalcode': tier.tiers_postalcode,
			'tiers_town': tier.tiers_town,
			'tiers_country': tier.tiers_country,
			'tiers_website': tier.tiers_website,
			'tiers_moral': tier.tiers_moral,
			'tiers_entity': tier.tiers_entity,
			'tiers_valide': tier.tiers_valide,
			'tiers_duedateconfig': tier.tiers_duedateconfig,
			'tiers_siret': tier.tiers_siret,
			'tiers_siren': tier.tiers_siren,
			'tiers_vat_number': tier.tiers_vat_number,
			'tiers_currency': tier.tiers_currency,
			'tiers_stallion_number': tier.tiers_stallion_number,
			'tiers_formatted':tier.tiers_rs + ' ' + (tier.tiers_alias ?'(' + tier.tiers_alias + ')' : '') + ' - ' + tier.tiers_postalcode + ' ' + tier.tiers_town,
			'tiers_paymentmethod': tier.tiers_paymentmethod,
			'tiers_hubspotid': tier.tiers_hubspotid
        }
	}

	fetchWithAlias(col) {
		this.additionalColumns({
			'phone_combine': this.phone_combine,
		})
		return col
	}

    transformLightWithAlias(tier) {
        return {
			'tiers_id': tier.tiers_id,
			'tiers_rs': tier.tiers_rs,
			'tiers_lastname': tier.tiers_lastname,
			'tiers_firstname': tier.tiers_firstname,
			'tiers_civility': tier.tiers_civility,
			'tiers_mail': tier.tiers_mail,
			'tiers_address1': tier.tiers_address1,
			'tiers_address2': tier.tiers_address2,
			'tiers_postalcode': tier.tiers_postalcode,
			'tiers_town': tier.tiers_town,
			'tiers_country': tier.tiers_country,
			'tiers_website': tier.tiers_website,
			'tiers_moral': tier.tiers_moral,
			'tiers_entity': tier.tiers_entity,
			'tiers_valide': tier.tiers_valide,
			'tiers_duedateconfig': tier.tiers_duedateconfig,
			'tiers_siret': tier.tiers_siret,
			'tiers_siren': tier.tiers_siren,
			'tiers_vat_number': tier.tiers_vat_number,
			'tiers_currency': tier.tiers_currency,
			'tiers_stallion_number': tier.tiers_stallion_number,
			'tiers_formatted':tier.tiers_rs + ' ' + (tier.tiers_alias ?'(' + tier.tiers_alias + ')' : '') + ' - ' + tier.tiers_postalcode + ' ' + tier.tiers_town,
			'tiers_paymentmethod': tier.tiers_paymentmethod,
			'tiers_hubspotid': tier.tiers_hubspotid,
			'tiers_alias': tier.tiers_alias,
			'phone_combine': tier.phone_combine
        }
	}

	fetchLightWithTags(col) {
		this.additionalColumns({
			'tiers_tags': this.tiers_tags
		})
		return col
	}

	transformLightWithTags(tier) {
        return {
			'tiers_id': tier.tiers_id,
			'tiers_rs': tier.tiers_rs,
			'tiers_lastname': tier.tiers_lastname,
			'tiers_firstname': tier.tiers_firstname,
			'tiers_civility': tier.tiers_civility,
			'tiers_mail': tier.tiers_mail,
			'tiers_address1': tier.tiers_address1,
			'tiers_address2': tier.tiers_address2,
			'tiers_postalcode': tier.tiers_postalcode,
			'tiers_town': tier.tiers_town,
			'tiers_country': tier.tiers_country,
			'tiers_website': tier.tiers_website,
			'tiers_moral': tier.tiers_moral,
			'tiers_entity': tier.tiers_entity,
			'tiers_valide': tier.tiers_valide,
			'tiers_duedateconfig': tier.tiers_duedateconfig,
			'tiers_siret': tier.tiers_siret,
			'tiers_siren': tier.tiers_siren,
			'tiers_vat_number': tier.tiers_vat_number,
			'tiers_currency': tier.tiers_currency,
			'tiers_stallion_number': tier.tiers_stallion_number,
			'tiers_formatted':tier.tiers_rs + ' ' + (tier.tiers_alias ?'(' + tier.tiers_alias + ')' : '') + ' - ' + tier.tiers_postalcode + ' ' + tier.tiers_town,
			'tiers_paymentmethod': tier.tiers_paymentmethod,
			'tiers_hubspotid': tier.tiers_hubspotid,
			'tiers_tags': tier.tiers_tags
        }
	}

	phone(item) {
		return this.fetchRelationship(item, 'phone')
		.then(phones => {
			return PhoneTransformer.process(phones)
		})
	}

	phone_combine(item) {
		return this.fetchRelationship(item, 'phone')
		.then(phones => {
			return phones.map(phone => phone.phone_indicatif+phone.phone_numero)
		})
	}

	media(item) {
		return this.fetchRelationship(item, 'tiers_media')
		.then(medias => {
			return medias.length > 0 ? medias[0].tiersmedia_filename : null
		})
	}

	fetchWithPhone(col) {
		this.additionalColumns({
			'phone_combine': this.phone_combine,
			'phone': this.phone,
			'media': this.media
		})
		return col
	}

	transformWithPhone(tier) {
		return {
			'phones': tier.phone,
			'tiers_id': tier.tiers_id,
			'tiers_rs': tier.tiers_rs,
			'tiers_lastname': tier.tiers_lastname,
			'tiers_firstname': tier.tiers_firstname,
			'tiers_civility': tier.tiers_civility,
			'tiers_mail': tier.tiers_mail,
			'tiers_address1': tier.tiers_address1,
			'tiers_address2': tier.tiers_address2,
			'tiers_postalcode': tier.tiers_postalcode,
			'tiers_town': tier.tiers_town,
			'tiers_country': tier.tiers_country,
			'tiers_website': tier.tiers_website,
			'tiers_moral': tier.tiers_moral,
			'tiers_entity': tier.tiers_entity,
			'tiers_valide': tier.tiers_valide,
			'tiers_archive': tier.tiers_archive,
			'tiers_duedateconfig': tier.tiers_duedateconfig,
			'tiers_siret': tier.tiers_siret,
			'tiers_siren': tier.tiers_siren,
			'tiers_vat_number': tier.tiers_vat_number,
			'tiers_vatcheck': tier.tiers_vatcheck,
			'tiers_currency': tier.tiers_currency,
			'tiers_lang': tier.tiers_lang,
			'href': 'tiersFiche',
			'params': { tiers_id: tier.tiers_id },
			'phones_number': tier.phone_combine.join("<br>"),
			'pays': Countries[Vue.i18n.locale()].label[tier.tiers_country],
			'tiers_alias': tier.tiers_alias,
			'tiers_description': tier.tiers_description,
			'tiers_paymentmethod': tier.tiers_paymentmethod,
			'tiers_longitude': tier.tiers_longitude,
			'tiers_latitude': tier.tiers_latitude,
			'media_filename': tier.media,
			'tiers_region': tier.tiers_region,
			'tiers_statut_rcs': tier.tiers_statut_rcs,
			'tiers_statut_rne': tier.tiers_statut_rne
        }
	}

	tiers_horse(item) {
		return this.fetchRelationship(item, 'tiers_horse')
		.then(tiers_horses => {
			return TiersHorseTransformer.process(tiers_horses, 'WithTiersHorsePartAndHorse')
		})
	}

	fetchWithTiersHorse(col) {
		this.additionalColumns({
			'tiers_horse': this.tiers_horse,
			'phone_combine': this.phone_combine,
			'phone': this.phone
		})

		return col
	}

	transformWithTiersHorse(tier) {
		return {
			'tiers_horse': tier.tiers_horse,
			'tiers_id': tier.tiers_id,
			'tiers_rs': tier.tiers_rs,
			'tiers_lastname': tier.tiers_lastname,
			'tiers_firstname': tier.tiers_firstname,
			'tiers_civility': tier.tiers_civility,
			'tiers_mail': tier.tiers_mail,
			'tiers_address1': tier.tiers_address1,
			'tiers_address2': tier.tiers_address2,
			'tiers_postalcode': tier.tiers_postalcode,
			'tiers_town': tier.tiers_town,
			'tiers_country': tier.tiers_country,
			'tiers_website': tier.tiers_website,
			'tiers_moral': tier.tiers_moral,
			'tiers_entity': tier.tiers_entity,
			'tiers_valide': tier.tiers_valide,
			'tiers_duedateconfig': tier.tiers_duedateconfig,
			'tiers_siret': tier.tiers_siret,
			'tiers_vat_number': tier.tiers_vat_number,
			'tiers_currency': tier.tiers_currency,
			'href': 'tiersFiche',
			'params': { tiers_id: tier.tiers_id },
			'phones_number': tier.phone_combine.join("<br>"),
			'pays': Countries[Vue.i18n.locale()].label[tier.tiers_country]
        }
	}

	tiers_horse_current_part(item) {
		return this.db().t('tiers_horse')
		.then(table => {
            return table.where({
				tiershorse_tiers: item.tiers_id,
				tiershorse_valide: 1
			})
			.and(tiershorse => tiershorse.tiershorse_fonction == 'PROPRIO')
		})
		.then(col => {
			return col.transform(new TiersHorseTransformer('withTiersHorseCurrentPartAndHorse'))
		})
	}

	fetchWithTiersHorseCurrentPart(col) {
		this.additionalColumns({
			'tiers_horse_current_part': this.tiers_horse_current_part,
			'phone_combine': this.phone_combine,
			'phone': this.phone
		})

		return col
	}

	transformWithTiersHorseCurrentPart(tier) {
		return {
			'tiers_horse': tier.tiers_horse_current_part || [],
			'tiers_id': tier.tiers_id,
			'tiers_rs': tier.tiers_rs,
			'tiers_lastname': tier.tiers_lastname,
			'tiers_firstname': tier.tiers_firstname,
			'tiers_civility': tier.tiers_civility,
			'tiers_mail': tier.tiers_mail,
			'tiers_address1': tier.tiers_address1,
			'tiers_address2': tier.tiers_address2,
			'tiers_postalcode': tier.tiers_postalcode,
			'tiers_town': tier.tiers_town,
			'tiers_country': tier.tiers_country,
			'tiers_website': tier.tiers_website,
			'tiers_moral': tier.tiers_moral,
			'tiers_entity': tier.tiers_entity,
			'tiers_valide': tier.tiers_valide,
			'tiers_duedateconfig': tier.tiers_duedateconfig,
			'tiers_siret': tier.tiers_siret,
			'tiers_vat_number': tier.tiers_vat_number,
			'tiers_currency': tier.tiers_currency,
			'href': 'tiersFiche',
			'params': { tiers_id: tier.tiers_id },
			'phones_number': tier.phone_combine.join("<br>"),
			'pays': Countries[Vue.i18n.locale()].label[tier.tiers_country]
        }
	}

	fetchWithDuedateConfig(col){
		return col.with({
			'tiers_duedateconfig': 'duedate_config'
		})
	}

	async transformWithDuedateConfig(tier) {
		const duedate_config = await DuedateConfigTransformer.process(tier.duedateconfig)
		return {
			'tiers_duedateconfig': duedate_config
		}
	}

	async client_account(item) {
		return this.fetchRelationship(item, 'tiers_client_account')
		.then(client_accounts => {
			return TiersClientAccountTransformer.process(client_accounts, 'WithTiers')
		})
		.then(res => {
			if(res.filter(ca => ca.tiersclientaccount_accountingplan && ca.tiersclientaccount_accountingplan.accountingplan_default == 1).length) {
				return res.filter(ca => ca.tiersclientaccount_accountingplan.accountingplan_default == 1)
			}
			else {
				return res
			}
		})
	}

	async all_client_account(item) {
		return this.fetchRelationship(item, 'tiers_client_account')
		.then(client_accounts => {
			return TiersClientAccountTransformer.process(client_accounts, 'WithTiers')
		})
		.then(res => {
			return res
		})
	}

	async tiers_tags(item) {
        return this.db().t('tags')
			.then(table => {
				return table.where({
					tags_type: 'tiers',
					tags_fk: item.tiers_id
				})
			})
			.then(col => {
				return col.toArray()
			})
	}

	fetchWithPhoneAndClientAccount(col) {
		this.additionalColumns({
			'phone': this.phone,
			'phone_combine': this.phone_combine,
			'client_account': this.client_account,
			'all_client_account': this.all_client_account,
			'tiers_tags': this.tiers_tags
		})
		return col
	}

	transformWithPhoneAndClientAccount(tier) {
		return {
			'phones': tier.phone,
			'tiers_id': tier.tiers_id,
			'tiers_rs': tier.tiers_rs,
			'tiers_lastname': tier.tiers_moral ? null : tier.tiers_lastname,
			'tiers_firstname': tier.tiers_moral ? null : tier.tiers_firstname,
			'tiers_civility': tier.tiers_moral ? null : tier.tiers_civility,
			'tiers_mail': tier.tiers_mail,
			'tiers_address1': tier.tiers_address1,
			'tiers_address2': tier.tiers_address2,
			'tiers_postalcode': tier.tiers_postalcode,
			'tiers_town': tier.tiers_town,
			'tiers_country': tier.tiers_country,
			'tiers_website': tier.tiers_website,
			'tiers_moral': tier.tiers_moral,
			'tiers_entity': tier.tiers_entity,
			'tiers_valide': tier.tiers_valide,
			'tiers_duedateconfig': tier.tiers_duedateconfig,
			'tiers_siret': tier.tiers_siret,
			'tiers_siren': tier.tiers_siren,
			'siren_siret': tier.tiers_siret && tier.tiers_siret.trim() != "" ? tier.tiers_siret : tier.tiers_siren,
			'tiers_vat_number': tier.tiers_vat_number,
			'tiers_currency': tier.tiers_currency,
			'tiers_archive': tier.tiers_archive,
			'href': 'tiersFiche',
			'params': { tiers_id: tier.tiers_id },
			'phones_number': tier.phone_combine.join("<br>"),
			'pays': Countries[Vue.i18n.locale()].label[tier.tiers_country],
			'client_account': tier.client_account[0],
			'all_client_account': tier.all_client_account,
			'tiers_alias': tier.tiers_alias,
			'tiers_clientarea': tier.tiers_clientarea,
			'tiers_tags': tier.tiers_tags.map(tag => tag.tags_color),
			'tiers_last_connection_ca_date': tier.tiers_last_connection_ca,
			'tiers_statut_rcs': tier.tiers_statut_rcs,
			'tiers_vatcheck': tier.tiers_vatcheck ?? 0,
			'tiers_statut_rne': tier.tiers_statut_rne,
			'tiers_inserted_date': tier.tiers_inserted
        }
	}

	fetchLightWithPhone(col) {
		this.additionalColumns({
			'phone': this.phone
		})
		return col
	}

	transformLightWithPhone(tier) {
		return {
			'phones': tier.phone,
			'tiers_id': tier.tiers_id,
			'tiers_rs': tier.tiers_rs,
			'tiers_mail': tier.tiers_mail,
			'tiers_address1': tier.tiers_address1,
			'tiers_address2': tier.tiers_address2,
			'tiers_postalcode': tier.tiers_postalcode,
			'phones_number': Common.getArrayObjProperty(tier.phone, 'phone_combine').join("<br>"),
			'pays': Countries[Vue.i18n.locale()].label[tier.tiers_country],
			'tiers_alias': tier.tiers_alias,
			'tiers_description': tier.tiers_description,
			'tiers_town': tier.tiers_town
        }
	}

	transformOnlyRS(tier) {
		return {
			'tiers_id': tier.tiers_id,
			'tiers_rs': tier.tiers_rs
		}
	}
}

export default TierTransformer
