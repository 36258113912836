import Vue from 'vue'
import Common from '@/assets/js/common'

import Transformer from './Transformer'
import HorseTransformer from './HorseTransformer'
import ActeTypeTransformer from './ActeTypeTransformer'
import ActesDocumentLienTransformer from './ActesDocumentLienTransformer'
import ActeArticleTransformer from './ActeArticleTransformer'
import PedigreeTransformer from './PedigreeTransformer'
import ReponseTransformer from './ReponseTransformer'
import NoteTransformer from './NoteTransformer'
import ContactTransformer from './ContactTransformer'

import _forEach from 'lodash/forEach'
import _groupBy from 'lodash/groupBy'
import _merge from 'lodash/merge'
import _orderBy from 'lodash/orderBy'
import ArticleTransformer from './ArticleTransformer'
import AccountingPlanTransformer from './AccountingPlanTransformer'
import SeasonMareStallionTransformer from './SeasonMareStallionTransformer'
import DocumentLienTransformer from './DocumentLienTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} actes
 */
export class ActeTransformer extends Transformer {

	table = 'horse_actes'
	transactionTables = ['horse', 'horse_actes_groupe', 'horse_actes_type', 'horse_pedigree']
	needAccessRights = true

    getBtableProps(item) {
        const hasBeenDone = item.actes_actesstatut == 1 || item.actes_actesstatut == Vue.i18n.translate('acte.faits') 

        return _merge(item, {
            'class': hasBeenDone ? "" : (item.actes_ageday > 0 ? "retard" : ""),
            'isActive': false
        })
	}

	formatted_statut(item) {
		if(item.actes_actesstatut == 1) {
			return Vue.i18n.translate('acte.faits')
		}
		else if(item.actes_ageday > 0) {
			return Vue.i18n.translate('global.en_retard')
		}
		else {
			return Vue.i18n.translate('acte.a_faire')
		}
	}

	statusCode(item) {
		if(item.actes_actesstatut == 1) {
			return 'done'
		}
		else if(item.actes_ageday > 0) {
			return 'delay'
		}
		else {
			return 'todo'
		}
	}

	sortByGroup(item) {
		if(item.actes_actesstatut == 1) {
			return 3
		}
		else if(item.actes_ageday > 0) {
			return 1
		}
		else {
			return 2
		}
	}

	calculDifference(date1, date2) {
		date1.setHours(0,0,0,0);
		date2.setHours(0,0,0,0);
		let Difference_In_Time = date2.getTime() - date1.getTime(); 
		let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
		return Math.round(Difference_In_Days);
	}

	commentaireFormatted(articles) {
		let commentaire = ""
		for  (const article of articles){
			if (article.actesarticles_commentaire){
				commentaire += article.actesarticles_commentaire + ' / '
			}
		}
		return commentaire.substring(0, commentaire.length-3);
	}


    getResponses(item, formatted=false) {
        return this.db().t('reponse')
        .then(table => {
            return table.where({
                reponse_fk: parseInt(item.actes_id),
                reponse_type: 'horse_actes'
			})
			.transform(new ReponseTransformer('withQuestion'))
		})
        .then(result => {
        	if(!formatted)
        		return result
			return this.getFormattedResponses(item, item.actes_type, result)
        })
	}

	getFormattedResponses(acte, actes_type, responses_list) {
		let acte_with_stallion = [
			'REPRO_INSMINATION_ARTIFICIELLE_EN_FRAIS',
			'REPRO_INSMINATION_ARTIFICIELLE_EN_CONGEL',
			'REPRO_INSMINATION_EN_RFRIGR',
			'REPRO_INSMINATION_ARTIFICIELLE_EN_RFRIGR_TRANSPORT',
			'REPRO_SAILLIE_EN_MAIN',
			'REPRO_SAILLIE_EN_LIBERT',
			'REPRO_COLLECTE_DEMBRYON'				
		]

		const clear_result = responses_list.filter(rep => {
			return rep.reponse_data[0] !== null
		})
		clear_result.sortBy('reponse_question_index')

		let reponse_reponse_array = clear_result.map(rep => rep.reponse_question_code)
		let reponse_formatted_data = clear_result.map(rep => rep.formatted_data).join(', ')

		let tab_res = {
			reponse_ids: responses_list.map(rep => rep.reponse_id).join(','),
			reponse_formatted_data: reponse_formatted_data,
			reponse_reponse_array: reponse_reponse_array,
			reponse_hidden_data: responses_list.map(rep => rep.reponse_data_hidden),
			stallion: acte.seasonmarestallion ? acte.seasonmarestallion.horse.horse_nom : '',
			og: "",
			od: "",
			uterus: "",
			dg: "",
			col: "",
			chaleur: "",
			ovulation: null
		}

		responses_list.forEach(rep => {
			if(actes_type && actes_type.actestype_code == 'REPRO_DIAGNOSTIC_DE_GESTATION') {
				if(rep.reponse_question_code == "Résultat") {
					tab_res.dg = rep.reponse_data.join(', ')
				}
				else if(rep.reponse_question_code == "Étalon") {
					tab_res.stallion = rep.reponse_data.join(', ')
				}
			}
			else if(actes_type && actes_type.actestype_code == 'REPRO_ECHOGRAPHIE') {
				if(rep.reponse_question_code == "Ovaire Gauche") {
					tab_res.og = rep.reponse_data.join(', ')
				}
				else if(rep.reponse_question_code == "Ovaire Droit") {
					tab_res.od = rep.reponse_data.join(', ')
				}
				else if(rep.reponse_question_code == "Utérus") {
					tab_res.uterus = rep.reponse_data.join(', ')
				}
				else if(rep.reponse_question_code == "Ouverture du col") {
					tab_res.col = rep.reponse_data.join(', ')
				}
				else if(rep.reponse_question_code == "Numéro de chaleur") {
					tab_res.chaleur = rep.reponse_data.join(', ')
				}
				else if(rep.reponse_question_code == "Ovulation") {
					tab_res.ovulation = rep.reponse_data.join(', ') === 'true'
				}
			}
			else if(actes_type && (actes_type.actestype_code == 'PERF_COURSE_GALOP' ||actes_type.actestype_code == 'PERF_COURSE_TROT') ) {
				switch (rep.reponse_question_code) {
					case 'Discipline':
						tab_res.discipline = rep.reponse_data.join(', ')
						break;
					case 'STATUT':
						tab_res.statut = rep.reponse_data.join(', ')
						break;
					case 'Nom de la course':
						tab_res.nom_course  = rep.reponse_data.join(', ')
						break;
					case 'Hippodrome':
						tab_res.hippodrome  = rep.reponse_data.join(', ')
						break;
					case "Numéro de la course":
						tab_res.num_course  = rep.reponse_data.join(', ')
						break;
					case "Numéro cheval":
						tab_res.num_horse  = rep.reponse_data.join(', ')
						break;
					case "Heure de la course":
						tab_res.horaire  = rep.reponse_data.join(', ')
						break;
					case "Distance de la course (m)":
						tab_res.distance_course  = rep.reponse_data.join(', ')
						break;
					case "Type de course":
						tab_res.type_course  = rep.reponse_data.join(', ')
						break;
					case "Catégorie de la course":
						tab_res.cat_course  = rep.reponse_data.join(', ')
						break;
					case "Corde":
						tab_res.corde  = rep.reponse_data.join(', ')
						break;
					case "Nom de l'entraineur":
						tab_res.nom_entraineur  = rep.reponse_data.join(', ')
						break;
					case "Jockey":
						tab_res.jockey  = rep.reponse_data.join(', ')
						break;
					case "Poids":
						tab_res.poids  = rep.reponse_data.join(', ')
						break;
					case "Oeillères":
						tab_res.oeilleres  = rep.reponse_data.join(', ')
						break;
					case "RESULTAT : rang":
						tab_res.rang  = rep.reponse_data.join(', ')
						break;
					case "RESULTAT : gain":
						tab_res.gain  = rep.reponse_data.join(', ')
						break;
					case "RESULTAT : Temps":
						tab_res.temps  = rep.reponse_data.join(', ')
						break;
					case "RESULTAT : Ecart au précédent":
						tab_res.ecart_precedent  = rep.reponse_data.join(', ')
						break;
					case "Lien vidéo":
						tab_res.lien_video  = rep.reponse_data.join(', ')
						break;
				  }
			}
			else if(actes_type && actes_type.actestype_code == 'PERF_CONCOURS') {
				switch (rep.reponse_question_code) {
					case "Type de concours":
						tab_res.type_concours = rep.reponse_data.join(', ')
						break;
					case "Localisation":
						tab_res.localisation = rep.reponse_data.join(', ')
						break;
					case "Horaire de passage":
						tab_res.horaire  = rep.reponse_data.join(', ')
						break;
					case "STATUT":
						tab_res.statut  = rep.reponse_data.join(', ')
						break;
					case "Cavalier":
						tab_res.jockey  = rep.reponse_data.join(', ')
						break;
					case "RESULTAT : Rang":
						tab_res.rang  = rep.reponse_data.join(', ')
						break;
					case "RESULTAT : Gain":
						tab_res.gain  = rep.reponse_data.join(', ')
						break;
					case "Lien vidéo":
						tab_res.lien_video  = rep.reponse_data.join(', ')
						break;
				  }
			}
			else if(rep.reponse_question_code == "Étalon") {
				tab_res.stallion = rep.reponse_data.join(', ')
			}
		})

		return tab_res
	}

	async horse(item) {
		const horse = await this.fetchRelationship(item, 'actes_horse')
		return HorseTransformer.process(horse, 'withPedigree')
	}

	async acte_horse(item) {
		const horse = await this.fetchRelationship(item, 'actes_horse')
		return HorseTransformer.process(horse, 'withPedigreeAndMainResidence')
	}

	async actes_articles(item) {
		if (!this.hasRight('FACT')) {
			return
		}

		const actes_articles = await this.fetchRelationship(item, 'horse_actes_articles')
		return ActeArticleTransformer.process(actes_articles)
	}

	async actes_articles_formatted(item) {
		if (!this.hasRight('FACT')) {
			return
		}

		const articles = await this.fetchAdditionalColumn(item, 'actes_articles')
		let articles_formatted = []
		await Common.asyncForEach(articles, async (a) => {
			articles_formatted.push(
				`${a.articles.articles_label} | ${a.actesarticles_quantite} * ${await Common.priceFormat(a.actesarticles_ht / 100)} = ${await Common.priceFormat(a.actesarticles_ht * a.actesarticles_quantite / 100)} HT | ${a.vat.vat_label}`
			);
		})

		return articles_formatted.join(', ')
	}

	async entity(item) {
		if (!this.hasRight('FACT')) {
			return
		}

		let accounting_plan = null
		if(item.actes_accountingplan) {
			accounting_plan = await this.db().t('accounting_plan')
			.then(table => {
				return table.where('accountingplan_id').equals(item.actes_accountingplan).transform(new AccountingPlanTransformer())
			})
		}

		if(accounting_plan) {
			return accounting_plan[0]
		}

		return null
	}

	async document_lien(item) {

		let document_lien = null
		if(item.actes_id) {
			document_lien = await this.db().t('actes_document_lien')
			.then(table => {
				return table.where('actesdocumentlien_actes').equals(item.actes_id).transform(new ActesDocumentLienTransformer())
			})
		}

		if(document_lien) {
			return document_lien
		}

		return null
	}

	actes_ageday(acte) {
		if(acte.actes_date instanceof Date) {
			const now = new Date()
			return Math.floor((now - acte.actes_date.getTime()) / (1000 * 3600 * 24)) // Pour un résultat en jours
		}
		return 0
	}

	actes_type(acte) {
		return this.fetchRelationship(acte, 'actes_actestype')
	}

	// Alias pour fonctionner avec les tableaux
	actetype(acte) {
		return this.actes_type(acte)
	}

	async actegroupe(acte) {
		const type = await this.fetchAdditionalColumn(acte, 'actes_type')
		return this.db().t('horse_actes_groupe')
		.then(table => {
			return table.where({
				'actesgroupe_code': type.actestype_codegroupe
			})
			.first()
		})
	}

	// Uniquement utilisée par les tableaux
	async pedigree(item) {
		const pedigree = await this.db().t('horse_pedigree')
		.then(table => {
			return table.get({
				'pedigree_horse': item.actes_horse
			})
		})
		.then(pedigree => {
			return PedigreeTransformer.process(pedigree, 'light')
		})

		return {
			'pere'              : pedigree != undefined && pedigree.length > 0 ? pedigree.pere : null,
			'mere'              : pedigree != undefined && pedigree.length > 0 ? pedigree.mere : null
		}
	}

	async seasonmarestallion(item) {
		if(item.acte_horse && item.acte_horse.horse_sexe == 'F') {
			const season = await this.db().t('season')
            .then(table => {
                return table.toCollection()
                .filter(s => (s.season_start <= item.actes_date) && (s.season_end >= item.actes_date))
            })
			.then(col => {
				return col.first()
			})

			if(season) {
				const season_mare = await this.db().t('season_mare')
				.then(table => {
					return table
					.where({
						seasonmare_horse: parseInt(item.acte_horse.horse_id),
						seasonmare_season: season.season_id
					})
	            })
				.then(col => {
					return col.first()
				})

				if(season_mare) {
					const sms = await this.db().t('season_mare_stallion')
		            .then(table => {
		                return table.where({
		                	seasonmarestallion_seasonmare: season_mare.seasonmare_id, 
		                	seasonmarestallion_default: 1
		                })
		                .toArray()
		            })
		            let sms_find = sms.find(sms => sms.seasonmarestallion_porteuse == null)

					return await SeasonMareStallionTransformer.process(sms_find, 'withHorse')
				}
			}
		}

		return ''
	}

	contact(acte) {
		return this.fetchRelationship(acte, 'actes_contact')
	}

	notes(item) {
		if(!this.hasRight('NOTE')) {
			return []
		}

        return this.db().t('horse_note')
        .then(table => {
            return table.where({
                note_horse: parseInt(item.actes_horse),
                note_acte: parseInt(item.actes_id)
			})
		})
		.then(res => {
			return res.toArray()
		})
	}


	async stringified(item) {
		let stringified = await super.stringified(item)
		const horse = await this.fetchRelationship(item, 'actes_horse')
		return `${stringified}|${horse.horse_nom}`
	}

	async transformLite(acte) {
        return {
            'actes_id'              : acte.actes_id,
            'actes_date'            : acte.actes_date,
            'actes_horse'           : acte.actes_horse,
            'actes_time'            : acte.actes_time,
            'actes_date_fin'        : acte.actes_date_fin,
            'actes_commentaire'     : acte.actes_commentaire,
            'actes_ageday'          : acte.actes_ageday,
        }
	}

	async fetchLiteWithNotes(col) {
		this.additionalColumns({
			'actes_articles': this.actes_articles,
			'actes_articles_formatted': this.actes_articles_formatted,
			'actes_ageday': this.actes_ageday,
			'actes_type': this.actes_type,
			'notes': this.notes,
			'contact': this.contact,
			'entity': this.entity
		})

        return col
	}

	async transformLiteWithNotes(acte) {
		const reponses = await this.getResponses(acte, true)
		const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')
		const contact = await ContactTransformer.process(acte.contact)

		if(actetype && actetype.actestype_groupe) {
			actetype.actestype_groupe.actesgroupe_label = Vue.i18n.translate('actes_groupe.' + actetype.actestype_codegroupe)
		}

        let result = {
            'actes_id'              : acte.actes_id,
            'actes_actesstatut'     : acte.actes_actesstatut,
            'actes_actestype'		: acte.actes_actestype,
            'actes_date'            : acte.actes_date,
            'actes_time'            : acte.actes_time,
            'actes_date_fin'        : acte.actes_date_fin,
            'actes_horse'           : acte.actes_horse,
            'actes_commentaire'     : acte.actes_commentaire,
            'actes_show_commentaire': acte.actes_show_commentaire,
            'actes_document'        : acte.actes_document,
            'actes_articles'        : acte.actes_articles,
			'actes_articles_formatted': acte.actes_articles_formatted,
            'actes_ageday'          : acte.actes_ageday,
			'actetype'              : actetype,
			'actetype_label'		: actetype.actestype_licencekey ? actetype.actestype_label : Vue.i18n.translate('actes_type.' + actetype.actestype_code),
            'actegroupe'            : actetype.actestype_groupe,
            'reponse'               : reponses,
			'notes'					: { raw: acte.notes, ...NoteTransformer.synthetize(acte.notes) },
            'contact'				: contact,
            'contact_label'			: contact ? contact.contact_civility + " " + contact.contact_firstname  + " " + contact.contact_lastname : "",
			'entity_id'				: acte.entity ? acte.entity.tiers.tiers_id : null,
			'entity_rs'				: acte.entity ? acte.entity.tiers.tiers_rs : ''
        }

        return this.getBtableProps(result)
	}

    async fetchHeavy (col) {
		this.additionalColumns({
			'horse': this.horse,
			'actes_articles': this.actes_articles,
			'actes_articles_formatted': this.actes_articles_formatted,
			'actes_ageday': this.actes_ageday,
			'actes_type': this.actes_type,
			'pedigree': this.pedigree,
			'contact': this.contact
		})

        return col
    }

    async transformHeavy (acte) {
        const reponses = await this.getResponses(acte, true)
		const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')
		const contact = await ContactTransformer.process(acte.contact)

		if(actetype && actetype.actestype_groupe) {
			actetype.actestype_groupe.actesgroupe_label = Vue.i18n.translate('actes_groupe.' + actetype.actestype_codegroupe)
		}

        let result = {
            'actes_id'              : acte.actes_id,
            'actes_actesstatut'     : acte.actes_actesstatut,
            'actes_actestype'		: acte.actes_actestype,
            'actes_date'            : acte.actes_date,
            'actes_time'            : acte.actes_time,
            'actes_date_fin'        : acte.actes_date_fin,
            'actes_horse'           : acte.horse.horse_id,
            'actes_commentaire'     : acte.actes_commentaire,
            'actes_show_commentaire': acte.actes_show_commentaire,
            'actes_document'        : acte.actes_document,
            'actes_articles'        : acte.actes_articles,
			'actes_articles_formatted': acte.actes_articles_formatted,
            'actes_ageday'          : acte.actes_ageday,
            'horse_nom'             : acte.horse.horse_nom,
            'horse_transpondeur'    : acte.horse.horse_transpondeur,
            'horse_interne'         : acte.horse.horse_interne,
			'horse_wholesire'       : acte.horse.horse_sire !== null ? acte.horse.horse_sire+acte.horse.horse_cle : '',
			'horse_categorie'		: acte.horse.horse_categorie,
			'actetype'              : actetype,
			'actetype_label'		: actetype.actestype_licencekey ? actetype.actestype_label : Vue.i18n.translate('actes_type.' + actetype.actestype_code),
            'actegroupe'            : actetype.actestype_groupe,
            'reponse'               : reponses,
            'pedigree'				: acte.pedigree,
            'contact'				: contact,
            'contact_label'			: contact ? contact.contact_civility + " " + contact.contact_firstname  + " " + contact.contact_lastname : ""
        }

        return this.getBtableProps(result)
    }

	async resolveWithNotes(col) {
		const actes = await col.with({
			notes: 'horse_note',
			contact: 'actes_contact',
		})

		const acte_ids = actes.map(acte => (acte.actes_id))
		const horse_ids = []
		const actestype_ids = []
		const accountingplans_ids = []

		actes.forEach(acte => {
			if(acte.actes_horse) {
				horse_ids.push(acte.actes_horse)
			}

			if(acte.actes_actestype) {
				actestype_ids.push(acte.actes_actestype)
			}

			if(acte.actes_accountingplan) {
				accountingplans_ids.push(acte.actes_accountingplan)
			}
		})

		// Horses
		const horses = await this.db().t('horse')
		.then(table => {
			return table.where('horse_id').anyOf(horse_ids).transform(new HorseTransformer('heavy'))
		})

		// Document
		const documents = await this.db().t('actes_document_lien')
		.then(table => {
			return table.where('actesdocumentlien_actes').anyOf(acte_ids).transform(new ActesDocumentLienTransformer())
		})

		// Acte type
		const acte_types = await this.db().t('horse_actes_type')
		.then(table => {
			return table.where('actestype_id').anyOf(actestype_ids).transform(new ActeTypeTransformer('withGroups'))
		})

		// Réponses
		const reponses = await this.db().t('reponse')
		.then(table => {
			return table.where('reponse_fk').anyOf(acte_ids)
			.and(r => (
				r.reponse_type === 'horse_actes'
				&& r.reponse_data[0] !== null
			))
			.transform(new ReponseTransformer('withQuestion'))
		})

		// Articles
		let actes_articles = []
		let accounting_plans = []
		if(this.hasRight('FACT') || this.hasRight('ALHFACT')) {
			actes_articles = await this.db().t('horse_actes_articles')
			.then(table => {
				return table.where('actesarticles_actes').anyOf(acte_ids).toArray()
			})
	
			const articles_ids = await actes_articles.map(actes_article => {
				return actes_article.actesarticles_articles
			})
	
			const articles = await this.db().t('articles')
			.then(table => {
				return table.where('articles_id').anyOf(articles_ids).toArray()
			})

			// VAT
			const vat_ids = []
			articles.forEach(article => {
				if(article.articles_vat) {
					vat_ids.push(article.articles_vat)
				}
			})
	
			const vats = await this.db().t('vat')
			.then(table => {
				return table.where('vat_id').anyOf(vat_ids).toArray()
			})

			accounting_plans = await this.db().t('accounting_plan')
			.then(table => {
				return table.where('accountingplan_id').anyOf(accountingplans_ids).transform(new AccountingPlanTransformer())
			})

			// Articles formatted
			for(let i=0; i < actes_articles.length; i++) {
				const actes_article = actes_articles[i]
				const article = articles.find((a) => (actes_article.actesarticles_articles === a.articles_id))
				const vat = vats.find(v => (article.articles_vat === v.vat_id))
	
				actes_article.formatted = `${article.articles_label} | ${await Common.priceFormat(actes_article.actesarticles_ht / 100)} HT | ${vat.vat_label}`
			}
		}

		return actes.map(acte => {
			const horse = horses.find(h => (h.horse_id === acte.actes_horse))
			const actetype = acte_types.find(at => (at.actestype_id === acte.actes_actestype))
			const document = documents.filter(doc => (doc.actesdocumentlien_actes === acte.actes_id))

			let acte_notes = acte.notes
			if(!this.hasRight('NOTE')) {
				acte_notes = []
			}

			const contact = acte.contact.length !== 0 ? acte.contact : null
			const acte_articles = actes_articles.filter(actes_article => (actes_article.actesarticles_actes === acte.actes_id))
			const acte_reponses = reponses.filter(r => (r.reponse_fk === acte.actes_id))
			const response = this.getFormattedResponses(acte, actetype, acte_reponses)
			const accounting_plan = accounting_plans.find(ap => ap.accountingplan_id == acte.actes_accountingplan)

			if(actetype && actetype.actestype_groupe) {
				actetype.actestype_groupe.actesgroupe_label = Vue.i18n.translate('actes_groupe.' + actetype.actestype_codegroupe)
			}

			return {
				'actes_id'				: acte.actes_id,
				'actes_actesstatut'		: acte.actes_actesstatut,
				'actes_actestype'		: acte.actes_actestype,
				'actes_date'			: acte.actes_date,
				'actes_time'			: acte.actes_time,
				'actes_date_fin'		: acte.actes_date_fin,
				'actes_horse'			: horse.horse_id,
				'actes_commentaire'		: acte.actes_commentaire,
				'actes_show_commentaire': acte.actes_show_commentaire,
				'actes_document'		: acte.actes_document,
				'actes_articles'		: acte_articles,
				'actes_articles_formatted': acte_articles.map(acte_article => (acte_article.formatted)).join(', '),
				'horse_nom'				: horse.horse_nom,
				'horse_transpondeur'	: horse.horse_transpondeur,
				'horse_interne'			: horse.horse_interne,
				'horse_wholesire'		: horse.horse_sire !== null ? horse.horse_sire+horse.horse_cle : '',
				'horse_categorie'		: horse.horse_categorie,
				'horse_inactive'		: horse.horse_inactive,
				'horse_sexe'			: horse.horse_sexe,
				'actetype'				: actetype,
				'actetype_label'		: actetype.actestype_licencekey ? actetype.actestype_label : Vue.i18n.translate('actes_type.' + actetype.actestype_code),
				'actegroupe'			: actetype.actestype_groupe,
				'reponse'				: response,
				'pedigree'				: horse.pedigree,
				'notes'					: { raw: acte_notes, ...NoteTransformer.synthetize(acte_notes) },
				'contact'				: contact,
				'actes_document_lien'	: document,
				'contact_label'			: contact ? contact.contact_civility + " " + contact.contact_firstname  + " " + contact.contact_lastname : "",
				'horse_datenaissance'	: horse.horse_datenaissance,
				'horse_residence_label' : horse.horse_residence_label,
				'entity_id'				: accounting_plan ? accounting_plan.tiers.tiers_id : null,
				'entity_rs'				: accounting_plan ? accounting_plan.tiers.tiers_rs : ''
			}
		})
	}

	async fetchWithNotes (col) {
		this.additionalColumns({
			'horse': this.horse,
			'actes_articles': this.actes_articles,
			'actes_articles_formatted': this.actes_articles_formatted,
			'actes_ageday': this.actes_ageday,
			'actes_type': this.actes_type,
			'notes': this.notes,
			'pedigree': this.pedigree,
			'contact': this.contact,
		})

        return col
    }

    async transformWithNotes (acte) {
        const reponses = await this.getResponses(acte, true)
		const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')
		const contact = await ContactTransformer.process(acte.contact)

		if(actetype && actetype.actestype_groupe) {
			actetype.actestype_groupe.actesgroupe_label = Vue.i18n.translate('actes_groupe.' + actetype.actestype_codegroupe)
		}

        let result = {
            'actes_id'              : acte.actes_id,
            'actes_actesstatut'     : acte.actes_actesstatut,
            'actes_actestype'		: acte.actes_actestype,
            'actes_date'            : acte.actes_date,
            'actes_time'            : acte.actes_time,
            'actes_date_fin'        : acte.actes_date_fin,
            'actes_horse'           : acte.horse.horse_id,
            'actes_commentaire'     : acte.actes_commentaire,
            'actes_show_commentaire': acte.actes_show_commentaire,
            'actes_document'        : acte.actes_document,
            'actes_articles'        : acte.actes_articles,
			'actes_articles_formatted': acte.actes_articles_formatted,
            'actes_ageday'          : acte.actes_ageday,
            'horse_nom'             : acte.horse.horse_nom,
            'horse_transpondeur'    : acte.horse.horse_transpondeur,
            'horse_interne'         : acte.horse.horse_interne,
			'horse_wholesire'       : acte.horse.horse_sire !== null ? acte.horse.horse_sire+acte.horse.horse_cle : '',
			'horse_categorie'		: acte.horse.horse_categorie,
			'horse_sexe'			: acte.horse.horse_sexe,
			'actetype'              : actetype,
			'actetype_label'		: actetype.actestype_licencekey ? actetype.actestype_label : Vue.i18n.translate('actes_type.' + actetype.actestype_code),
            'actegroupe'            : actetype.actestype_groupe,
            'reponse'               : reponses,
            'pedigree'				: acte.pedigree,
			'notes'					: { raw: acte.notes, ...NoteTransformer.synthetize(acte.notes) },
            'contact'				: contact,
            'actes_document_lien'	: acte.actes_document_lien,
            'contact_label'			: contact ? contact.contact_civility + " " + contact.contact_firstname  + " " + contact.contact_lastname : ""
        }

        return this.getBtableProps(result)
    }

	async fetchWithHorseAndMainResidence (col) {
		this.additionalColumns({
			'acte_horse': this.acte_horse,
			'actes_articles': this.actes_articles,
			'actes_articles_formatted': this.actes_articles_formatted,
			'actes_ageday': this.actes_ageday,
			'actes_type': this.actes_type,
			'pedigree': this.pedigree,
			'entity': this.entity,
			'seasonmarestallion': this.seasonmarestallion
		})

        return col
    }

    async transformWithHorseAndMainResidence (acte) {
        const reponses = await this.getResponses(acte, true)
		const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')

		if(actetype && actetype.actestype_groupe) {
			actetype.actestype_groupe.actesgroupe_label = Vue.i18n.translate('actes_groupe.' + actetype.actestype_codegroupe)
		}

        let result = {
            'actes_id'              : acte.actes_id,
            'actes_actesstatut'     : acte.actes_actesstatut,
            'actes_actestype'		: acte.actes_actestype,
            'actes_date'            : acte.actes_date,
            'actes_time'            : acte.actes_time,
            'actes_date_fin'        : acte.actes_date_fin,
            'actes_horse'           : acte.acte_horse.horse_id,
            'actes_commentaire'     : acte.actes_commentaire,
            'actes_show_commentaire': acte.actes_show_commentaire,
            'actes_document'        : acte.actes_document,
            'actes_articles'        : acte.actes_articles,
			'actes_articles_formatted': acte.actes_articles_formatted,
            'actes_ageday'          : acte.actes_ageday,
            'horse_nom'             : acte.acte_horse.horse_nom,
            'horse_transpondeur'    : acte.acte_horse.horse_transpondeur,
            'horse_interne'         : acte.acte_horse.horse_interne,
			'horse_wholesire'       : acte.acte_horse.horse_sire !== null ? acte.acte_horse.horse_sire+acte.acte_horse.horse_cle : '',
			'horse_categorie'		: acte.acte_horse.horse_categorie,
			'actetype'              : actetype,
			'actetype_label'		: actetype.actestype_licencekey ? actetype.actestype_label : Vue.i18n.translate('actes_type.' + actetype.actestype_code),
            'actegroupe'            : actetype.actestype_groupe,
            'reponse'               : reponses,
            'pedigree'				: acte.pedigree,
			'main_residence_label'	: acte.acte_horse.horse_residence_label,
			'entity_id'				: acte.entity ? acte.entity.tiers.tiers_id : null,
			'entity_rs'				: acte.entity ? acte.entity.tiers.tiers_rs : '',
			'notes'					: '',
			'horse_last_echo_og'	: acte.acte_horse.horse_last_echo_og,
			'horse_last_echo_od'	: acte.acte_horse.horse_last_echo_od,
			'horse_last_echo_uterus': acte.acte_horse.horse_last_echo_uterus,
			'seasonmarestallion_status'	  : acte.seasonmarestallion ? acte.seasonmarestallion.seasonmarestallion_status : '',
			'horse_stallion'	  		  : acte.seasonmarestallion ? acte.seasonmarestallion.horse.horse_nom : '',
			'date_since_last_covering'	  : acte.seasonmarestallion && acte.seasonmarestallion.seasonmarestallion_dds ? this.calculDifference(acte.seasonmarestallion.seasonmarestallion_dds, new Date()) : ''
        }

        return this.getBtableProps(result)
    }
	async fetchWithHorseAndMainResidenceAndArrayReponse (col) {
		this.additionalColumns({
			'acte_horse': this.acte_horse,
			'actes_articles': this.actes_articles,
			'actes_articles_formatted': this.actes_articles_formatted,
			'actes_ageday': this.actes_ageday,
			'actes_type': this.actes_type,
			'pedigree': this.pedigree,
			'entity': this.entity,
			'seasonmarestallion': this.seasonmarestallion
		})

        return col
    }

    async transformWithHorseAndMainResidenceAndPerformance (acte) {
        const reponses = await this.getResponses(acte, true)
		const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')

        let result = {
            'actes_id'              : acte.actes_id,
            'actes_actesstatut'     : acte.actes_actesstatut,
            'actes_actestype'		: acte.actes_actestype,
            'actes_date'            : acte.actes_date,
            'actes_time'            : acte.actes_time,
            'actes_date_fin'        : acte.actes_date_fin,
            'actes_horse'           : acte.acte_horse.horse_id,
            'actes_commentaire'     : acte.actes_commentaire,
            'actes_show_commentaire': acte.actes_show_commentaire,
            'actes_document'        : acte.actes_document,
            'actes_articles'        : acte.actes_articles,
			'actes_articles_formatted': acte.actes_articles_formatted,
            'actes_ageday'          : acte.actes_ageday,
            'horse_nom'             : acte.acte_horse.horse_nom,
            'horse_transpondeur'    : acte.acte_horse.horse_transpondeur,
            'horse_interne'         : acte.acte_horse.horse_interne,
			'horse_wholesire'       : acte.acte_horse.horse_sire !== null ? acte.acte_horse.horse_sire+acte.acte_horse.horse_cle : '',
			'horse_categorie'		: acte.acte_horse.horse_categorie,
			'actetype'              : actetype,
			'actetype_label'		: actetype.actestype_label,
            'actegroupe'            : actetype.actestype_groupe,
            'reponse'               : reponses,
            'pedigree'				: acte.pedigree,
			'main_residence_label'	: acte.acte_horse.horse_residence_label,
			'entity_id'				: acte.entity ? acte.entity.tiers.tiers_id : null,
			'entity_rs'				: acte.entity ? acte.entity.tiers.tiers_rs : '',
			'notes'					: '',
			'horse_last_echo_og'	: acte.acte_horse.horse_last_echo_og,
			'horse_last_echo_od'	: acte.acte_horse.horse_last_echo_od,
			'horse_last_echo_uterus': acte.acte_horse.horse_last_echo_uterus,
			'seasonmarestallion_status'	  : acte.seasonmarestallion ? acte.seasonmarestallion.seasonmarestallion_status : '',
			'horse_stallion'	  		  : acte.seasonmarestallion ? acte.seasonmarestallion.horse.horse_nom : '',
			'date_since_last_covering'	  : acte.seasonmarestallion && acte.seasonmarestallion.seasonmarestallion_dds ? this.calculDifference(acte.seasonmarestallion.seasonmarestallion_dds, new Date()) : ''
        }

        return this.getBtableProps(result)
    }

	async fetchWithHorseAndMainResidenceNotes (col) {
		this.additionalColumns({
			'acte_horse': this.acte_horse,
			'actes_articles': this.actes_articles,
			'actes_ageday': this.actes_ageday,
			'actes_type': this.actes_type,
			'notes': this.notes,
			'pedigree': this.pedigree,
			'contact': this.contact,
			'actes_articles_formatted': this.actes_articles_formatted,
			'entity': this.entity,
			'seasonmarestallion': this.seasonmarestallion,
			'document_lien':this.document_lien
		})

        return col
    }

    async transformWithHorseAndMainResidenceNotes (acte) {
        const reponses = await this.getResponses(acte, true)
		const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')
		const contact = await ContactTransformer.process(acte.contact)

		if(actetype && actetype.actestype_groupe) {
			actetype.actestype_groupe.actesgroupe_label = Vue.i18n.translate('actes_groupe.' + actetype.actestype_codegroupe)
		}

        let result = {
            'actes_id'              	  : acte.actes_id,
            'actes_actesstatut'     	  : acte.actes_actesstatut,
            'is_validated'     	 	  	  : acte.actes_actesstatut,
            'actes_actestype'			  : acte.actes_actestype,
            'actes_date'           		  : acte.actes_date,
            'actes_time'            	  : acte.actes_time,
            'actes_date_fin'        	  : acte.actes_date_fin,
            'actes_horse'           	  : acte.acte_horse.horse_id,
			'actes_articles_commentaire'  : acte.actes_articles && acte.actes_articles.length > 0 ? this.commentaireFormatted(acte.actes_articles) : '',
            'actes_commentaire'     	  : acte.actes_commentaire,
            'actes_document'        	  : acte.actes_document,
			'actes_articles_formatted'	  : acte.actes_articles_formatted,
            'actes_ageday'          	  : acte.actes_ageday,
            'horse_nom'             	  : acte.acte_horse.horse_nom,
            'horse_sexe'             	  : acte.acte_horse.horse_sexe,
            'horse_transpondeur'    	  : acte.acte_horse.horse_transpondeur,
            'horse_interne'         	  : acte.acte_horse.horse_interne,
			'horse_wholesire'       	  : acte.acte_horse.horse_sire !== null ? acte.acte_horse.horse_sire+acte.acte_horse.horse_cle : '',
			'horse_categorie'			  : acte.acte_horse.horse_categorie,
			'horse_intraloc_label'		  : acte.acte_horse.horse_intraloc_label,
			'actetype'              	  : actetype,
			'actetype_label'			  : actetype.actestype_licencekey ? actetype.actestype_label : Vue.i18n.translate('actes_type.' + actetype.actestype_code),
            'actegroupe'            	  : actetype.actestype_groupe,
            'reponse'               	  : reponses,
            'pedigree'					  : acte.acte_horse.horse_pedigree[0],
			'main_residence_label'		  : acte.acte_horse.horse_residence_label,
			'notes'						  : { raw: acte.notes, ...NoteTransformer.synthetize(acte.notes) },
            'contact'					  : contact,
            'actes_document_lien'				  : acte.document_lien,
            'contact_label'				  : contact ? contact.contact_civility + " " + contact.contact_firstname  + " " + contact.contact_lastname : "",
			'horse_last_vermifuge_date'   : acte.acte_horse.horse_last_vermifuge_date,
			'horse_last_vermifuge_label'  : acte.acte_horse.horse_last_vermifuge_label,
			'horse_last_repro_label'	  : acte.acte_horse.horse_last_repro,
			'horse_datenaissance'		  : acte.acte_horse.horse_datenaissance,
			'entity_id'					  : acte.entity ? acte.entity.tiers.tiers_id : null,
			'entity_rs'					  : acte.entity ? acte.entity.tiers.tiers_rs : '',
			'horse_last_echo_og'		  : acte.acte_horse.horse_last_echo_og,
			'horse_last_echo_od'		  : acte.acte_horse.horse_last_echo_od,
			'horse_last_echo_uterus'	  : acte.acte_horse.horse_last_echo_uterus,
			'seasonmarestallion_status'	  : acte.seasonmarestallion ? acte.seasonmarestallion.seasonmarestallion_status : '',
			'horse_stallion'	  		  : acte.seasonmarestallion ? acte.seasonmarestallion.horse.horse_nom : '',
			'date_since_last_covering'	  : acte.seasonmarestallion && acte.seasonmarestallion.seasonmarestallion_dds ? this.calculDifference(acte.seasonmarestallion.seasonmarestallion_dds, new Date()) : '',
			'horse_inactive'			  : acte.acte_horse.horse_inactive
        }

        return this.getBtableProps(result)
    }
	async fetchWithHorseAndMainResidenceNotesWithoutBtTableProp (col) {
		this.additionalColumns({
			'acte_horse': this.acte_horse,
			'actes_articles': this.actes_articles,
			'actes_ageday': this.actes_ageday,
			'actes_type': this.actes_type,
			'notes': this.notes,
			'pedigree': this.pedigree,
			'contact': this.contact,
			'actes_articles_formatted': this.actes_articles_formatted,
			'entity': this.entity,
			'seasonmarestallion': this.seasonmarestallion,
			'document_lien':this.document_lien
		})

        return col
    }

    async transformWithHorseAndMainResidenceNotesWithoutBtTableProp (acte) {
        const reponses = await this.getResponses(acte, true)
		const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')
		const contact = await ContactTransformer.process(acte.contact)

		if(actetype && actetype.actestype_groupe) {
			actetype.actestype_groupe.actesgroupe_label = Vue.i18n.translate('actes_groupe.' + actetype.actestype_codegroupe)
		}

        let result = {
            'actes_id'              	  : acte.actes_id,
            'actes_actesstatut'     	  : acte.actes_actesstatut,
            'actes_actestype'			  : acte.actes_actestype,
            'actes_date'           		  : acte.actes_date,
            'actes_time'            	  : acte.actes_time,
            'actes_date_fin'        	  : acte.actes_date_fin,
            'actes_horse'           	  : acte.acte_horse.horse_id,
            'actes_commentaire'     	  : acte.actes_commentaire,
            'actes_document'        	  : acte.actes_document,
            'actes_articles'  			  : acte.actes_articles,
            'actes_articles_commentaire'  : acte.actes_articles && acte.actes_articles.length > 0 ? this.commentaireFormatted(acte.actes_articles) : '',
			'actes_articles_formatted'	  : acte.actes_articles_formatted,
            'actes_ageday'          	  : acte.actes_ageday,
            'horse_nom'             	  : acte.acte_horse.horse_nom,
            'horse_transpondeur'    	  : acte.acte_horse.horse_transpondeur,
            'horse_interne'         	  : acte.acte_horse.horse_interne,
			'horse_wholesire'       	  : acte.acte_horse.horse_sire !== null ? acte.acte_horse.horse_sire+acte.acte_horse.horse_cle : '',
			'horse_categorie'			  : acte.acte_horse.horse_categorie,
			'actetype'              	  : actetype,
			'actetype_label'			  : actetype.actestype_licencekey ? actetype.actestype_label : Vue.i18n.translate('actes_type.' + actetype.actestype_code),
            'actegroupe'            	  : actetype.actestype_groupe,
            'reponse'               	  : reponses,
            'pedigree'					  : acte.pedigree,
			'main_residence_label'		  : acte.acte_horse.horse_residence_label,
			'notes'						  : { raw: acte.notes, ...NoteTransformer.synthetize(acte.notes) },
            'contact'					  : contact,
            'actes_document_lien'				  : acte.document_lien,
            'contact_label'				  : contact ? contact.contact_civility + " " + contact.contact_firstname  + " " + contact.contact_lastname : "",
			'horse_last_vermifuge_date'   : acte.acte_horse.horse_last_vermifuge_date ? new Date(acte.acte_horse.horse_last_vermifuge_date).toLocaleDateString(Vue.prototype.$i18n.locale()) : '',
			'horse_last_vermifuge_label'  : acte.acte_horse.horse_last_vermifuge_label,
			'horse_last_repro_label'	  : acte.acte_horse.horse_last_repro,
			'horse_datenaissance'		  : acte.acte_horse.horse_datenaissance,
			'entity_id'					  : acte.entity ? acte.entity.tiers.tiers_id : null,
			'entity_rs'					  : acte.entity ? acte.entity.tiers.tiers_rs : '',
			'horse_last_echo_og'		  : acte.acte_horse.horse_last_echo_og,
			'horse_last_echo_od'		  : acte.acte_horse.horse_last_echo_od,
			'horse_last_echo_uterus'	  : acte.acte_horse.horse_last_echo_uterus,
			'seasonmarestallion_status'	  : acte.seasonmarestallion ? acte.seasonmarestallion.seasonmarestallion_status : '',
			'horse_stallion'	  		  : acte.seasonmarestallion ? acte.seasonmarestallion.horse.horse_nom : '',
			'date_since_last_covering'	  : acte.seasonmarestallion && acte.seasonmarestallion.seasonmarestallion_dds ? this.calculDifference(acte.seasonmarestallion.seasonmarestallion_dds, new Date()) : '',
			'horse_inactive'			  : acte.acte_horse.horse_inactive
        }
		
        return result
    }

    async fetchGyneco (col) {
		this.additionalColumns({
			'actes_ageday'			: this.actes_ageday,
			'statusCode'			: this.statusCode,
			'sortByGroup'			: this.sortByGroup,
			'formatted_statut'		: this.formatted_statut,
			'actes_articles'		: this.actes_articles,
			'actes_articles_formatted': this.actes_articles_formatted,
			'document_lien':this.document_lien,
			'horse': this.horse,
		})

        return col.with({
            'actes_type'            : 'actes_actestype',
        })
    }

    async transformGyneco (acte) {
        const reponses = await this.getResponses(acte, true)
        const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')

		if(actetype && actetype.actestype_groupe) {
			actetype.actestype_groupe.actesgroupe_label = Vue.i18n.translate('actes_groupe.' + actetype.actestype_codegroupe)
		}

        let result = {
            'actes_id'              : acte.actes_id,
            'actes_id_absolute'     : Math.abs(acte.actes_id),
            'actes_actesstatut'     : acte.formatted_statut,
            'actes_time'            : acte.actes_time,
            'actes_date'            : acte.actes_date,
            'actes_date_fin'        : acte.actes_date_fin,
            'actes_horse'           : acte.actes_horse,
            'actes_commentaire'     : acte.actes_commentaire,
            'actes_show_commentaire': acte.actes_show_commentaire,
            'actes_document'        : acte.actes_document,
            'actes_actestype'       : acte.actes_actestype,
            'actes_ageday'          : acte.actes_ageday,
            'actetype'              : actetype,
            'actegroupe'            : actetype.actestype_groupe,
			'reponse'               : reponses,
			'sortByGroup'			: acte.sortByGroup,
			'statusCode'			: acte.statusCode,
			'actes_articles'        : acte.actes_articles,
			'actes_articles_formatted': acte.actes_articles_formatted,
            'horse_nom'				: acte.horse.horse_nom,
            'statut'				: acte.actes_actesstatut,
            'actes_document_lien'	: acte.document_lien,
        }

        return this.getBtableProps(result)
    }

    sortGyneco (array) {
		return _orderBy(array, ['sortByGroup', 'actes_date', 'actes_id_absolute'], ['asc', 'desc', 'desc'])
	}

    async transformGynecoJumenterie (acte) {
        const now = new Date().getTime()
        acte.actes_ageday = (new Date(acte.actes_date)).getTime()

        return {
            'actes_id'              : acte.actes_id,
            'actes_id_absolute'     : Math.abs(acte.actes_id),
            'actes_actesstatut'     : acte.actes_actesstatut,
            'actes_time'            : acte.actes_time,
            'actes_date'            : acte.actes_date,
            'actes_date_fin'        : acte.actes_date_fin,
            'actes_horse'           : acte.actes_horse,
            'actes_commentaire'     : acte.actes_commentaire,
            'actes_show_commentaire': acte.actes_show_commentaire,
            'actes_document'        : acte.actes_document,
            'actes_actestype'       : acte.actes_actestype,
            'actes_ageday'          : Math.floor((now - acte.actes_ageday) / (1000 * 3600 * 24)), // Pour un résultat en jours
            'reponse'               : await this.getResponses(acte, true)
        }
    }

    sortGynecoJumenterie (array) {
		return _orderBy(array, ['actes_date', 'actes_id_absolute'], ['desc', 'desc'])
    }

    async fetchMonte (col) {
		this.additionalColumns({
			actes_ageday: this.actes_ageday
		})

        return col.with({
            'actes_type'            : 'actes_actestype',
        })
    }

    async transformMonte (acte) {
        const reponses = await this.getResponses(acte, true)
        const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')

		if(actetype && actetype.actestype_groupe) {
			actetype.actestype_groupe.actesgroupe_label = Vue.i18n.translate('actes_groupe.' + actetype.actestype_codegroupe)
		}

        return {
            'actes_id'              : acte.actes_id,
            'actes_id_absolute'     : Math.abs(acte.actes_id),
            'actes_actesstatut'     : acte.actes_actesstatut,
            'actes_time'            : acte.actes_time,
            'actes_date'            : acte.actes_date,
            'actes_date_fin'        : acte.actes_date_fin,
            'actes_horse'           : acte.actes_horse,
            'actes_commentaire'     : acte.actes_commentaire,
            'actes_show_commentaire': acte.actes_show_commentaire,
            'actes_document'        : acte.actes_document,
            'actes_ageday'          : acte.actes_ageday,
            'actetype'              : actetype,
            'actegroupe'            : actetype.actestype_groupe,
            'reponse'               : reponses
        }
    }

	async fetchInlineFormatted (col) {
		this.additionalColumns({
			'actes_ageday': this.actes_ageday,
			'actes_type': this.actes_type
		})

        return col
    }

    async transformInlineFormatted (acte) {
		const reponses = await this.getResponses(acte, true)
		const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')

		const at_label = actetype.actestype_licencekey ? actetype.actestype_label : Vue.i18n.translate('actes_type.' + actetype.actestype_code)

		const ag_label = Vue.i18n.translate('actes_groupe.' + actetype.actestype_codegroupe)
		// const ag_label = actetype.actestype_groupe.actesgroupe_label

		let act_date = '-'
		if(acte.actes_date instanceof Date) {
			act_date = acte.actes_date.toLocaleDateString(Vue.i18n.locale())
		}
		let inline_content = `${act_date}: ${ag_label} - ${at_label}`

		if(reponses.reponse_formatted_data) {
			inline_content += ` (${reponses.reponse_formatted_data})`
		}

		return {
            'actes_id'        : acte.actes_id,
            'actes_age'       : acte.age,
			'actes_date'	  : acte.actes_date,
			'actetype_label'  : at_label,
			'actegroupe_label': ag_label,
			inline_content
		}
	}

	async fetchWithReponseNotFormatted (col) {
		this.additionalColumns({
			'horse': this.horse,
		})

        return col.with({
            'actes_type'            : 'actes_actestype',
        })
    }

    async transformWithReponseNotFormatted (acte) {
        const reponses = await this.getResponses(acte)
        const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')

        return {
            'actes_id'              : acte.actes_id,
            'actes_id_absolute'     : Math.abs(acte.actes_id),
            'actes_actesstatut'     : acte.actes_actesstatut,
            'actes_time'            : acte.actes_time,
            'actes_date'            : acte.actes_date,
            'actes_date_fin'        : acte.actes_date_fin,
            'actes_horse'           : acte.actes_horse,
            'actes_commentaire'     : acte.actes_commentaire,
            'actes_show_commentaire': acte.actes_show_commentaire,
            'actes_document'        : acte.actes_document,
            'actes_ageday'          : acte.actes_ageday,
            'actetype'              : actetype,
            'actestype_code'        : actetype.actestype_code,
            'reponse'               : reponses,
            'horse_nom'				: acte.horse.horse_nom,
            'horse_id'				: acte.horse.horse_id,
        }
    }



	async fetchWithContactAndArticles (col) {
		this.additionalColumns({
			'actes_articles': this.actes_articles,
			'actes_articles_formatted': this.actes_articles_formatted,
			'actes_ageday': this.actes_ageday,
			'actes_type': this.actes_type,
			'contact': this.contact
		})

        return col
    }

    async transformWithContactAndArticles (acte) {
        const reponses = await this.getResponses(acte)
		const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')
		const contact = await ContactTransformer.process(acte.contact)

        let result = {
            'actes_id'              : acte.actes_id,
            'actes_actesstatut'     : acte.actes_actesstatut,
            'actes_actestype'		: acte.actes_actestype,
            'actes_date'            : acte.actes_date,
            'actes_time'            : acte.actes_time,
            'actes_date_fin'        : acte.actes_date_fin,
            'actes_commentaire'     : acte.actes_commentaire,
            'actes_show_commentaire': acte.actes_show_commentaire,
            'actes_document'        : acte.actes_document,
            'actes_articles'        : acte.actes_articles,
			'actes_articles_formatted': acte.actes_articles_formatted,
            'actes_ageday'          : acte.actes_ageday,
            'actes_horse'			: acte.actes_horse,
			'actetype'              : actetype,
			'actetype_label'		: actetype.actestype_licencekey ? actetype.actestype_label : Vue.i18n.translate('actes_type.' + actetype.actestype_code),
            'actegroupe'            : actetype.actestype_groupe,
            'reponse'               : reponses,
            'contact'				: contact,
            'contact_label'			: contact ? contact.contact_civility + " " + contact.contact_firstname  + " " + contact.contact_lastname : ""
        }

        return this.getBtableProps(result)
    }

	async fetchWithActeTypeGroupe (col) {
		this.additionalColumns({
			'acte_horse': this.acte_horse,
		})

        return col.with({
            'actes_type'            : 'actes_actestype',
        })
    }

    async transformWithActeTypeGroupe (acte) {
        const actetype = await ActeTypeTransformer.process(acte.actes_type, 'withGroups')

        return {
            'actes_id'              : acte.actes_id,
            'actes_id_absolute'     : Math.abs(acte.actes_id),
            'actes_actesstatut'     : acte.actes_actesstatut,
            'actes_time'            : acte.actes_time,
            'actes_date'            : acte.actes_date,
            'actes_date_fin'        : acte.actes_date_fin,
            'actetype'              : actetype,
            'actestype_code'        : actetype.actestype_code,
			'horse_inactive'		: acte.acte_horse.horse_inactive
        }
    }
}

export default ActeTransformer
