<template>
	<div v-if="show_menu">
		<nav>
            <Nav />
            <NavMobile v-if="isBelowMd" />
	    </nav>

		<!-- <b-alert variant="danger" id="alert_phone" dismissible show v-if="!verified_user">
			<b>{{ $t("global.telephone_non_verifie") }}</b>
			{{ $t("global.confirmer_telephone") }}<br><router-link :to="{ name: 'compteValidation' }" class="btn btn-secondary">{{ $t("global.renseigner_telephone") }} <font-awesome-icon :icon="['fal', 'long-arrow-right']"/></router-link>
		</b-alert> -->

		<notifications/>
        <PwaUpdater />
        <PwaDownload />
        <ImportHorseMicroship ref="import_horse_microship" />
        <!-- <LicenceReminder /> Vérifier la mise à jour des dates de licence -->
    </div>
</template>


<script type="text/javascript">
import { EventBus } from "EventBus"
import User from '@/mixins/User.js'
import Navigation from '@/mixins/Navigation.js'
import Window from '@/mixins/Window.js'
import Constants from '../../config.local.js'

export default {
	mixins: [User, Navigation, Window],
	components: {
		Notifications: () => import('@/components/Header/Notifications'),
		NavMobile: () => import('@/components/Header/NavMobile'),
		Nav: () => import('@/components/Header/Nav'),
		PwaUpdater: () => import('@/components/Offline/PwaUpdater'),
        PwaDownload: () => import('@/components/Offline/PwaDownload'),
        LicenceReminder: () => import('@/components/LicenceReminder'),
        ImportHorseMicroship: () => import('@/components/ImportHorseMicroship')
	},
    metaInfo: {
        meta: [
            { name: 'apple-mobile-web-app-capable', content: 'yes' },
            { name: 'mobile-web-app-capable', content: 'yes' }
        ]
    },
    data() {
        return {
            verified_user : null,
            blacklist: [ // liste des vues dans lesquelles on affiche pas le header. Nom relatif au router (cf router)
                "Home",
                "PasswordUpdate",
                "compteCreation",
                "compteCreationSalon",
                "compteValidation",
                "resetPassword",
                "switchLicence",
				"CheckEmailLS",
				"EmailValidation",
                "Loading",
                "notificationHandler",
                "GocardlessRapprochement"
            ],
            events_tab: {
                'Header::displayToast': this.displayToast,
                'verified_user': this.onUserVerified
            }
        }
    },
    mounted() {
        EventBus.$on('Actions::ImportHorseMicrosip', this.openImportHorseMicroship)
    },
    computed: {
        show_menu() {
            const route_name = this.$route.name

            if(!route_name) return false
            return !this.blacklist.includes(route_name)
        }
    },
    methods: {
        async logout() {
            await this.delogUser()
        },

        displayToast(params) {
            let paramsToast = {}

            if(params.title) {
                paramsToast.title = params.title
            }
            paramsToast.variant = params.type
			paramsToast.solid = false
			paramsToast.toaster = params.toaster || 'b-toaster-top-right'

            this.$bvToast.toast(params.body, paramsToast)
        },

        onUserVerified(payLoad) {
            this.verified_user = payLoad
        },

        async reload() {
            let licence_key = this.getConfig("licence_key")
            let token = this.getConfig("token")
            await this.rmConfig();
            await this.setConfig("licence_key", licence_key)
            await this.setConfig("token", token)
            await this.loadConfig()
            location.reload()
        },

        sendSampleNotification() {
            const url = `${process.env.VUE_APP_BASE_API_URL}/api/notifications/sample/${Constants.USER_LICENCE_KEY}/${this.getConfig('user_id')}`
            this.$request.request_post_api('Notification::sendSample', url)
        },

        openImportHorseMicroship(params) {
            if(this.$refs.import_horse_microship) {
                this.$refs.import_horse_microship.openModal(params)
            }
        }
    }
}
</script>
