import Vue from 'vue'
import Common from '@/assets/js/common'
import ConfigMixin from "@/mixins/Config.js"
import _findKey from 'lodash/findKey'
import _cloneDeep from 'lodash/cloneDeep'
import dateFormat from "dateformat"

var Tools = {
    data() {
        return {
            regex_alpha: /(\w)/,
            regex_alpha_nc: /(\W)/, // Exclu les accents et apostrophes
            regex_alpha_fr: /([A-Za-z0-9èÉéÈêÊëË'" ])/,
            regex_alpha_fr_nc: /([^A-Za-z0-9èÉéÈêÊëË'". ])/,

            // regex_sire: /(?<![0-9]{1,})([0-9]{8}[A-Z])(?![A-Z]{1,})/, // match un sire complet, on cherche s'il y a d'autre chiffres avant, et d'autres lettres qui suivent la clé, si oui, la regex est rejetée => (?!) = négation
            regex_sire: /^[0-9]{8}[A-Z]$/, // match un sire complet, on cherche s'il y a d'autre chiffres avant, et d'autres lettres qui suivent la clé, si oui, la regex est rejetée => (?!) = négation
            regex_sire_like: /([0-9]{5})/, // regex permettant de commencer la recherche de sire
            // regex_incomplete_sire_car: /(?<![0-9]{1,})([0-9]{8})([A-Z]{2,}$)/, // match 8 chiffres et plusieurs lettre => Erreur de saisie, pas possible d'avoir plusieurs lettres
            regex_incomplete_sire_car: /^[0-9]{8}[A-Z]{2,}$/, // match 8 chiffres et plusieurs lettre => Erreur de saisie, pas possible d'avoir plusieurs lettres
            // regex_incomplete_sire_num: /(?<![0-9]{1,})([0-9]{6,7}[A-Z])/, // match entre 6 et 7 chiffres et une lettre => Un chiffre est manquant
            regex_incomplete_sire_num: /^[0-9]{6,7}[A-Z]$/, // match entre 6 et 7 chiffres et une lettre => Un chiffre est manquant
            // regex_incomplete_sire_cle: /([0-9]{8})(?![A-Z]{1,})/, // match entre 8 chiffres => Un caractère est manquant (la clé)
            regex_incomplete_sire_cle: /^[0-9]{8}$/, // match entre 8 chiffres => Un caractère est manquant (la clé)

            // regex_ueln: /(?<![0-9]{1,})([0-9]{14}[A-Z])(?![A-Z]{1,})/, // match un ueln complet, on cherche également s'il n'y a pas d'autre chiffres avant, et d'autres lettres qui suivent la clé
            regex_ueln: /^(([0-9]{14})|(([0-9]{4}[A-Z]{2}[0-9]{8})|([0-9]{3}[A-Z]{3}[0-9]{8}))[A-Z]$)|^(([0-9]{9}[A-Z][0-9]{5}$)|([0-9]{10}[A-Z][0-9]{4}$))/, // match un ueln complet, on cherche également s'il n'y a pas d'autre chiffres avant, et d'autres lettres qui suivent la clé
            regex_ueln_like: /([0-9]{9})|([0-9]{3}[A-Z]{3})|([0-9]{4}[A-Z]{2})/, // regex permettant de commencer la recherche d'ueln
            regex_ueln_error: /([0-9]{15,}|[A-Z]{2,})/, // regex permettant de commencer la recherche d'ueln
            regex_incomplete_ueln_car: /(([0-9]{15,}|[0-9]{14,})[A-Z]{2,})/, // Erreur de saisie, pas possible d'avoir plusieurs lettres
            // regex_incomplete_ueln_num: /(?<![0-9]+)([0-9]{9,13})(?![0-9]+)/, // Erreur de saisie, il manque un/des chiffres
            regex_incomplete_ueln_num: /^[0-9]{9,13}$/, // Erreur de saisie, il manque un/des chiffres
            // regex_incomplete_ueln_cle: /(?<![0-9]+)([0-9]{14})(?![A-Z]{1,})(?![0-9]+)/, // Erreur de saisie, il manque une lettre, la clé
            regex_incomplete_ueln_cle: /^[0-9]{14}$/, // Erreur de saisie, il manque une lettre, la clé
            regex_microship: /^[0-9]{15}$/,
            // eslint-disable-next-line no-useless-escape
            regex_email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

            regex_name_like: /([A-Za-z ]{5,})/, // regex permettant de commencer la recherche de nom

            regex_date_field: /[a-zA-Z]+_date/, // permet de catch un champs de bdd avec le terme _date dedans
			regex_licencekey: /([0-9A-Z]{4}-){4}([0-9A-Z]{4})/
        }
    },
	methods: {
        isLogged: function() {
            return (ConfigMixin.methods.getConfig("licence_key") && ConfigMixin.methods.getConfig("token"));
        },

        beforeOpenHome: function() {
            if(this.isLogged())
               return true
            return false
       },
        // retourne le nom du mois en fonction de son id, dans la bonne langue
		getMonthName: function(id) {
            if(id !== undefined) {
                return this.$t('mois.'+id)
            }
		},

        getTrad: function(section, params=[]) {
            if(params.length === 0) return this.$t(section)
            return this.$t(section, params)
        },

        getTradOutsideVue: function(section, params=[]) {
            if(params.length === 0) return Vue.i18n.translate(section, params)
            return Vue.i18n.translate(section, params)
        },

        getTableColumnTrad: function(table_cle, column) {
            return this.getTrad('table.'+table_cle+'.'+column)
        },

        // Retourne les propriétés d'un objet à partir d'un filtre (String)
        getFilterObject: function(obj_source, filter) {
    		return Object.keys(obj_source)
    			.filter(key => key.includes(filter))
    		  	.reduce((obj, key) => {
    				obj[key] = obj_source[key];
    				return obj;
    		  	}, {}
            )
        },

        // Retourne, sur un tableau d'objets, toutes les occurences d'une propriété
        // Par exemple, sur un tableau d'actes, on peut retourner un tableau d'acte id
        getArrayObjProperty: function(tab, property) {
			return Common.getArrayObjProperty(tab, property)
        },

        getNestedObject: function(nestedObj, pathArr) {
            return pathArr.reduce((obj, key) =>
                    (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj)
        },

        getNestedObjectString: function() {
            return Common.getNestedObjectString(...arguments)
        },

        cleanDuplicatArray: function(array) {
            return array.filter((item, index) => {
                return array.indexOf(item) >= index
            })
        },

        checkForm: function(required_values) {
            let el = '';
            let error = false

            // On vérifie chaque input obligatoire
            for(let i=0; i<required_values.length; i++) {
                el = required_values[i]

                // On ajoute la classe error sur les div des v-model non remplis
                if(this[el] === null || this[el] === undefined) {
                    error = true
                }
            }

            return error
        },

        /* Permet d'update des attributs d'un objet, à partir d'un autre */
        mergeObject: function(obj_source, obj_taget) {

        },

        escapeTable: function(str) {
            if(str == undefined) {
                return ''
            }

            if(str != null && str.length > 0) {
                return str.replace(/'/g, "''")
            }

            return str
        },

        formatBase64String: function(base64_str) {
            if(base64_str.includes("base64,")) { // base64, est le dernier bout de string avant le code base64 réel
                let index = base64_str.search("base64,")
                base64_str = base64_str.substr(index+7)
            }

            return base64_str
        },

        /* Permet de cloner un objet sans copie par référence */
        deppCloneObj: function(obj) {
            return _cloneDeep(obj)
        },

        deepClone: function(obj) {
            return this.deppCloneObj(obj)
        },

        /* Permet de resortir un élément déjà présent ou non, entre deux tableaux */
        diffArrayNonExist: function(arr1, arr2) {
            return arr1.filter((i) => { return arr2.indexOf(i) < 0 })
        },
        diffArrayExist: function(arr1, arr2) {
            return arr1.filter((i) => { return arr2.indexOf(i) > -1 })
        },

        /* Permet de merge un array avec un objet */
        /* array => tableau à merge */
        /* obj => objet à ajouter (ou supprimer) du tableau */
        /* auto_delete => à true, si l'objet existe déjà, il est supprimé du tableau */
        mergeArray: function(array, obj, auto_delete=false) {
            // On regarde si l'objet est déjà dans le tableau. Si oui, on le supprime, sinon, on l'ajoute
            const index_of = array.indexOf(obj)

            if(index_of > -1) {
                array.splice(index_of, 1)
            }
            else if(auto_delete) {
                array.push(obj)
            }

            return array
        },

        /* Permet de construire une route avec les bon params */
        /* ur_base (String) => l'url avec les params à remplacer */
        /* params (Object) => les params avec en clé le param de la route, en valeur le param à donner */
        constructRoute: (url_base, params) => {
            return Common.constructRoute(url_base, params)
        },

        /* Retourne la date et l'heure courante : "Lundi 2 Décembre 2019 15:07:34" */
        getPrintableDateTime: function(){
            let now = new Date
            return now.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })+' '
            +( now.getHours()<10 ? '0' : '')+now.getHours()
            +":"+( now.getMinutes()<10 ? '0' : '')+now.getMinutes()
            +":"+( now.getSeconds()<10 ? '0' : '')+now.getSeconds();
        },

        priceFormat: function(value, currencyCode='EUR', withSymbol=false){
            return Common.priceFormat(value, currencyCode, withSymbol)
        },

        /* parse une chaine de caractère en float quelque soit le format (1 200,00 ou 1 200.00 ou 1,200.00) */
        parsePotentiallyGroupedFloat: function (stringValue) {
            if(typeof stringValue !== 'string')
                return parseFloat(stringValue)
            stringValue = stringValue.trim();
            var result = stringValue.replace(/[^0-9]/g, '');
            // eslint-disable-next-line
            if (/[,\.]\d{2}$/.test(stringValue)) {
                result = result.replace(/(\d{2})$/, '.$1');
            }
            // eslint-disable-next-line
            if (/[,\.]\d{1}$/.test(stringValue)) {
                result = result.replace(/(\d{1})$/, '.$1');
            }
            return parseFloat(result);
        },

        accentsTidy: function(s){
            let r = s.toLowerCase();

            r = r.replace(new RegExp(/[àáâãäå]/g),"a");
            r = r.replace(new RegExp(/æ/g),"ae");
            r = r.replace(new RegExp(/ç/g),"c");
            r = r.replace(new RegExp(/[èéêë]/g),"e");
            r = r.replace(new RegExp(/[ìíîï]/g),"i");
            r = r.replace(new RegExp(/ñ/g),"n");                
            r = r.replace(new RegExp(/[òóôõö]/g),"o");
            r = r.replace(new RegExp(/œ/g),"oe");
            r = r.replace(new RegExp(/[ùúûü]/g),"u");
            r = r.replace(new RegExp(/[ýÿ]/g),"y");

            return r;
        },

		// TODO : version générique pour l'INTERNATIONAL
        // formatDate: function(date, display_year = true) {
        //     var d = new Date(date),
        //         month = '' + (d.getMonth() + 1),
        //         day = '' + d.getDate(),
        //         year = d.getFullYear();

        //     if (month.length < 2) 
        //         month = '0' + month;
        //     if (day.length < 2) 
        //         day = '0' + day;

        //     if(Vue.i18n.locale() === 'en') {
        //         if(display_year)
        //             return [month, day, year].join('/')
        //         return [month, day].join('/')
        //     }
            
        //     if(display_year)
        //         return [day, month, year].join('/')
        //     return [day, month].join('/')
        // },

        formatDate: function(date) {
            if(!date) {
                return ''
            }
            let date_config = JSON.parse(window.localStorage.getItem('format_date')) ?? 'd/m/Y'
            date_config = date_config.replace('y', 'yy').replace('Y', 'yyyy').replace('d', 'dd').replace('m', 'mm')
            
            let date_obj = Date.parseTz(date)
            return date_obj == 'Invalid Date' ? date_obj : dateFormat(date_obj, date_config)
        },

        // public method for url encoding
        toUTF8: function(text) {
            var res = text.replace(/&nbsp;/g, ' ');
            res = res.replace(/&iexcl;/g, '¡');
            res = res.replace(/&cent;/g, '¢');
            res = res.replace(/&pound;/g, '£');
            res = res.replace(/&curren;/g, '¤');
            res = res.replace(/&yen;/g, '¥');
            res = res.replace(/&brvbar;/g, '¦');
            res = res.replace(/&sect;/g, '§');
            res = res.replace(/&uml;/g, '¨');
            res = res.replace(/&copy;/g, '©');
            res = res.replace(/&ordf;/g, 'ª');
            res = res.replace(/&laquo;/g, '«');
            res = res.replace(/&not;/g, '¬');
            res = res.replace(/&shy;/g, '­');
            res = res.replace(/&reg;/g, '®');
            res = res.replace(/&macr;/g, '¯');
            res = res.replace(/&deg;/g, '°');
            res = res.replace(/&plusmn;/g, '±');
            res = res.replace(/&sup2;/g, '²');
            res = res.replace(/&sup3;/g, '³');
            res = res.replace(/&acute;/g, '´');
            res = res.replace(/&micro;/g, 'µ');
            res = res.replace(/&para;/g, '¶');
            res = res.replace(/&middot;/g, '·');
            res = res.replace(/&cedil;/g, '¸');
            res = res.replace(/&sup1;/g, '¹');
            res = res.replace(/&ordm;/g, 'º');
            res = res.replace(/&raquo;/g, '»');
            res = res.replace(/&frac14;/g, '¼');
            res = res.replace(/&frac12;/g, '½');
            res = res.replace(/&frac34;/g, '¾');
            res = res.replace(/&iquest;/g, '¿');
            res = res.replace(/&Agrave;/g, 'À');
            res = res.replace(/&Aacute;/g, 'Á');
            res = res.replace(/&Acirc;/g, 'Â');
            res = res.replace(/&Atilde;/g, 'Ã');
            res = res.replace(/&Auml;/g, 'Ä');
            res = res.replace(/&Aring;/g, 'Å');
            res = res.replace(/&AElig;/g, 'Æ');
            res = res.replace(/&Ccedil;/g, 'Ç');
            res = res.replace(/&Egrave;/g, 'È');
            res = res.replace(/&Eacute;/g, 'É');
            res = res.replace(/&Ecirc;/g, 'Ê');
            res = res.replace(/&Euml;/g, 'Ë');
            res = res.replace(/&Igrave;/g, 'Ì');
            res = res.replace(/&Iacute;/g, 'Í');
            res = res.replace(/&Icirc;/g, 'Î');
            res = res.replace(/&Iuml;/g, 'Ï');
            res = res.replace(/&ETH;/g, 'Ð');
            res = res.replace(/&Ntilde;/g, 'Ñ');
            res = res.replace(/&Ograve;/g, 'Ò');
            res = res.replace(/&Oacute;/g, 'Ó');
            res = res.replace(/&Ocirc;/g, 'Ô');
            res = res.replace(/&Otilde;/g, 'Õ');
            res = res.replace(/&Ouml;/g, 'Ö');
            res = res.replace(/&times;/g, '×');
            res = res.replace(/&Oslash;/g, 'Ø');
            res = res.replace(/&Ugrave;/g, 'Ù');
            res = res.replace(/&Uacute;/g, 'Ú');
            res = res.replace(/&Ucirc;/g, 'Û');
            res = res.replace(/&Uuml;/g, 'Ü');
            res = res.replace(/&Yacute;/g, 'Ý');
            res = res.replace(/&THORN;/g, 'Þ');
            res = res.replace(/&szlig;/g, 'ß');
            res = res.replace(/&agrave;/g, 'à');
            res = res.replace(/&aacute;/g, 'á');
            res = res.replace(/&acirc;/g, 'â');
            res = res.replace(/&atilde;/g, 'ã');
            res = res.replace(/&auml;/g, 'ä');
            res = res.replace(/&aring;/g, 'å');
            res = res.replace(/&aelig;/g, 'æ');
            res = res.replace(/&ccedil;/g, 'ç');
            res = res.replace(/&egrave;/g, 'è');
            res = res.replace(/&eacute;/g, 'é');
            res = res.replace(/&ecirc;/g, 'ê');
            res = res.replace(/&euml;/g, 'ë');
            res = res.replace(/&igrave;/g, 'ì');
            res = res.replace(/&iacute;/g, 'í');
            res = res.replace(/&icirc;/g, 'î');
            res = res.replace(/&iuml;/g, 'ï');
            res = res.replace(/&eth;/g, 'ð');
            res = res.replace(/&ntilde;/g, 'ñ');
            res = res.replace(/&ograve;/g, 'ò');
            res = res.replace(/&oacute;/g, 'ó');
            res = res.replace(/&ocirc;/g, 'ô');
            res = res.replace(/&otilde;/g, 'õ');
            res = res.replace(/&ouml;/g, 'ö');
            res = res.replace(/&divide;/g, '÷');
            res = res.replace(/&oslash;/g, 'ø');
            res = res.replace(/&ugrave;/g, 'ù');
            res = res.replace(/&uacute;/g, 'ú');
            res = res.replace(/&ucirc;/g, 'û');
            res = res.replace(/&uuml;/g, 'ü');
            res = res.replace(/&yacute;/g, 'ý');
            res = res.replace(/&thorn;/g, 'þ');
            res = res.replace(/&yuml;/g, 'ÿ');
            return res;
        }
	}
}

export default Tools
