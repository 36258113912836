import Transformer from './Transformer'
import SeasonTransformer from '@/assets/js/dexie/transformers/SeasonTransformer.js'
import HorseTransformer from '@/assets/js/dexie/transformers/HorseTransformer.js'
import TierTransformer from '@/assets/js/dexie/transformers/TierTransformer.js'
/**
 * Retourne une liste de
 * @param {Dexie.Collection} season
 */
export default class ContractConfigTransformer extends Transformer {

	table = 'contract_config'

	async horse (contract_config) {
		return this.fetchRelationship(contract_config, 'contractconfig_horse')
		.then(horse => {
			return HorseTransformer.process(horse)
		})
	}

	async fetch (col) {
	    return col.with({
	        'season'                    : 'contractconfig_season',
	        'horse'                     : 'contractconfig_horse',
	        'cmep'                      : 'contractconfig_default_cmep'
	    })
	}


    async transform (contract_config) {
        return {
            'contractconfig_id'             : contract_config.contractconfig_id,
            'contractconfig_fromnumber'     : contract_config.contractconfig_fromnumber,
            'contractconfig_season'         : contract_config.contractconfig_season,
            'contractconfig_horse'          : contract_config.contractconfig_horse,
            'contractconfig_configuration'  : contract_config.contractconfig_configuration,
            'contractconfig_default_cmep'   : contract_config.contractconfig_default_cmep,
            'season'        				: await SeasonTransformer.process(contract_config.season, 'withContractsConfigStallion'),
			'horse'        					: await HorseTransformer.process(contract_config.horse, 'Light'),
            'cmep'                          : await TierTransformer.process(contract_config.cmep, 'light'),
			'contractconfig_updated'		: contract_config.contractconfig_updated,
            'contractconfig_paillette'		: contract_config.contractconfig_paillette,
            'contractconfig_paillette_recommended' : contract_config.contractconfig_paillette_recommended,
        }
	}

    async fetchWithSeasonAndHorse (col) {
        return col.with({
            'season'                    : 'contractconfig_season',
            'horse'                     : 'contractconfig_horse'
        })
    }


    async transformWithSeasonAndHorse (contract_config) {
        return {
            'contractconfig_id'             : contract_config.contractconfig_id,
            'contractconfig_season'         : contract_config.contractconfig_season,
            'contractconfig_horse'          : contract_config.contractconfig_horse,
            'contractconfig_default_cmep'   : contract_config.contractconfig_default_cmep,
            'season'                        : await SeasonTransformer.process(contract_config.season, 'Light'),
            'horse'                         : await HorseTransformer.process(contract_config.horse, 'UltraLight'),
            'contractconfig_updated'        : contract_config.contractconfig_updated,
        }
    }

	async fetchWithoutSeason (col) {
	    return col.with({
	        'horse'                     : 'contractconfig_horse'
	    })
	}


    async transformWithoutSeason (contract_config) {
        return {
            'contractconfig_id'             : contract_config.contractconfig_id,
            'contractconfig_fromnumber'     : contract_config.contractconfig_fromnumber,
            'contractconfig_season'         : contract_config.contractconfig_season,
            'contractconfig_horse'          : contract_config.contractconfig_horse,
            'contractconfig_configuration'  : contract_config.contractconfig_configuration,
			'horse'        					: await HorseTransformer.process(contract_config.horse, 'Light'),
			'contractconfig_updated'		: contract_config.contractconfig_updated,
            'contractconfig_paillette'		: contract_config.contractconfig_paillette,
            'contractconfig_paillette_recommended' : contract_config.contractconfig_paillette_recommended,
        }
	}

	async transformLight(contract_config) {
        return {
            'contractconfig_id'             : contract_config.contractconfig_id,
            'contractconfig_fromnumber'     : contract_config.contractconfig_fromnumber,
            'contractconfig_configuration'  : contract_config.contractconfig_configuration,
            'contractconfig_season'         : contract_config.contractconfig_season,
            'contractconfig_horse'          : contract_config.contractconfig_horse,
            'contractconfig_paillette'      : contract_config.contractconfig_paillette,
            'contractconfig_paillette_recommended' : contract_config.contractconfig_paillette_recommended,
            'contractconfig_default_cmep'   : contract_config.contractconfig_default_cmep
        }
	}

    async fetchWithSeason (col) {
        return col.with({
            'season'                    : 'contractconfig_season',
        })
    }


    async transformWithSeason (contract_config) {
        return {
            'contractconfig_id'             : contract_config.contractconfig_id,
            'contractconfig_fromnumber'     : contract_config.contractconfig_fromnumber,
            'contractconfig_season'         : contract_config.contractconfig_season,
            'contractconfig_horse'          : contract_config.contractconfig_horse,
            'contractconfig_configuration'  : contract_config.contractconfig_configuration,
            'contractconfig_default_cmep'   : contract_config.contractconfig_default_cmep,
            'season'                        : await SeasonTransformer.process(contract_config.season, 'withContractsConfigStallion'),
            'contractconfig_updated'        : contract_config.contractconfig_updated,
            'contractconfig_paillette'      : contract_config.contractconfig_paillette,
            'contractconfig_paillette_recommended' : contract_config.contractconfig_paillette_recommended,
        }
    }
}
