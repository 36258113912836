<template>
	<div id="app" class='w-100'>
		<Header />
		<router-view></router-view>
		<Footer />
	</div>
</template>

<script>
import { EventBus } from "EventBus";
import Constants from "Constants"
import _debounce from 'lodash/debounce'
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Shortcuts from  "@/mixins/Shortcuts.js";
import User from  "@/mixins/User.js";
import Rollbar from  "@/mixins/Rollbar.js";
import Window from  "@/mixins/Window.js";
import ValidationError from 'GroomyRoot/assets/js/errors/ValidationError'

export default {
	name: "App",
	mixins: [Shortcuts, User, Rollbar, Window],
	components: {
		Header,
		Footer
	},
	data: () => ({
		debouncedForceDelogUser: null,
		blacklist_routes: ['Shortcuts'],
		blacklist_titles: ['horseFiche', 'tiersFiche'],
		microship: ''
	}),
	computed: {
		disableScroll() {
			return (
				this.isBelowMd
				&& (
					this.$store.state.nav.isOpen
					|| this.$store.state.nav.isFooterOpen
					|| this.$store.state.nav.isNotificationsOpen
				)
			)
		}
	},
	async created() {
		this.debouncedForceDelogUser = _debounce(
			this.forceDelogUser,
			10000,
			{ 'leading': true, 'trailing': false }
		)
		EventBus.$on('App::forceDelogUser', this.debouncedForceDelogUser)
		EventBus.$on('App::failureToast', this.failureToast)
		EventBus.$on('Rollbar::debug', this.onRollbarDebug)
		EventBus.$on('Rollbar::info', this.onRollbarInfo)
		EventBus.$on('Rollbar::warn', this.onRollbarWarn)
		EventBus.$on('Rollbar::error', this.onRollbarError)
		EventBus.$on('sync:progress_success', this.onSyncProgress)
		
		if(Constants.IS_IOS_RUNNING) {
			EventBus.$on('NativComm::update_nav', this.handleSwiftNavigationUpdate)
		}

		window.addEventListener('error', this.onWindowError)
		window.addEventListener('unhandledrejection', this.onPromiseError)

		let licence_config = this.getConfig('licence_config')
		let read_microship_config = null
		if(licence_config.length > 0) {
			read_microship_config = licence_config.find(config => config.licenceconfig_key == 'READ_MICROSHIP')
		}

		document.addEventListener("keydown", event => {
			if(read_microship_config) {
				this.readMicroship(event, read_microship_config.licenceconfig_value)
			}

			let shortcuts = this.getConfig('shortcuts')
			if(shortcuts && shortcuts.length > 0 && !this.blacklist_routes.includes(this.$route.name)) {
				const shortcuts_found = shortcuts.filter(s => s.shortcuts_keycode == event.keyCode)
				if(shortcuts_found) {
					let success = 0
					shortcuts_found.forEach(shortcut => {
						let error = 0
						if(success) {
							return
						}

						if(shortcut.shortcuts_ctrl) {
							if(!event.ctrlKey) error++
						}
						if(shortcut.shortcuts_meta) {
							if(!event.metaKey) error++
						}
						if(shortcut.shortcuts_alt) {
							if(!event.altKey) error++
						}
						if(shortcut.shortcuts_shift) {
							if(!event.shiftKey) error++
						}

						if(event.ctrlKey) {
							if(!shortcut.shortcuts_ctrl) error++
						}
						if(event.metaKey) {
							if(!shortcut.shortcuts_meta) error++
						}
						if(event.altKey) {
							if(!shortcut.shortcuts_alt) error++
						}
						if(event.shiftKey) {
							if(!shortcut.shortcuts_shift) error++
						}

						if(!error && !success) {
							success++
							event.preventDefault()
							if(!shortcut.shortcuts_personal) {
								this.$router.push({ name: shortcut.shortcuts_route})
							}
							else {
								window.location.href = shortcut.shortcuts_route
							}
						}
					})
				}
			}
		});

		this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
			this.$root.initDragModal()
		})
	},
	mounted() {
		this.init_component()
	},
	beforeDestroy() {
		window.removeEventListener('error', this.onWindowError)
		window.removeEventListener('unhandledrejection', this.onPromiseError)
	},
	destroyed() {
		EventBus.$off('App::forceDelogUser', this.debouncedForceDelogUser)
		EventBus.$off('App::failureToast', this.failureToast)
		EventBus.$off('Rollbar::debug', this.onRollbarDebug)
		EventBus.$off('Rollbar::info', this.onRollbarInfo)
		EventBus.$off('Rollbar::warn', this.onRollbarWarn)
		EventBus.$off('Rollbar::error', this.onRollbarError)
		EventBus.$off('sync:progress_success', this.onSyncProgress)
		
		if(Constants.IS_IOS_RUNNING) {
			EventBus.$off('NativComm::update_nav', this.handleSwiftNavigationUpdate)
		}
	},
	watch: {
		disableScroll(val) {
			this.updateScrollState()
		},
		'$route'(val) {
			// const parent = this.$route.matched[this.$route.matched.length - 2]
			const parent = this.$route.matched[0]
			if(val.name && (!parent || (parent && !this.blacklist_titles.includes(parent.name)))) {
				const title = this.getTrad('title.' + val.name)
				document.title = title == '' || title == 'title.' + val.name ? 'Groomy' : title
            }
        }
	},
	methods: {
		async init_component() {
			let valid_user = this.getConfig("valid_user")
			if(valid_user == 0) {
				EventBus.$emit("verified_user", false)
			}
			else {
				EventBus.$emit("verified_user", true)
			}
		},
		onPromiseError(evt) {
			evt.promise.catch(this.onError)
		},
		onWindowError(evt) {
			this.onError(evt.error)
		},
		onError(err) {
			if(err instanceof ValidationError) {
				this.failureToast(err.i18nMessage, true)
			}
		},
		updateScrollState() {
			if (this.disableScroll) {
				document.querySelector('html').classList.add('no_scroll')
			}
			else {
				document.querySelector('html').classList.remove('no_scroll')
			}
		},
		handleSwiftNavigationUpdate(data) {
			const route = data.route
			if(!route) return
			this.$router.push(route)
		},
		onSyncProgress() {
			this.refreshVueExPermission()
		},
		readMicroship(e, action) {
			const chars = {
				"&": 1,
				"é": 2,
				'"': 3,
				"'": 4,
				"(": 5,
				"§": 6,
				"è": 7,
				"!": 8,
				"ç": 9,
				"à": 0,
				"_": 8,
				"-": 6
			}

			if(this.transpondeur == '') {
				window.setTimeout(this.resetTranspondeur, 1000);
			}
	    	e = e || window.event;
	    	if(e.key == 'Enter') {
	    		this.transpondeur = this.transpondeur.replaceAll('Shift', '').replaceAll('undefined', '')
	    		if(this.transpondeur.length == 15) {
	    			console.log(this.transpondeur);
	    			this.transpondeur = this.transpondeur.replace(/[&é"'(§è!çà)_-]/g, m => chars[m])
	    			EventBus.$emit('Actions::ImportHorseMicrosip', {action, transpondeur: this.transpondeur})
	    		}
	    		else {
	    			this.resetTranspondeur()
	    		}
	    	}
	    	else {
	    		this.transpondeur += e.key
	    	}
		},
		resetTranspondeur() {
			this.transpondeur = ''
		}
	}
};
</script>
